body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: black;
  padding: 0;
  overflow-x: hidden;
  width: 100vw;
  -webkit-overflow-scrolling: touch;
}
html {
  width: 100vw;
  margin: 0;
  padding: 0;
}
