.sauseSecretWrapper {
  padding-top: 160px;
  padding-bottom: 400px;
  width: 100%;

  @media (min-width: 1440px) and (max-width: 1920px) {
    padding-top: calc(320px + (160 - 320) * ((100vw - 1440px) / (1920 - 1440)));
    padding-bottom: calc(320px + (400 - 320) * ((100vw - 1440px) / (1920 - 1440)));
  }
  @media (min-width: 1080px) and (max-width: 1440px) {
    padding-top: calc(160px + (320 - 160) * ((100vw - 1080px) / (1440 - 1080)));
    padding-bottom: calc(160px + (320 - 160) * ((100vw - 1080px) / (1440 - 1080)));
  }
  @media (min-width: 768px) and (max-width: 1080px) {
    padding-top: calc(112px + (160 - 112) * ((100vw - 768px) / (1080 - 768)));
    padding-bottom: calc(112px + (160 - 112) * ((100vw - 768px) / (1080 - 768)));
  }
  @media (max-width: 768px) {
    padding-bottom: 112px;
  }
  @media (min-width: 320px) and (max-width: 768px) {
    padding-top: calc(80px + (112 - 80) * ((100vw - 320px) / (768 - 320)));
  }
  @media (min-width: 320px) and (max-width: 480px) {
    padding-bottom: calc(80px + (112 - 80) * ((100vw - 320px) / (480 - 320)));
  }

  .sauseSecret {
    display: flex;
    align-items: center;
    gap: 32px;

    @media (max-width: 1080px) {
      flex-direction: column-reverse;
      gap: 40px;
    }
    @media (min-width: 480px) and (max-width: 768px){
      gap: calc(32px + (40 - 32) * ((100vw - 480px) / (768 - 480)));
    }
    @media (max-width: 480px) {
      gap: 32px;
    }

    &__nftBlock {
      gap: 24px;
      background-image: url("../../../../images/mainPage/nftBG.png");
      display: flex;
      align-items: center;
      justify-content: center;
      background-size: contain;
      background-repeat: round;
      padding: 93px 42px 144px 42px;
      box-sizing: border-box;
      position: relative;

      @media (min-width: 1440px) and (max-width: 1920px){
        padding: calc(84px + (93 - 84) * ((100vw - 1440px) / (1920 - 1440))) 42px calc(121px + (144 - 121) * ((100vw - 1440px) / (1920 - 1440))) 42px;
      }
      @media (min-width: 1080px) and (max-width: 1440px){
        padding: calc(56px + (84 - 56) * ((100vw - 1080px) / (1440 - 1080)))
        calc(32px + (42 - 32) * ((100vw - 1080px) / (1440 - 1080)))
        calc(92px + (121 - 92) * ((100vw - 1080px) / (1440 - 1080)))
        calc(32px + (42 - 32) * ((100vw - 1080px) / (1440 - 1080)))
      };
      @media (max-width: 1080px) {
        padding: 45px 24px 80px 24px;
        background-image: url("../../../../images/mainPage/nftBGMob.png");
      }
      @media (min-width: 480px) and (max-width: 768px){
        gap: calc(
                16px + (24 - 16) * ((100vw - 480px) / (768 - 480))
        );
        padding: calc(36px + (45 - 35) * ((100vw - 480px) / (768 - 480)))
        calc(16px + (24 - 16) * ((100vw - 480px) / (768 - 480)))
        calc(65px + (80 - 65) * ((100vw - 480px) / (768 - 480)))
        calc(16px + (24 - 16) * ((100vw - 480px) / (768 - 480)))
      }
      @media (max-width: 480px) {
        padding: 36px 16px 65px 16px
      }
      @media (min-width: 320px) and (max-width: 480px){
        gap: calc(
                8px + (16 - 8) * ((100vw - 320px) / (480 - 320))
        );
      }

      .Slider3 {
        @media (max-width: 1080px) {
          display: none;
        }
      }
      .abs {
        position: absolute;
        top: 70px;
        width: 93%;
        height: 50px;
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(10px);
        mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
        z-index: 2;
        opacity: 1;
        transition: opacity 0.5s ease;

        @media (min-width: 1440px) and (max-width: 1920px){
          top: calc(60px + (70 - 60) * ((100vw - 1440px) / (1920 - 1440)));
        }
        @media (min-width: 1081px) and (max-width: 1440px){
          top: calc(30px + (60 - 30) * ((100vw - 1081px) / (1440 - 1081)));
        }
        @media (max-width: 1080px) {
          top: 20px;
        }
        @media (min-width: 480px) and (max-width: 768px){
          top: calc(10px + (20 - 10) * ((100vw - 480px) / (768 - 480)));
        }
        @media (max-width: 480px) {
          top: 10px;
        }
      }
      .abs2 {
        position: absolute;
        bottom: 125px;
        width: 93%;
        height: 50px;
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(10px);
        mask-image: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
        z-index: 2;
        opacity: 1;
        transition: opacity 0.5s ease;

        @media (min-width: 1440px) and (max-width: 1920px){
          bottom: calc(95px + (125 - 95) * ((100vw - 1440px) / (1920 - 1440)));
        }
        @media (min-width: 1081px) and (max-width: 1440px){
          bottom: calc(70px + (95 - 70) * ((100vw - 1081px) / (1440 - 1081)));
        }
        @media (max-width: 1080px) {
          bottom: 60px;
        }
        @media (min-width: 480px) and (max-width: 768px){
          bottom: calc(45px + (60 - 45) * ((100vw - 480px) / (768 - 480)));
        }
        @media (max-width: 480px) {
          bottom: 40px;
        }
      }
      .slick-slide {
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;

      }
      .slick-slide div {
        height: 100%;
      }
      .slick-slide div img {
        height: 100%;
        width: 285px !important;
        aspect-ratio: 0.91;

        @media (min-width: 1440px) and (max-width: 1920px){
          max-width: calc(
                  220px + (285 - 220) * ((100vw - 1440px) / (1920 - 1440))
          ) !important
        }
        @media (min-width: 1080px) and (max-width: 1440px){
          max-width: calc(
                  148px + (220 - 148) * ((100vw - 1080px) / (1440 - 1080))
          ) !important
        }
        @media (max-width: 1080px) {
          max-width: 298px !important;
        }
        @media (min-width: 480px) and (max-width: 768px){
          max-width: calc(
                  190px + (298 - 190) * ((100vw - 480px) / (768 - 480))
          ) !important;
        }
        @media (min-width: 320px) and (max-width: 480px){
          max-width: calc(
                  120px + (190 - 120) * ((100vw - 320px) / (480 - 320))
          ) !important;
        }

        object-fit: cover;
        display: block;
        margin-bottom: 24px;

        @media (min-width: 1080px) and (max-width: 1440px){
          margin-bottom: calc(
                  16px + (24 - 16) * ((100vw - 1080px) / (1440 - 1080))
          ) !important
        }
        @media (max-width: 1080px) {
          margin-bottom: 22px !important;
        }
        @media (min-width: 440px) and (max-width: 768px){
          margin-bottom: calc(
                  16px + (22 - 16) * ((100vw - 1080px) / (1440 - 1080))
          ) !important
        }
        @media (min-width: 320px) and (max-width: 480px){
          margin-bottom: calc(
                  8px + (16 - 8) * ((100vw - 320px) / (480 - 320))
          ) !important
        }

      }
      .slick-cloned div img {
        height: 100%;
        width: 285px !important;

        @media (min-width: 1440px) and (max-width: 1920px){
          max-width: calc(
                  220px + (285 - 220) * ((100vw - 1440px) / (1920 - 1440))
          ) !important
        }
        @media (min-width: 1080px) and (max-width: 1440px){
          max-width: calc(
                  148px + (220 - 148) * ((100vw - 1080px) / (1440 - 1080))
          ) !important
        }
        @media (max-width: 1080px) {
          max-width: 298px !important;
        }

        @media (min-width: 480px) and (max-width: 768px){
          max-width: calc(
                  190px + (298 - 190) * ((100vw - 480px) / (768 - 480))
          ) !important;
        }
        @media (min-width: 320px) and (max-width: 480px){
          max-width: calc(
                  120px + (190 - 120) * ((100vw - 320px) / (480 - 320))
          ) !important;
        }

        object-fit: cover;
        display: block;
        margin-bottom: 24px;

      }
      .slick-slider {
        max-width: 285px;

        @media (min-width: 1440px) and (max-width: 1920px){
          max-width: calc(
                  220px + (285 - 220) * ((100vw - 1440px) / (1920 - 1440))
          );
        }
        @media (min-width: 1080px) and (max-width: 1440px){
          max-width: calc(
                  148px + (220 - 148) * ((100vw - 1080px) / (1440 - 1080))
          );
        }
        @media (max-width: 1080px) {
          max-width: 298px;
        }
        @media (min-width: 480px) and (max-width: 768px){
          max-width: calc(
                  190px + (298 - 190) * ((100vw - 480px) / (768 - 480))
          );
        }
        @media (min-width: 320px) and (max-width: 480px){
          max-width: calc(
                  120px + (190 - 120) * ((100vw - 320px) / (480 - 320))
          ) !important;
        }

        button {
          display: none !important;
        }
      }
      .slick-list {
        height: 654px !important;
        @media (min-width: 1440px) and (max-width: 1920px){
          height: calc(
                  480px + (654 - 480) * ((100vw - 1440px) / (1920 - 1440))
          ) !important
        }
        @media (min-width: 1080px) and (max-width: 1440px){
          height: calc(
                  336px + (480 - 336) * ((100vw - 1080px) / (1440 - 1080))
          ) !important
        }
        @media (max-width: 1080px) {
          height: 800px !important;
        }
        @media (min-width: 480px) and (max-width: 768px){
          height: calc(
                  600px + (800 - 600) * ((100vw - 480px) / (768 - 480))
          ) !important
        }
        @media (min-width: 320px) and (max-width: 480px){
          height: calc(
                  400px + (600 - 400) * ((100vw - 320px) / (480 - 320))
          ) !important
        }
      }
    }
    &__contentBlock {
      &-title {
        font-size: 64px;
        font-family: Knewave;
        font-weight: 400;
        text-transform: uppercase;
        color: var(--font-color-white);
        margin-bottom: 42px;
        max-width: 590px;
        position: relative;

        @media (min-width: 1080px) and (max-width: 1440px){
          font-size: calc(
                  48px + (64 - 48) * ((100vw - 1080px) / (1440 - 1080))
          );
          margin-bottom: calc(
                  32px + (42 - 32) * ((100vw - 1080px) / (1440 - 1080))
          );
          line-height: calc(
                  68px + (74 - 68) * ((100vw - 1080px) / (1440 - 1080))
          );
        }
        @media (max-width: 1080px) {
          margin-bottom: 32px;
          font-size: 48px;
          max-width: 472px;
          line-height: 68px;
        }
        @media (min-width: 768px) and (max-width: 1080px){
          margin-bottom: calc(
                  30px + (32 - 30) * ((100vw - 768px) / (1080 - 768))
          );
        }
        @media (max-width: 768px) {
          margin-bottom: 30px;
        }

        @media (min-width: 480px) and (max-width: 768px){
          margin-bottom: calc(
                  30px + (32 - 30) * ((100vw - 480px) / (768 - 480))
          );
          font-size: calc(
                  32px + (48 - 32) * ((100vw - 480px) / (768 - 480))
          );
          line-height: calc(
                  40px + (68 - 40) * ((100vw - 480px) / (768 - 480))
          );
          max-width: calc(
                  300px + (472 - 300) * ((100vw - 480px) / (768 - 480))
          );
        }
        @media (min-width: 320px) and (max-width: 480px){
          font-size: calc(
                  24px + (32 - 24) * ((100vw - 320px) / (480 - 320))
          );
          line-height: calc(
                  32px + (40 - 32) * ((100vw - 320px) / (480 - 320))
          );
          margin-bottom: calc(
                  24px + (30 - 24) * ((100vw - 320px) / (480 - 320))
          );
          max-width: calc(
                  250px + (300 - 250) * ((100vw - 320px) / (480 - 320))
          );
        }

        img {
          position: absolute;
          bottom: 40px;
          left: 420px;
          width: 320px;

          @media (min-width: 1440px) and (max-width: 1920px) {
            left: calc(
                    340px + (420 - 340) * ((100vw - 1440px) / (1920 - 1440))
            );
            bottom: calc(
                    60px + (40 - 60) * ((100vw - 1440px) / (1920 - 1440))
            );
            width: calc(
                    240px + (320 - 240) * ((100vw - 1440px) / (1920 - 1440))
            );
          }
          @media (min-width: 1080px) and (max-width: 1440px) {
            left: calc(
                    220px + (340 - 220) * ((100vw - 1080px) / (1440 - 1080))
            );
            bottom: calc(
                    60px + (40 - 60) * ((100vw - 1440px) / (1920 - 1440))
            );
          }
          @media (max-width: 1440px) {
            bottom: 0px;
            right: 0;
            width: 240px;
          }

          @media (max-width: 1080px) {
            left: 100%;
            right: inherit;
            bottom: inherit;
            transform: translateY(-50%);
            width: 160px;
          }
          @media (max-width: 480px) {
            left: 80%;
            top: -20%;
          }
          @media (min-width: 320px) and (max-width: 480px){
            width: calc(
                    120px + (160 - 120) * ((100vw - 320px) / (480 - 320))
            );
          }

        }
      }
      &-description {
        font-size: 24px;
        font-family: Russo One;
        font-weight: 400;
        line-height: 30px;
        color: var(--font-color-gray);
        max-width: 659px;

        @media (min-width: 1440px) and (max-width: 1920px) {
          font-size: calc(
                  16px + (24 - 16) * ((100vw - 1440px) / (1920 - 1440))
          );
          line-height: calc(
                  24px + (30 - 24) * ((100vw - 1440px) / (1920 - 1440))
          );
          max-width: calc(
                  540px + (659 - 540) * ((100vw - 1440px) / (1920 - 1440))
          );

        }
        @media (max-width: 1440px) {
          font-size: 16px;
          line-height: 24px;
        }
        @media (min-width: 480px) and (max-width: 768px) {
          max-width: calc(
                  440px + (688 - 440) * ((100vw - 480px) / (768 - 480))
          );
        }
        @media (min-width: 320px) and (max-width: 480px) {
          font-size: calc(
                  14px + (16 - 14) * ((100vw - 320px) / (480 - 320))
          );
          line-height: calc(
                  21px + (24 - 21) * ((100vw - 320px) / (480 - 320))
          );
        }
      }
    }
  }
}

