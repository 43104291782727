  .w-icon-slider-right:before {
    content: "î˜€";
  }
  
  .w-icon-slider-left:before {
    content: "î˜";
  }
  
  .w-icon-nav-menu:before {
    content: "î˜‚";
  }
  
  .w-icon-arrow-down:before, .w-icon-dropdown-toggle:before {
    content: "î˜ƒ";
  }
  
  .w-icon-file-upload-remove:before {
    content: "î¤€";
  }
  
  .w-icon-file-upload-icon:before {
    content: "î¤ƒ";
  }
  
  * {
    box-sizing: border-box;
  }
  
  html {
    height: 100%;
  }
  
  img {
    vertical-align: middle;
    max-width: 100%;
    display: inline-block;
  }
  
  html.w-mod-touch * {
    background-attachment: scroll !important;
  }
  
  .w-block {
    display: block;
  }
  
  .w-inline-block {
    max-width: 100%;
    display: inline-block;
  }
  
  .w-clearfix:before, .w-clearfix:after {
    content: " ";
    grid-area: 1 / 1 / 2 / 2;
    display: table;
  }
  
  .w-clearfix:after {
    clear: both;
  }
  
  .w-hidden {
    display: none;
  }
  
  .w-button {
    color: #fff;
    line-height: inherit;
    cursor: pointer;
    background-color: #3898ec;
    border: 0;
    border-radius: 0;
    padding: 9px 15px;
    text-decoration: none;
    display: inline-block;
  }
  
  input.w-button {
    -webkit-appearance: button;
  }
  
  html[data-w-dynpage] [data-w-cloak] {
    color: rgba(0, 0, 0, 0) !important;
  }
  
  .w-code-block {
    margin: unset;
  }
  
  pre.w-code-block code {
    all: inherit;
  }
  
  .w-webflow-badge, .w-webflow-badge * {
    z-index: auto;
    visibility: visible;
    box-sizing: border-box;
    float: none;
    clear: none;
    box-shadow: none;
    opacity: 1;
    direction: ltr;
    font-family: inherit;
    font-weight: inherit;
    color: inherit;
    font-size: inherit;
    line-height: inherit;
    font-style: inherit;
    font-variant: inherit;
    text-align: inherit;
    letter-spacing: inherit;
    -webkit-text-decoration: inherit;
    text-decoration: inherit;
    text-indent: 0;
    text-transform: inherit;
    text-shadow: none;
    font-smoothing: auto;
    vertical-align: baseline;
    cursor: inherit;
    white-space: inherit;
    word-break: normal;
    word-spacing: normal;
    word-wrap: normal;
    background: none;
    border: 0 rgba(0, 0, 0, 0);
    border-radius: 0;
    width: auto;
    min-width: 0;
    max-width: none;
    height: auto;
    min-height: 0;
    max-height: none;
    margin: 0;
    padding: 0;
    list-style-type: disc;
    transition: none;
    display: block;
    position: static;
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
    overflow: visible;
    transform: none;
  }
  
  .w-webflow-badge {
    white-space: nowrap;
    cursor: pointer;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, .1), 0 1px 3px rgba(0, 0, 0, .1);
    visibility: visible !important;
    z-index: 2147483647 !important;
    color: #aaadb0 !important;
    opacity: 1 !important;
    background-color: #fff !important;
    border-radius: 3px !important;
    width: auto !important;
    height: auto !important;
    margin: 0 !important;
    padding: 6px !important;
    font-size: 12px !important;
    line-height: 14px !important;
    text-decoration: none !important;
    display: inline-block !important;
    position: fixed !important;
    top: auto !important;
    bottom: 12px !important;
    left: auto !important;
    right: 12px !important;
    overflow: visible !important;
    transform: none !important;
  }
  
  .w-webflow-badge > img {
    visibility: visible !important;
    opacity: 1 !important;
    vertical-align: middle !important;
    display: inline-block !important;
  }
  
  h1, h2, h3, h4, h5, h6 {
    margin-bottom: 10px;
    font-weight: bold;
  }
  
  h1 {
    margin-top: 20px;
    font-size: 38px;
    line-height: 44px;
  }
  
  h2 {
    margin-top: 20px;
    font-size: 32px;
    line-height: 36px;
  }
  
  h3 {
    margin-top: 20px;
    font-size: 24px;
    line-height: 30px;
  }
  
  h4 {
    margin-top: 10px;
    font-size: 18px;
    line-height: 24px;
  }
  
  h5 {
    margin-top: 10px;
    font-size: 14px;
    line-height: 20px;
  }
  
  h6 {
    margin-top: 10px;
    font-size: 12px;
    line-height: 18px;
  }
  
  p {
    margin-top: 0;
    margin-bottom: 10px;
  }
  
  blockquote {
    border-left: 5px solid #e2e2e2;
    margin: 0 0 10px;
    padding: 10px 20px;
    font-size: 18px;
    line-height: 22px;
  }
  
  figure {
    margin: 0 0 10px;
  }
  
  figcaption {
    text-align: center;
    margin-top: 5px;
  }
  
  ul, ol {
    margin-top: 0;
    margin-bottom: 10px;
    padding-left: 40px;
  }
  
  .w-list-unstyled {
    padding-left: 0;
    list-style: none;
  }
  
  .w-embed:before, .w-embed:after {
    content: " ";
    grid-area: 1 / 1 / 2 / 2;
    display: table;
  }
  
  .w-embed:after {
    clear: both;
  }
  
  .w-video {
    width: 100%;
    padding: 0;
    position: relative;
  }
  
  .w-video iframe, .w-video object, .w-video embed {
    border: none;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  
  fieldset {
    border: 0;
    margin: 0;
    padding: 0;
  }
  
  button, [type="button"], [type="reset"] {
    cursor: pointer;
    -webkit-appearance: button;
    border: 0;
  }
  
  .w-form {
    margin: 0 0 15px;
  }
  
  .w-form-done {
    text-align: center;
    background-color: #ddd;
    padding: 20px;
    display: none;
  }
  
  .w-form-fail {
    background-color: #ffdede;
    margin-top: 10px;
    padding: 10px;
    display: none;
  }
  
  label {
    margin-bottom: 5px;
    font-weight: bold;
    display: block;
  }
  
  .w-input, .w-select {
    color: #333;
    vertical-align: middle;
    background-color: #fff;
    border: 1px solid #ccc;
    width: 100%;
    height: 38px;
    margin-bottom: 10px;
    padding: 8px 12px;
    font-size: 14px;
    line-height: 1.42857;
    display: block;
  }
  
  .w-input:-moz-placeholder, .w-select:-moz-placeholder {
    color: #999;
  }
  
  .w-input::-moz-placeholder, .w-select::-moz-placeholder {
    color: #999;
    opacity: 1;
  }
  
  .w-input::-webkit-input-placeholder, .w-select::-webkit-input-placeholder {
    color: #999;
  }
  
  .w-input:focus, .w-select:focus {
    border-color: #3898ec;
    outline: 0;
  }
  
  .w-input[disabled], .w-select[disabled], .w-input[readonly], .w-select[readonly], fieldset[disabled] .w-input, fieldset[disabled] .w-select {
    cursor: not-allowed;
  }
  
  .w-input[disabled]:not(.w-input-disabled), .w-select[disabled]:not(.w-input-disabled), .w-input[readonly], .w-select[readonly], fieldset[disabled]:not(.w-input-disabled) .w-input, fieldset[disabled]:not(.w-input-disabled) .w-select {
    background-color: #eee;
  }
  
  textarea.w-input, textarea.w-select {
    height: auto;
  }
  
  .w-select {
    background-color: #f3f3f3;
  }
  
  .w-select[multiple] {
    height: auto;
  }
  
  .w-form-label {
    cursor: pointer;
    margin-bottom: 0;
    font-weight: normal;
    display: inline-block;
  }
  
  .w-radio {
    margin-bottom: 5px;
    padding-left: 20px;
    display: block;
  }
  
  .w-radio:before, .w-radio:after {
    content: " ";
    grid-area: 1 / 1 / 2 / 2;
    display: table;
  }
  
  .w-radio:after {
    clear: both;
  }
  
  .w-radio-input {
    float: left;
    margin: 3px 0 0 -20px;
    line-height: normal;
  }
  
  .w-file-upload {
    margin-bottom: 10px;
    display: block;
  }
  
  .w-file-upload-input {
    opacity: 0;
    z-index: -100;
    width: .1px;
    height: .1px;
    position: absolute;
    overflow: hidden;
  }
  
  .w-file-upload-default, .w-file-upload-uploading, .w-file-upload-success {
    color: #333;
    display: inline-block;
  }
  
  .w-file-upload-error {
    margin-top: 10px;
    display: block;
  }
  
  .w-file-upload-default.w-hidden, .w-file-upload-uploading.w-hidden, .w-file-upload-error.w-hidden, .w-file-upload-success.w-hidden {
    display: none;
  }
  
  .w-file-upload-uploading-btn {
    cursor: pointer;
    background-color: #fafafa;
    border: 1px solid #ccc;
    margin: 0;
    padding: 8px 12px;
    font-size: 14px;
    font-weight: normal;
    display: flex;
  }
  
  .w-file-upload-file {
    background-color: #fafafa;
    border: 1px solid #ccc;
    flex-grow: 1;
    justify-content: space-between;
    margin: 0;
    padding: 8px 9px 8px 11px;
    display: flex;
  }
  
  .w-file-upload-file-name {
    font-size: 14px;
    font-weight: normal;
    display: block;
  }
  
  .w-file-remove-link {
    cursor: pointer;
    width: auto;
    height: auto;
    margin-top: 3px;
    margin-left: 10px;
    padding: 3px;
    display: block;
  }
  
  .w-icon-file-upload-remove {
    margin: auto;
    font-size: 10px;
  }
  
  .w-file-upload-error-msg {
    color: #ea384c;
    padding: 2px 0;
    display: inline-block;
  }
  
  .w-file-upload-info {
    padding: 0 12px;
    line-height: 38px;
    display: inline-block;
  }
  
  .w-file-upload-label {
    cursor: pointer;
    background-color: #fafafa;
    border: 1px solid #ccc;
    margin: 0;
    padding: 8px 12px;
    font-size: 14px;
    font-weight: normal;
    display: inline-block;
  }
  
  .w-icon-file-upload-icon, .w-icon-file-upload-uploading {
    width: 20px;
    margin-right: 8px;
    display: inline-block;
  }
  
  .w-icon-file-upload-uploading {
    height: 20px;
  }
  
  .w-container {
    max-width: 940px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .w-container:before, .w-container:after {
    content: " ";
    grid-area: 1 / 1 / 2 / 2;
    display: table;
  }
  
  .w-container:after {
    clear: both;
  }
  
  .w-container .w-row {
    margin-left: -10px;
    margin-right: -10px;
  }
  
  .w-row:before, .w-row:after {
    content: " ";
    grid-area: 1 / 1 / 2 / 2;
    display: table;
  }
  
  .w-row:after {
    clear: both;
  }
  
  .w-row .w-row {
    margin-left: 0;
    margin-right: 0;
  }
  
  .w-col {
    float: left;
    width: 100%;
    min-height: 1px;
    padding-left: 10px;
    padding-right: 10px;
    position: relative;
  }
  
  .w-col .w-col {
    padding-left: 0;
    padding-right: 0;
  }
  
  .w-col-1 {
    width: 8.33333%;
  }
  
  .w-col-2 {
    width: 16.6667%;
  }
  
  .w-col-3 {
    width: 25%;
  }
  
  .w-col-4 {
    width: 33.3333%;
  }
  
  .w-col-5 {
    width: 41.6667%;
  }
  
  .w-col-6 {
    width: 50%;
  }
  
  .w-col-7 {
    width: 58.3333%;
  }
  
  .w-col-8 {
    width: 66.6667%;
  }
  
  .w-col-9 {
    width: 75%;
  }
  
  .w-col-10 {
    width: 83.3333%;
  }
  
  .w-col-11 {
    width: 91.6667%;
  }
  
  .w-col-12 {
    width: 100%;
  }
  
  .w-hidden-main {
    display: none !important;
  }
  
  @media screen and (max-width: 991px) {
    .w-container {
      max-width: 728px;
    }
  
    .w-hidden-main {
      display: inherit !important;
    }
  
    .w-hidden-medium {
      display: none !important;
    }
  
    .w-col-medium-1 {
      width: 8.33333%;
    }
  
    .w-col-medium-2 {
      width: 16.6667%;
    }
  
    .w-col-medium-3 {
      width: 25%;
    }
  
    .w-col-medium-4 {
      width: 33.3333%;
    }
  
    .w-col-medium-5 {
      width: 41.6667%;
    }
  
    .w-col-medium-6 {
      width: 50%;
    }
  
    .w-col-medium-7 {
      width: 58.3333%;
    }
  
    .w-col-medium-8 {
      width: 66.6667%;
    }
  
    .w-col-medium-9 {
      width: 75%;
    }
  
    .w-col-medium-10 {
      width: 83.3333%;
    }
  
    .w-col-medium-11 {
      width: 91.6667%;
    }
  
    .w-col-medium-12 {
      width: 100%;
    }
  
    .w-col-stack {
      width: 100%;
      left: auto;
      right: auto;
    }
  }
  
  @media screen and (max-width: 767px) {
    .w-hidden-main, .w-hidden-medium {
      display: inherit !important;
    }
  
    .w-hidden-small {
      display: none !important;
    }
  
    .w-row, .w-container .w-row {
      margin-left: 0;
      margin-right: 0;
    }
  
    .w-col {
      width: 100%;
      left: auto;
      right: auto;
    }
  
    .w-col-small-1 {
      width: 8.33333%;
    }
  
    .w-col-small-2 {
      width: 16.6667%;
    }
  
    .w-col-small-3 {
      width: 25%;
    }
  
    .w-col-small-4 {
      width: 33.3333%;
    }
  
    .w-col-small-5 {
      width: 41.6667%;
    }
  
    .w-col-small-6 {
      width: 50%;
    }
  
    .w-col-small-7 {
      width: 58.3333%;
    }
  
    .w-col-small-8 {
      width: 66.6667%;
    }
  
    .w-col-small-9 {
      width: 75%;
    }
  
    .w-col-small-10 {
      width: 83.3333%;
    }
  
    .w-col-small-11 {
      width: 91.6667%;
    }
  
    .w-col-small-12 {
      width: 100%;
    }
  }
  
  @media screen and (max-width: 479px) {
    .w-container {
      max-width: none;
    }
  
    .w-hidden-main, .w-hidden-medium, .w-hidden-small {
      display: inherit !important;
    }
  
    .w-hidden-tiny {
      display: none !important;
    }
  
    .w-col {
      width: 100%;
    }
  
    .w-col-tiny-1 {
      width: 8.33333%;
    }
  
    .w-col-tiny-2 {
      width: 16.6667%;
    }
  
    .w-col-tiny-3 {
      width: 25%;
    }
  
    .w-col-tiny-4 {
      width: 33.3333%;
    }
  
    .w-col-tiny-5 {
      width: 41.6667%;
    }
  
    .w-col-tiny-6 {
      width: 50%;
    }
  
    .w-col-tiny-7 {
      width: 58.3333%;
    }
  
    .w-col-tiny-8 {
      width: 66.6667%;
    }
  
    .w-col-tiny-9 {
      width: 75%;
    }
  
    .w-col-tiny-10 {
      width: 83.3333%;
    }
  
    .w-col-tiny-11 {
      width: 91.6667%;
    }
  
    .w-col-tiny-12 {
      width: 100%;
    }
  }
  
  .w-widget {
    position: relative;
  }
  
  .w-widget-map {
    width: 100%;
    height: 400px;
  }
  
  .w-widget-map label {
    width: auto;
    display: inline;
  }
  
  .w-widget-map img {
    max-width: inherit;
  }
  
  .w-widget-map .gm-style-iw {
    text-align: center;
  }
  
  .w-widget-map .gm-style-iw > button {
    display: none !important;
  }
  
  .w-widget-twitter {
    overflow: hidden;
  }
  
  .w-widget-twitter-count-shim {
    vertical-align: top;
    text-align: center;
    background: #fff;
    border: 1px solid #758696;
    border-radius: 3px;
    width: 28px;
    height: 20px;
    display: inline-block;
    position: relative;
  }
  
  .w-widget-twitter-count-shim * {
    pointer-events: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  .w-widget-twitter-count-shim .w-widget-twitter-count-inner {
    text-align: center;
    color: #999;
    font-family: serif;
    font-size: 15px;
    line-height: 12px;
    position: relative;
  }
  
  .w-widget-twitter-count-shim .w-widget-twitter-count-clear {
    display: block;
    position: relative;
  }
  
  .w-widget-twitter-count-shim.w--large {
    width: 36px;
    height: 28px;
  }
  
  .w-widget-twitter-count-shim.w--large .w-widget-twitter-count-inner {
    font-size: 18px;
    line-height: 18px;
  }
  
  .w-widget-twitter-count-shim:not(.w--vertical) {
    margin-left: 5px;
    margin-right: 8px;
  }
  
  .w-widget-twitter-count-shim:not(.w--vertical).w--large {
    margin-left: 6px;
  }
  
  .w-widget-twitter-count-shim:not(.w--vertical):before, .w-widget-twitter-count-shim:not(.w--vertical):after {
    content: " ";
    pointer-events: none;
    border: solid rgba(0, 0, 0, 0);
    width: 0;
    height: 0;
    position: absolute;
    top: 50%;
    left: 0;
  }
  
  .w-widget-twitter-count-shim:not(.w--vertical):before {
    border-width: 4px;
    border-color: rgba(117, 134, 150, 0) #5d6c7b rgba(117, 134, 150, 0) rgba(117, 134, 150, 0);
    margin-top: -4px;
    margin-left: -9px;
  }
  
  .w-widget-twitter-count-shim:not(.w--vertical).w--large:before {
    border-width: 5px;
    margin-top: -5px;
    margin-left: -10px;
  }
  
  .w-widget-twitter-count-shim:not(.w--vertical):after {
    border-width: 4px;
    border-color: rgba(255, 255, 255, 0) #fff rgba(255, 255, 255, 0) rgba(255, 255, 255, 0);
    margin-top: -4px;
    margin-left: -8px;
  }
  
  .w-widget-twitter-count-shim:not(.w--vertical).w--large:after {
    border-width: 5px;
    margin-top: -5px;
    margin-left: -9px;
  }
  
  .w-widget-twitter-count-shim.w--vertical {
    width: 61px;
    height: 33px;
    margin-bottom: 8px;
  }
  
  .w-widget-twitter-count-shim.w--vertical:before, .w-widget-twitter-count-shim.w--vertical:after {
    content: " ";
    pointer-events: none;
    border: solid rgba(0, 0, 0, 0);
    width: 0;
    height: 0;
    position: absolute;
    top: 100%;
    left: 50%;
  }
  
  .w-widget-twitter-count-shim.w--vertical:before {
    border-width: 5px;
    border-color: #5d6c7b rgba(117, 134, 150, 0) rgba(117, 134, 150, 0);
    margin-left: -5px;
  }
  
  .w-widget-twitter-count-shim.w--vertical:after {
    border-width: 4px;
    border-color: #fff rgba(255, 255, 255, 0) rgba(255, 255, 255, 0);
    margin-left: -4px;
  }
  
  .w-widget-twitter-count-shim.w--vertical .w-widget-twitter-count-inner {
    font-size: 18px;
    line-height: 22px;
  }
  
  .w-widget-twitter-count-shim.w--vertical.w--large {
    width: 76px;
  }
  
  .w-background-video {
    color: #fff;
    height: 500px;
    position: relative;
    overflow: hidden;
  }
  
  .w-background-video > video {
    object-fit: cover;
    z-index: -100;
    background-position: 50%;
    background-size: cover;
    width: 100%;
    height: 100%;
    margin: auto;
    position: absolute;
    top: -100%;
    bottom: -100%;
    left: -100%;
    right: -100%;
  }
  
  .w-background-video > video::-webkit-media-controls-start-playback-button {
    -webkit-appearance: none;
    display: none !important;
  }
  
  .w-background-video--control {
    background-color: rgba(0, 0, 0, 0);
    padding: 0;
    position: absolute;
    bottom: 1em;
    right: 1em;
  }
  
  .w-background-video--control > [hidden] {
    display: none !important;
  }
  
  .w-slider {
    text-align: center;
    clear: both;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    tap-highlight-color: rgba(0, 0, 0, 0);
    background: #ddd;
    height: 300px;
    position: relative;
  }
  
  .w-slider-mask {
    z-index: 1;
    white-space: nowrap;
    height: 100%;
    display: block;
    position: relative;
    left: 0;
    right: 0;
    overflow: hidden;
  }
  
  .w-slide {
    vertical-align: top;
    white-space: normal;
    text-align: left;
    width: 100%;
    height: 100%;
    display: inline-block;
    position: relative;
  }
  
  .w-slider-nav {
    z-index: 2;
    text-align: center;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    tap-highlight-color: rgba(0, 0, 0, 0);
    height: 40px;
    margin: auto;
    padding-top: 10px;
    position: absolute;
    top: auto;
    bottom: 0;
    left: 0;
    right: 0;
  }
  
  .w-slider-nav.w-round > div {
    border-radius: 100%;
  }
  
  .w-slider-nav.w-num > div {
    font-size: inherit;
    line-height: inherit;
    width: auto;
    height: auto;
    padding: .2em .5em;
  }
  
  .w-slider-nav.w-shadow > div {
    box-shadow: 0 0 3px rgba(51, 51, 51, .4);
  }
  
  .w-slider-nav-invert {
    color: #fff;
  }
  
  .w-slider-nav-invert > div {
    background-color: rgba(34, 34, 34, .4);
  }
  
  .w-slider-nav-invert > div.w-active {
    background-color: #222;
  }
  
  .w-slider-dot {
    cursor: pointer;
    background-color: rgba(255, 255, 255, .4);
    width: 1em;
    height: 1em;
    margin: 0 3px .5em;
    transition: background-color .1s, color .1s;
    display: inline-block;
    position: relative;
  }
  
  .w-slider-dot.w-active {
    background-color: #fff;
  }
  
  .w-slider-dot:focus {
    outline: none;
    box-shadow: 0 0 0 2px #fff;
  }
  
  .w-slider-dot:focus.w-active {
    box-shadow: none;
  }
  
  .w-slider-arrow-left, .w-slider-arrow-right {
    cursor: pointer;
    color: #fff;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 80px;
    margin: auto;
    font-size: 40px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
  }
  
  .w-slider-arrow-left [class^="w-icon-"], .w-slider-arrow-right [class^="w-icon-"], .w-slider-arrow-left [class*=" w-icon-"], .w-slider-arrow-right [class*=" w-icon-"] {
    position: absolute;
  }
  
  .w-slider-arrow-left:focus, .w-slider-arrow-right:focus {
    outline: 0;
  }
  
  .w-slider-arrow-left {
    z-index: 3;
    right: auto;
  }
  
  .w-slider-arrow-right {
    z-index: 4;
    left: auto;
  }
  
  .w-icon-slider-left, .w-icon-slider-right {
    width: 1em;
    height: 1em;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  
  .w-slider-aria-label {
    clip: rect(0 0 0 0);
    border: 0;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    position: absolute;
    overflow: hidden;
  }
  
  .w-slider-force-show {
    display: block !important;
  }
  
  .w-dropdown {
    text-align: left;
    z-index: 900;
    margin-left: auto;
    margin-right: auto;
    display: inline-block;
    position: relative;
  }
  
  .w-dropdown-btn, .w-dropdown-toggle, .w-dropdown-link {
    vertical-align: top;
    color: #222;
    text-align: left;
    white-space: nowrap;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    text-decoration: none;
    position: relative;
  }
  
  .w-dropdown-toggle {
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
    padding-right: 40px;
    display: inline-block;
  }
  
  .w-dropdown-toggle:focus {
    outline: 0;
  }
  
  .w-icon-dropdown-toggle {
    width: 1em;
    height: 1em;
    margin: auto 20px auto auto;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
  }
  
  .w-dropdown-list {
    background: #ddd;
    min-width: 100%;
    display: none;
    position: absolute;
  }
  
  .w-dropdown-list.w--open {
    display: block;
  }
  
  .w-dropdown-link {
    color: #222;
    padding: 10px 20px;
    display: block;
  }
  
  .w-dropdown-link.w--current {
    color: #0082f3;
  }
  
  .w-dropdown-link:focus {
    outline: 0;
  }
  
  @media screen and (max-width: 767px) {
    .w-nav-brand {
      padding-left: 10px;
    }
  }
  
  .w-lightbox-backdrop {
    cursor: auto;
    letter-spacing: normal;
    text-indent: 0;
    text-shadow: none;
    text-transform: none;
    visibility: visible;
    white-space: normal;
    word-break: normal;
    word-spacing: normal;
    word-wrap: normal;
    color: #fff;
    text-align: center;
    z-index: 2000;
    opacity: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -webkit-tap-highlight-color: transparent;
    background: rgba(0, 0, 0, .9);
    outline: 0;
    font-family: Helvetica Neue, Helvetica, Ubuntu, Segoe UI, Verdana, sans-serif;
    font-size: 17px;
    font-style: normal;
    font-weight: 300;
    line-height: 1.2;
    list-style: disc;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    -webkit-transform: translate(0);
  }
  
  .w-lightbox-backdrop, .w-lightbox-container {
    -webkit-overflow-scrolling: touch;
    height: 100%;
    overflow: auto;
  }
  
  .w-lightbox-content {
    height: 100vh;
    position: relative;
    overflow: hidden;
  }
  
  .w-lightbox-view {
    opacity: 0;
    width: 100vw;
    height: 100vh;
    position: absolute;
  }
  
  .w-lightbox-view:before {
    content: "";
    height: 100vh;
  }
  
  .w-lightbox-group, .w-lightbox-group .w-lightbox-view, .w-lightbox-group .w-lightbox-view:before {
    height: 86vh;
  }
  
  .w-lightbox-frame, .w-lightbox-view:before {
    vertical-align: middle;
    display: inline-block;
  }
  
  .w-lightbox-figure {
    margin: 0;
    position: relative;
  }
  
  .w-lightbox-group .w-lightbox-figure {
    cursor: pointer;
  }
  
  .w-lightbox-img {
    width: auto;
    max-width: none;
    height: auto;
  }
  
  .w-lightbox-image {
    float: none;
    max-width: 100vw;
    max-height: 100vh;
    display: block;
  }
  
  .w-lightbox-group .w-lightbox-image {
    max-height: 86vh;
  }
  
  .w-lightbox-caption {
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    background: rgba(0, 0, 0, .4);
    padding: .5em 1em;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
  }
  
  .w-lightbox-embed {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  
  .w-lightbox-control {
    cursor: pointer;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 24px;
    width: 4em;
    transition: all .3s;
    position: absolute;
    top: 0;
  }
  
  .w-lightbox-left {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii0yMCAwIDI0IDQwIiB3aWR0aD0iMjQiIGhlaWdodD0iNDAiPjxnIHRyYW5zZm9ybT0icm90YXRlKDQ1KSI+PHBhdGggZD0ibTAgMGg1djIzaDIzdjVoLTI4eiIgb3BhY2l0eT0iLjQiLz48cGF0aCBkPSJtMSAxaDN2MjNoMjN2M2gtMjZ6IiBmaWxsPSIjZmZmIi8+PC9nPjwvc3ZnPg==");
    display: none;
    bottom: 0;
    left: 0;
  }
  
  .w-lightbox-right {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii00IDAgMjQgNDAiIHdpZHRoPSIyNCIgaGVpZ2h0PSI0MCI+PGcgdHJhbnNmb3JtPSJyb3RhdGUoNDUpIj48cGF0aCBkPSJtMC0waDI4djI4aC01di0yM2gtMjN6IiBvcGFjaXR5PSIuNCIvPjxwYXRoIGQ9Im0xIDFoMjZ2MjZoLTN2LTIzaC0yM3oiIGZpbGw9IiNmZmYiLz48L2c+PC9zdmc+");
    display: none;
    bottom: 0;
    right: 0;
  }
  
  .w-lightbox-close {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii00IDAgMTggMTciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxNyI+PGcgdHJhbnNmb3JtPSJyb3RhdGUoNDUpIj48cGF0aCBkPSJtMCAwaDd2LTdoNXY3aDd2NWgtN3Y3aC01di03aC03eiIgb3BhY2l0eT0iLjQiLz48cGF0aCBkPSJtMSAxaDd2LTdoM3Y3aDd2M2gtN3Y3aC0zdi03aC03eiIgZmlsbD0iI2ZmZiIvPjwvZz48L3N2Zz4=");
    background-size: 18px;
    height: 2.6em;
    right: 0;
  }
  
  .w-lightbox-strip {
    white-space: nowrap;
    padding: 0 1vh;
    line-height: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    overflow-x: auto;
    overflow-y: hidden;
  }
  
  .w-lightbox-item {
    box-sizing: content-box;
    cursor: pointer;
    width: 10vh;
    padding: 2vh 1vh;
    display: inline-block;
    -webkit-transform: translate3d(0, 0, 0);
  }
  
  .w-lightbox-active {
    opacity: .3;
  }
  
  .w-lightbox-thumbnail {
    background: #222;
    height: 10vh;
    position: relative;
    overflow: hidden;
  }
  
  .w-lightbox-thumbnail-image {
    position: absolute;
    top: 0;
    left: 0;
  }
  
  .w-lightbox-thumbnail .w-lightbox-tall {
    width: 100%;
    top: 50%;
    transform: translate(0, -50%);
  }
  
  .w-lightbox-thumbnail .w-lightbox-wide {
    height: 100%;
    left: 50%;
    transform: translate(-50%);
  }
  
  .w-lightbox-spinner {
    box-sizing: border-box;
    border: 5px solid rgba(0, 0, 0, .4);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin-top: -20px;
    margin-left: -20px;
    animation: .8s linear infinite spin;
    position: absolute;
    top: 50%;
    left: 50%;
  }
  
  .w-lightbox-spinner:after {
    content: "";
    border: 3px solid rgba(0, 0, 0, 0);
    border-bottom-color: #fff;
    border-radius: 50%;
    position: absolute;
    top: -4px;
    bottom: -4px;
    left: -4px;
    right: -4px;
  }
  
  .w-lightbox-hide {
    display: none;
  }
  
  .w-lightbox-noscroll {
    overflow: hidden;
  }
  
  @media (min-width: 768px) {
    .w-lightbox-content {
      height: 96vh;
      margin-top: 2vh;
    }
  
    .w-lightbox-view, .w-lightbox-view:before {
      height: 96vh;
    }
  
    .w-lightbox-group, .w-lightbox-group .w-lightbox-view, .w-lightbox-group .w-lightbox-view:before {
      height: 84vh;
    }
  
    .w-lightbox-image {
      max-width: 96vw;
      max-height: 96vh;
    }
  
    .w-lightbox-group .w-lightbox-image {
      max-width: 82.3vw;
      max-height: 84vh;
    }
  
    .w-lightbox-left, .w-lightbox-right {
      opacity: .5;
      display: block;
    }
  
    .w-lightbox-close {
      opacity: .8;
    }
  
    .w-lightbox-control:hover {
      opacity: 1;
    }
  }
  
  .w-lightbox-inactive, .w-lightbox-inactive:hover {
    opacity: 0;
  }
  
  .w-richtext:before, .w-richtext:after {
    content: " ";
    grid-area: 1 / 1 / 2 / 2;
    display: table;
  }
  
  .w-richtext:after {
    clear: both;
  }
  
  .w-richtext[contenteditable="true"]:before, .w-richtext[contenteditable="true"]:after {
    white-space: initial;
  }
  
  .w-richtext ol, .w-richtext ul {
    overflow: hidden;
  }
  
  .w-richtext .w-richtext-figure-selected.w-richtext-figure-type-video div:after, .w-richtext .w-richtext-figure-selected[data-rt-type="video"] div:after, .w-richtext .w-richtext-figure-selected.w-richtext-figure-type-image div, .w-richtext .w-richtext-figure-selected[data-rt-type="image"] div {
    outline: 2px solid #2895f7;
  }
  
  .w-richtext figure.w-richtext-figure-type-video > div:after, .w-richtext figure[data-rt-type="video"] > div:after {
    content: "";
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  
  .w-richtext figure {
    max-width: 60%;
    position: relative;
  }
  
  .w-richtext figure > div:before {
    cursor: default !important;
  }
  
  .w-richtext figure img {
    width: 100%;
  }
  
  .w-richtext figure figcaption.w-richtext-figcaption-placeholder {
    opacity: .6;
  }
  
  .w-richtext figure div {
    color: rgba(0, 0, 0, 0);
    font-size: 0;
  }
  
  .w-richtext figure.w-richtext-figure-type-image, .w-richtext figure[data-rt-type="image"] {
    display: table;
  }
  
  .w-richtext figure.w-richtext-figure-type-image > div, .w-richtext figure[data-rt-type="image"] > div {
    display: inline-block;
  }
  
  .w-richtext figure.w-richtext-figure-type-image > figcaption, .w-richtext figure[data-rt-type="image"] > figcaption {
    caption-side: bottom;
    display: table-caption;
  }
  
  .w-richtext figure.w-richtext-figure-type-video, .w-richtext figure[data-rt-type="video"] {
    width: 60%;
    height: 0;
  }
  
  .w-richtext figure.w-richtext-figure-type-video iframe, .w-richtext figure[data-rt-type="video"] iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  
  .w-richtext figure.w-richtext-figure-type-video > div, .w-richtext figure[data-rt-type="video"] > div {
    width: 100%;
  }
  
  .w-richtext figure.w-richtext-align-center {
    clear: both;
    margin-left: auto;
    margin-right: auto;
  }
  
  .w-richtext figure.w-richtext-align-center.w-richtext-figure-type-image > div, .w-richtext figure.w-richtext-align-center[data-rt-type="image"] > div {
    max-width: 100%;
  }
  
  .w-richtext figure.w-richtext-align-normal {
    clear: both;
  }
  
  .w-richtext figure.w-richtext-align-fullwidth {
    text-align: center;
    clear: both;
    width: 100%;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
  
  .w-richtext figure.w-richtext-align-fullwidth > div {
    padding-bottom: inherit;
    display: inline-block;
  }
  
  .w-richtext figure.w-richtext-align-fullwidth > figcaption {
    display: block;
  }
  
  .w-richtext figure.w-richtext-align-floatleft {
    float: left;
    clear: none;
    margin-right: 15px;
  }
  
  .w-richtext figure.w-richtext-align-floatright {
    float: right;
    clear: none;
    margin-left: 15px;
  }
  
  .w-nav {
    z-index: 1000;
    background: #ddd;
    position: relative;
  }
  
  .w-nav:before, .w-nav:after {
    content: " ";
    grid-area: 1 / 1 / 2 / 2;
    display: table;
  }
  
  .w-nav:after {
    clear: both;
  }
  
  .w-nav-brand {
    float: left;
    color: #333;
    text-decoration: none;
    position: relative;
  }
  
  .w-nav-link {
    vertical-align: top;
    color: #222;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    text-decoration: none;
    display: inline-block;
    position: relative;
  }
  
  .w-nav-link.w--current {
    color: #0082f3;
  }
  
  .w-nav-menu {
    float: right;
    position: relative;
  }
  
  [data-nav-menu-open] {
    text-align: center;
    background: #c8c8c8;
    min-width: 200px;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    overflow: visible;
    display: block !important;
  }
  
  .w--nav-link-open {
    display: block;
    position: relative;
  }
  
  .w-nav-overlay {
    width: 100%;
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    overflow: hidden;
  }
  
  .w-nav-overlay [data-nav-menu-open] {
    top: 0;
  }
  
  .w-nav[data-animation="over-left"] .w-nav-overlay {
    width: auto;
  }
  
  .w-nav[data-animation="over-left"] .w-nav-overlay, .w-nav[data-animation="over-left"] [data-nav-menu-open] {
    z-index: 1;
    top: 0;
    right: auto;
  }
  
  .w-nav[data-animation="over-right"] .w-nav-overlay {
    width: auto;
  }
  
  .w-nav[data-animation="over-right"] .w-nav-overlay, .w-nav[data-animation="over-right"] [data-nav-menu-open] {
    z-index: 1;
    top: 0;
    left: auto;
  }
  
  .w-nav-button {
    float: right;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 18px;
    font-size: 24px;
    display: none;
    position: relative;
  }
  
  .w-nav-button:focus {
    outline: 0;
  }
  
  .w-nav-button.w--open {
    color: #fff;
    background-color: #c8c8c8;
  }
  
  .w-nav[data-collapse="all"] .w-nav-menu {
    display: none;
  }
  
  .w-nav[data-collapse="all"] .w-nav-button, .w--nav-dropdown-open, .w--nav-dropdown-toggle-open {
    display: block;
  }
  
  .w--nav-dropdown-list-open {
    position: static;
  }
  
  @media screen and (max-width: 991px) {
    .w-nav[data-collapse="medium"] .w-nav-menu {
      display: none;
    }
  
    .w-nav[data-collapse="medium"] .w-nav-button {
      display: block;
    }
  }
  
  @media screen and (max-width: 767px) {
    .w-nav[data-collapse="small"] .w-nav-menu {
      display: none;
    }
  
    .w-nav[data-collapse="small"] .w-nav-button {
      display: block;
    }
  
    .w-nav-brand {
      padding-left: 10px;
    }
  }
  
  @media screen and (max-width: 479px) {
    .w-nav[data-collapse="tiny"] .w-nav-menu {
      display: none;
    }
  
    .w-nav[data-collapse="tiny"] .w-nav-button {
      display: block;
    }
  }
  
  .w-tabs {
    position: relative;
  }
  
  .w-tabs:before, .w-tabs:after {
    content: " ";
    grid-area: 1 / 1 / 2 / 2;
    display: table;
  }
  
  .w-tabs:after {
    clear: both;
  }
  
  .w-tab-menu {
    position: relative;
  }
  
  .w-tab-link {
    vertical-align: top;
    text-align: left;
    cursor: pointer;
    color: #222;
    background-color: #ddd;
    padding: 9px 30px;
    text-decoration: none;
    display: inline-block;
    position: relative;
  }
  
  .w-tab-link.w--current {
    background-color: #c8c8c8;
  }
  
  .w-tab-link:focus {
    outline: 0;
  }
  
  .w-tab-content {
    display: block;
    position: relative;
    overflow: hidden;
  }
  
  .w-tab-pane {
    display: none;
    position: relative;
  }
  
  .w--tab-active {
    display: block;
  }
  
  @media screen and (max-width: 479px) {
    .w-tab-link {
      display: block;
    }
  }
  
  .w-ix-emptyfix:after {
    content: "";
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
  
  .w-dyn-empty {
    background-color: #ddd;
    padding: 10px;
  }
  
  .w-dyn-hide, .w-dyn-bind-empty, .w-condition-invisible {
    display: none !important;
  }
  
  .wf-layout-layout {
    display: grid;
  }
  
  .w-code-component > * {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  
  :root {
    --main-accent: yellow;
    --red: #fe6d0a;
    --purple: #604fee;
    --black: black;
    --white: white;
  }
    
  .hero {
    background-image: linear-gradient(0deg, #131313, rgba(255, 255, 255, 0)), url("https://cdn.prod.website-files.com/663d1241e2f31fd3a4af7229/663d130de2f31fd3a4b01d3c_sugarheads-bg.webp");
    background-position: 0 0, 50%;
    background-size: auto, cover;
    background-attachment: scroll, fixed;
    justify-content: center;
    align-items: center;
    height: 100vh;
    display: flex;
  }
  
  .container {
    width: 80%;
    max-width: 1400px;
  }
  
  .container.full-width {
    width: 100%;
    max-width: 100%;
    padding: 0 10px;
  }
  
  .hero-wrap {
    grid-column-gap: 40px;
    grid-row-gap: 40px;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    display: flex;
    position: relative;
  }
  
  .hero-h {
    z-index: 25;
    text-align: center;
    text-transform: capitalize;
    font-family: Knewave, sans-serif;
    font-size: 6rem;
    line-height: 6.5rem;
    position: relative;
  }
  
  .hero-p {
    z-index: 25;
    opacity: .9;
    text-align: center;
    margin-bottom: 0;
    font-size: 2rem;
    font-style: italic;
    line-height: 2.1rem;
    text-decoration: underline;
    position: relative;
  }
  
  .color-span {
    color: var(--main-accent);
  }
  
  .sh-hero-logo {
    width: 425px;
  }

  .pixels-gif {
    border-radius: 15px;
    width: 500px;
  }
  
  .section {
    justify-content: center;
    align-items: center;
    padding-top: 100px;
    padding-bottom: 100px;
    display: flex;
    position: relative;
    overflow: hidden;
  }
  
  .section.full-section {
    padding-top: 60px;
    padding-bottom: 10px;
  }
  
  .hero-skull {
    z-index: 0;
    opacity: .5;
    position: absolute;
    top: 10%;
    bottom: auto;
    left: 10%;
    right: auto;
  }
  
  .gallery-preview {
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-columns: 1fr;
    display: grid;
  }
  
  .gallery-img {
    border-radius: 15px;
    width: 100%;
  }
  
  .sugar-crown {
    display: none;
  }
  
  .section-grid {
    grid-column-gap: 100px;
    grid-row-gap: 100px;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    display: grid;
  }
  
  .section-h {
    color: var(--main-accent);
    text-transform: uppercase;
    margin-bottom: 10px;
    font-family: Knewave, sans-serif;
    font-size: 4rem;
    font-weight: 400;
    line-height: 4.5rem;
    text-decoration: none;
  }
  
  .section-h.center {
    text-align: center;
    font-size: 5rem;
    line-height: 5.5rem;
  }
  
  .section-p {
    color: #cecece;
    font-size: 1.3rem;
    line-height: 1.7rem;
  }
  
  .allocation-wrap {
    grid-column-gap: 15px;
    grid-row-gap: 15px;
    justify-content: flex-start;
    align-items: center;
    display: flex;
  }
  
  .allocation-h {
    color: var(--main-accent);
    font-family: Knewave, sans-serif;
    font-size: 3rem;
    line-height: 3.5rem;
  }
  
  .allocation-h.color-1 {
    color: var(--red);
  }
  
  .allocation-h.color-2 {
    color: var(--purple);
  }
  
  .allocation-p {
    text-transform: capitalize;
    font-size: 1.5rem;
    line-height: 2rem;
  }
  
  .allocation-outer {
    grid-column-gap: 40px;
    grid-row-gap: 40px;
    flex-flow: column;
    margin-top: 40px;
    margin-bottom: 40px;
    display: flex;
  }
  
  .btn-mint {
    background-color: var(--main-accent);
    color: var(--black);
    text-transform: uppercase;
    border: 5px solid #d3d302;
    border-radius: 15px;
    padding: 15px 40px;
    font-family: Knewave, sans-serif;
    font-size: 1.5rem;
    line-height: 2rem;
    text-align: center;
  }
  
  .allocation-img {
    width: 100%;
    padding: 0;
  }
  
  .text-span {
    color: var(--main-accent);
    font-family: Knewave, sans-serif;
  }
  
  .launch-phase-outer {
    grid-column-gap: 100px;
    grid-row-gap: 100px;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  
  .launch-phase-wrap {
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    flex-flow: column;
    width: 70%;
    display: flex;
  }
  
  .launch-phase-h {
    color: var(--red);
    text-align: center;
    text-transform: uppercase;
    font-family: Knewave, sans-serif;
    font-size: 3.5rem;
    line-height: 4rem;
  }
  
  .launch-phase-h.color-1 {
    color: var(--purple);
  }
  
  .launch-phase-p {
    text-align: center;
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .pixel-page-wrap {
    color: #fff;
    background-color: #131313;
    height: auto;
    font-family: Nunito Sans, sans-serif;
    font-size: 1rem;
    line-height: 1.2rem;
  }

  .pixel-phases-outer {
    grid-column-gap: 40px;
    grid-row-gap: 40px;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  
  @media screen and (max-width: 479px) {
    .hero {
      height: auto;
      padding-top: 140px;
      padding-bottom: 140px;
    }
  
    .container.full-width {
      padding-top: 0;
      padding-bottom: 0;
    }
  
    .hero-h {
      font-size: 2.5rem;
      line-height: 2.8rem;
    }
  
    .hero-p {
      font-size: 1.2rem;
      line-height: 1.5rem;
    }
  
    .sh-hero-logo {
      z-index: 2;
      width: 325px;
      position: relative;
    }
  
    .section {
      padding-top: 60px;
      padding-bottom: 60px;
    }
  
    .section.full-section {
      margin-top: 60px;
      padding-top: 60px;
      padding-bottom: 10px;
      overflow: visible;
    }
  
    .hero-skull {
      opacity: .25;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  
    .gallery-preview {
      grid-column-gap: 10px;
      grid-row-gap: 10px;
      grid-template-columns: 1fr 1fr;
    }
  
    .gallery-img {
      border-radius: 10px;
    }

    .pixels-gif {
      width: 100%;
    }
  
    .sugar-crown {
      z-index: -1;
      display: block;
      position: absolute;
      top: -18%;
      bottom: auto;
      left: 55%;
      right: auto;
      transform: translate(-50%);
    }
  
    .section-grid {
      grid-column-gap: 50px;
      grid-row-gap: 50px;
      flex-flow: column;
      display: flex;
    }
  
    .section-gw {
      align-self: auto;
    }
  
    .section-gw.flex-center {
      flex-flow: column;
      justify-content: center;
      align-items: center;
      display: flex;
    }
  
    .section-h {
      text-align: center;
      font-size: 3rem;
      line-height: 3.5rem;
    }
  
    .section-h.center {
      font-size: 3rem;
      line-height: 3.5rem;
    }
  
    .section-p {
      text-align: center;
    }
  
    .allocation-wrap {
      flex-flow: column;
      justify-content: center;
      align-items: center;
    }
  
    .allocation-p {
      text-align: center;
      font-size: 1.2rem;
      line-height: 1.7rem;
    }
  
    .btn {
      text-align: center;
      width: 100%;
      padding-left: 20px;
      padding-right: 20px;
      font-size: 1.1rem;
    }
  
    .allocation-img {
      width: 50%;
      padding: 0;
    }
  
    .launch-phase-outer {
      grid-column-gap: 60px;
      grid-row-gap: 60px;
    }
  
    .launch-phase-wrap {
      width: 100%;
    }
  
    .launch-phase-h {
      font-size: 2rem;
      line-height: 2.5rem;
    }
  
    .launch-phase-p {
      font-size: 1.1rem;
      line-height: 1.6rem;
    }
  }
  
  #w-node-_4d010225-c814-5080-6f82-a3ba49e64b72-a4af7233 {
    align-self: center;
  }