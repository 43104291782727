@import "src/pages/_variables.scss";
.ownedItemsSectionWrapper {
  width: 100%;
  margin-bottom: 320px;
  @media (min-width: 1440px) and (max-width: 1920px){
    margin-bottom: calc(
            296px + (320 - 296) * ((100vw - 1440px) / (1920 - 1440))
    );
  }
  @media (min-width: 1080px) and (max-width: 1440px){
    margin-bottom: calc(
            216px + (296 - 216) * ((100vw - 1080px) / (1440 - 1080))
    );
  }
  @media (min-width: 768px) and (max-width: 1080px){
    margin-bottom: calc(
            152px + (216 - 152) * ((100vw - 768px) / (1080 - 768))
    );
  }
  @media (max-width: 768px) {
    margin-bottom: 152px;
  }
  @media (min-width: 320px) and (max-width: 480px){
    margin-bottom: calc(
            104px + (152 - 104) * ((100vw - 320px) / (480 - 320))
    );
  }
  .ownedItemsSection {

    &__titleWrapper {
      display: flex;
      justify-content: center;
      &-title {
        position: relative;
        color: var(--font-color-white);
        font-size: 64px;
        font-family: Knewave;
        font-weight: 400;
        text-transform: uppercase;
        line-height: 74px;
        margin-bottom: 54px;

        @media (min-width: 1440px) and (max-width: 1920px){
          font-size: calc(
                  56px + (64 - 56) * ((100vw - 1440px) / (1920 - 1440))
          );
          line-height: calc(
                  67px + (74 - 67) * ((100vw - 1440px) / (1920 - 1440))
          );
          margin-bottom: calc(
                  48px + (54 - 48) * ((100vw - 1440px) / (1920 - 1440))
          );
        }
        @media (min-width: 1080px) and (max-width: 1440px){
          font-size: calc(
                  48px + (56 - 48) * ((100vw - 1080px) / (1440 - 1080))
          );
          line-height: calc(
                  68px + (67 - 68) * ((100vw - 1080px) / (1440 - 1080))
          );
          margin-bottom: calc(
                  32px + (48 - 32) * ((100vw - 1080px) / (1440 - 1080))
          );
        }
        @media (max-width: 1080px) {
          font-size: 48px;
          line-height: 68px;
          margin-bottom: 32px;
        }
        @media (min-width: 480px) and (max-width: 768px){
          font-size: calc(
                  32px + (48 - 32) * ((100vw - 480px) / (768 - 480))
          );
          line-height: calc(
                  40px + (68 - 40) * ((100vw - 480px) / (768 - 480))
          );
        }
        @media (min-width: 320px) and (max-width: 480px){
          font-size: calc(
                  24px + (32 - 24) * ((100vw - 320px) / (480 - 320))
          );
          line-height: calc(
                  32px + (40 - 32) * ((100vw - 320px) / (480 - 320))
          );
          margin-bottom: calc(
                  24px + (32 - 24) * ((100vw - 320px) / (480 - 320))
          );
        }
        &-imageWrapper {
          position: absolute;
          left: 460px;
          bottom: -110%;
          width: 360px;

          @media (min-width: 1440px) and (max-width: 1920px){
            width: calc(
                    240px + (360 - 240) * ((100vw - 1440px) / (1920 - 1440))
            );
            left: calc(
                    420px + (460 - 420) * ((100vw - 1440px) / (1920 - 1440))
            );
          }
          @media (max-width: 1500px) {
            bottom: -80%;
          }
          @media (max-width: 1440px) {
            width: 240px;
            left: 420px
          }
          @media (min-width: 1080px) and (max-width: 1440px){
            left: calc(
                    350px + (420 - 350) * ((100vw - 1080px) / (1440 - 1080))
            );
          }
          @media (max-width: 1080px) {
            left: 350px;
          }
          @media (max-width: 900px) {
            bottom: -50%;
          }
          @media (min-width: 768px) and (max-width: 1080px){
            width: calc(
                    160px + (240 - 160) * ((100vw - 768px) / (1080 - 768))
            );
            left: calc(
                    380px + (350 - 380) * ((100vw - 768px) / (1080 - 768))
            );
          }
          @media (max-width: 768px) {
            left: 380px;
          }
          @media (min-width: 480px) and (max-width: 768px){
            width: calc(
                    120px + (160 - 120) * ((100vw - 480px) / (768 - 480))
            );
            left: calc(
                    250px + (380 - 250) * ((100vw - 480px) / (768 - 480))
            );
          }
          @media (min-width: 320px) and (max-width: 480px){
            width: calc(
                    80px + (120 - 80) * ((100vw - 320px) / (480 - 320))
            );
            left: calc(
                    190px + (250 - 190) * ((100vw - 320px) / (480 - 320))
            );
          }

          img {
            width: 100%;
          }
        }
      }
    }
    &__lockBlock {
      display: flex;
      justify-content: center;
      margin-top: 94px;

      @media (min-width: 1440px) and (max-width: 1920px){
        margin-top: calc(
                125px + (94 - 124) * ((100vw - 1440px) / (1920 - 1440))
        );
      }
      @media (min-width: 1080px) and (max-width: 1440px){
        margin-top: calc(
                94px + (125 - 94) * ((100vw - 1080px) / (1440 - 1080))
        );
      }
      @media (max-width: 1080px) {
        margin-top: 94px;
      }
      @media (min-width: 320px) and (max-width: 480px){
        margin-top: calc(
                48px + (94 - 48) * ((100vw - 320px) / (480 - 320))
        );
      }

      img {
        max-width: 360px;
        @media (min-width: 1440px) and (max-width: 1920px){
          max-width: calc(
                  288px + (360 - 288) * ((100vw - 1440px) / (1920 - 1440))
          );
        }
        @media (min-width: 1080px) and (max-width: 1440px){
          max-width: calc(
                  212px + (288 - 212) * ((100vw - 1080px) / (1440 - 1080))
          );
        }
        @media (min-width: 768px) and (max-width: 1080px){
          max-width: calc(
                  312px + (212 - 312) * ((100vw - 768px) / (1080 - 768))
          );
        }
        @media (min-width: 480px) and (max-width: 768px){
          max-width: calc(
                  204px + (312 - 204) * ((100vw - 480px) / (768 - 480))
          );
        }
        @media (min-width: 320px) and (max-width: 480px){
          max-width: calc(
                  126px + (204 - 126) * ((100vw - 320px) / (480 - 320))
          );
        }
      }
    }
    &__itemsWrapper {
      &-count {
        color: var(--font-color-white);
        font-size: 24px;
        font-family: Russo One;
        font-weight: 400;
        line-height: 30px;
        margin-bottom: 32px;

        @media (min-width: 1440px) and (max-width: 1920px){
          margin-bottom: calc(
                  47px + (32 - 47) * ((100vw - 1440px) / (1920 - 1440))
          );
        }
        @media (min-width: 1080px) and (max-width: 1440px){
          margin-bottom: calc(
                  32px + (47 - 32) * ((100vw - 1080px) / (1440 - 1080))
          );
        }
        @media (max-width: 1080px) {
          margin-bottom: 32px;
        }
        @media (min-width: 480px) and (max-width: 768px){
          font-size: calc(
                  18px + (24 - 18) * ((100vw - 480px) / (768 - 480))
          );
          line-height: calc(
                  26px + (30 - 26) * ((100vw - 480px) / (768 - 480))
          );
        }
        @media (min-width: 320px) and (max-width: 480px){
          margin-bottom: calc(
                  24px + (32 - 24) * ((100vw - 320px) / (480 - 320))
          );
          font-size: calc(
                  16px + (18 - 16) * ((100vw - 320px) / (480 - 320))
          );
          line-height: calc(
                  24px + (26 - 24) * ((100vw - 320px) / (480 - 320))
          );
        }
      }
      &-items {
        display: flex;
        gap: 32px;
        flex-flow: wrap;
        justify-content: center;

        @media (max-width: 1273px) {
          gap: 29px;
        }

        @media (max-width: 1078px) {
          gap: 16px;
        }
        &-wrap {
          width: 23%;

          @media (max-width: 1179px) {
            width: 48%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          @media (max-width: 440px) {
            width: 47%;
          }


          &-item {
            background-image: url("../../../../images/analyticsPage/ownerItemBG.png");
            background-repeat: round;
            padding: 36px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;

            @media (min-width: 1440px) and (max-width: 1920px){
              padding: calc(
                      32px + (36 - 32) * ((100vw - 1440px) / (1920 - 1440))
              );
            }
            @media (min-width: 1080px) and (max-width: 1440px){
              padding: calc(
                      24px + (32 - 24) * ((100vw - 1080px) / (1440 - 1080))
              );
            }
            @media (max-width: 1189px) {
              padding: 32px;
            }
            @media (min-width: 480px) and (max-width: 768px){
              padding: calc(
                      24px + (32 - 24) * ((100vw - 480px) / (768 - 480))
              );
            }
            @media (min-width: 320px) and (max-width: 480px){
              padding: calc(
                      16px + (24 - 16) * ((100vw - 320px) / (480 - 320))
              );
            }

            img {
              width: 324px;
              height: 368px;

              @media (min-width: 1440px) and (max-width: 1920px){
                width: calc(
                        252px + (324 - 252) * ((100vw - 1440px) / (1920 - 1440))
                );
                height: calc(
                        288px + (368 - 288) * ((100vw - 1440px) / (1920 - 1440))
                );
              }
              @media (min-width: 1080px) and (max-width: 1440px){
                width: calc(
                        178px + (252 - 178) * ((100vw - 1080px) / (1440 - 1080))
                );
                height: calc(
                        212px + (288 - 212) * ((100vw - 1080px) / (1440 - 1080))
                );
              }
              @media (max-width: 1078px) {
                width: 272px;
                height: 320px;
              }
              @media (min-width: 480px) and (max-width: 768px){
                width: calc(
                        164px + (272 - 164) * ((100vw - 480px) / (768 - 480))
                );
                height: calc(
                        200px + (320 - 200) * ((100vw - 480px) / (768 - 480))
                );
              }
              @media (min-width: 320px) and (max-width: 480px){
                width: calc(
                        98px + (164 - 98) * ((100vw - 320px) / (480 - 320))
                );
                height: calc(
                        120px + (200 - 120) * ((100vw - 320px) / (480 - 320))
                );
              }
            }
          }
        }
      }
    }
  }
}
.blur {
  @include blur;
}
.hidden {
  display: none !important;
}
