.firstSectionWrapper {
  position: relative;
  background: linear-gradient(180deg, black 0%, rgba(0, 0, 0, 0) 100%);
  .image {
    width: 100%;

    @media (min-width: 480px) and (max-width: 1080px) {
      height: calc(
              920px + (1200 - 920) * ((100vw - 480px) / (1080 - 480))
      );
      object-fit: cover;
    }
    @media (min-width: 320px) and (max-width: 480px) {
      height: calc(
              675px + (920 - 675) * ((100vw - 320px) / (480 - 320))
      );
      object-fit: cover;
    }
  }
  .gradient {
    position: absolute;
    top: -1px;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, black 0%, rgba(0, 0, 0, 0) 40%);
  }
  .gradientMax {
    position: absolute;
    top: -1px;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, black 10%, rgba(0, 0, 0, 0.5) 90%);
    opacity: 0;
    transition: opacity 0.5s ease;
  }
  .gradientActive {
    opacity: 1;
  }
  .bottomGradient {
    position: absolute;
    left: 0;
    bottom: -12%;
    width: 100vw;

    @media (min-width: 480px) and (max-width: 1080px) {
      bottom: -4%;
    }
    @media (min-width: 320px) and (max-width: 480px) {
      bottom: -2%;
    }
  }
  .first {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    .firstSection {
      margin-top: 48px;
      &__title {
        font-size: 82px;
        font-family: Knewave;
        font-weight: 400;
        text-transform: uppercase;
        line-height: 96px;
        max-width: 1220px;
        color: var(--font-color-white);
        margin-bottom: 32px;

        @media (min-width: 1440px) and (max-width: 1920px){
          margin-bottom: calc(
                  48px + (32 - 48) * ((100vw - 1440px) / (1920 - 1440))
          );
        }

        @media (min-width: 1080px) and (max-width: 1440px){
          font-size: calc(
                  64px + (82 - 64) * ((100vw - 1080px) / (1440 - 1080))
          );
          line-height: calc(
                  74px + (96 - 74) * ((100vw - 1080px) / (1440 - 1080))
          );
          max-width: calc(
                  952px + (1220 - 952) * ((100vw - 1080px) / (1440 - 1080))
          );
          margin-bottom: calc(
                  32px + (48 - 32) * ((100vw - 1080px) / (1440 - 1080))
          );
        }
        @media (max-width: 1080px) {
          margin-bottom: 32px;
          font-size: 64px;
        }
        @media (min-width: 768px) and (max-width: 1080px){
          max-width: calc(
                  688px + (952 - 688) * ((100vw - 768px) / (1080 - 768))
          );
        }
        @media (min-width: 440px) and (max-width: 768px){
          font-size: calc(
                  48px + (64 - 48) * ((100vw - 440px) / (768 - 440))
          );
          line-height: calc(
                  68px + (74 - 68) * ((100vw - 440px) / (768 - 440))
          );
          max-width: calc(
                  440px + (688 - 440) * ((100vw - 440px) / (768 - 440))
          );
          margin-bottom: calc(
                  24px + (32 - 24) * ((100vw - 440px) / (768 - 440))
          );
        }
        @media (min-width: 320px) and (max-width: 480px){
          font-size: calc(
                  32px + (48 - 32) * ((100vw - 320px) / (480 - 320))
          );
          line-height: calc(
                  40px + (68 - 40) * ((100vw - 320px) / (480 - 320))
          );
          margin-bottom: calc(
                  16px + (24 - 16) * ((100vw - 320px) / (480 - 320))
          );
        }
      }
      &__description {
        font-size: 24px;
        font-family: Russo One;
        font-weight: 400;
        max-width: 600px;
        line-height: 30px;
        margin-bottom: 48px;
        color: var(--font-color-white90);
        text-shadow:
                -1px -1px 0 #000,
                1px -1px 0 #000,
                -1px 1px 0 #000,
                1px 1px 0 #000;

        @media (min-width: 1440px) and (max-width: 1920px){
          font-size: calc(
                  16px + (24 - 16) * ((100vw - 1440px) / (1920 - 1440))
          );
          line-height: calc(
                  24px + (30 - 24) * ((100vw - 1440px) / (1920 - 1440))
          );
          max-width: calc(
                  415px + (600 - 415) * ((100vw - 1440px) / (1920 - 1440))
          );
        }
        @media (max-width: 1440px) {
          font-size: 16px;
          max-width: 415px;
          line-height: 24px;
        }
        @media (min-width: 1080px) and (max-width: 1440px){
          margin-bottom: calc(
                  44px + (48 - 44) * ((100vw - 1080px) / (1440 - 1080))
          );
        }
        @media (min-width: 768px) and (max-width: 1080px){
          margin-bottom: calc(
                  48px + (44 - 48) * ((100vw - 768px) / (1080 - 768))
          );
        }
        @media (min-width: 480px) and (max-width: 768px){
          margin-bottom: calc(
                  40px + (48 - 40) * ((100vw - 480px) / (768 - 480))
          );
        }
        @media (min-width: 320px) and (max-width: 480px){
          font-size: calc(
                  14px + (16 - 14) * ((100vw - 320px) / (480 - 320))
          );
          line-height: calc(
                  21px + (24 - 21) * ((100vw - 320px) / (480 - 320))
          );
          margin-bottom: calc(
                  24px + (40 - 24) * ((100vw - 320px) / (480 - 320))
          );
        }
      }
    }
  }
}
