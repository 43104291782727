@import "src/pages/_variables.scss";
.voltSectionWrapper {
  width: 100%;
  background-image: url("../../../../images/analyticsPage/voltBG.webp");
  background-repeat: round;
  background-size: contain;
  position: relative;
  padding-bottom: 200px;
  margin-bottom: 240px;

  @media (min-width: 1440px) and (max-width: 1920px){
    padding-bottom: calc(
            160px + (200 - 160) * ((100vw - 1440px) / (1920 - 1440))
    )
  }
  @media (min-width: 1081px) and (max-width: 1440px){
    padding-bottom: calc(
            144px + (160 - 144) * ((100vw - 1081px) / (1440 - 1081))
    );
    margin-bottom: calc(
            160px + (240 - 160) * ((100vw - 1081px) / (1440 - 1081))
    )
  }
  @media (max-width: 1080px) {
    background-image: url("../../../../images/analyticsPage/voltBG3.webp");
    padding-bottom: 100px;
    margin-bottom: 160px;
  }
  @media (min-width: 768px) and (max-width: 1080px){
    margin-bottom: calc(
            112px + (160 - 112) * ((100vw - 768px) / (1080 - 768))
    );
  }
  @media (max-width: 768px) {
    margin-bottom: 112px;
    background-image: url("../../../../images/analyticsPage/voltBG768.webp");
  }
  @media (min-width: 480px) and (max-width: 768px){
    padding-bottom: calc(
            64px + (104 - 64) * ((100vw - 480px) / (768 - 480))
    );
  }
  @media (max-width: 553px) {
    background-image: url("../../../../images/analyticsPage/voltBG480.webp");
  }
  @media (min-width: 320px) and (max-width: 480px){
    padding-bottom: calc(
            46px + (64 - 46) * ((100vw - 320px) / (480 - 320))
    );
    margin-bottom: calc(
            80px + (112 - 80) * ((100vw - 320px) / (480 - 320))
    );
  }

  &__gradient {
    position: absolute;
    top: -1px;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, black 0%, rgba(0, 0, 0, 0) 9.5%);
  }
  &__gradientMax {
    position: absolute;
    top: -1px;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, black 10%, rgba(0, 0, 0, 0.5) 90%);
    opacity: 0;
    transition: opacity 0.5s ease;
    z-index: 1;
    pointer-events: none;
  }
  .gradientActive {
    opacity: 1;
  }

  .voltSection {
    padding-top: 32px;
    position: sticky;
    &__firstBlock {
      margin-bottom: 332px;
      @media (min-width: 1440px) and (max-width: 1920px){
        margin-bottom: calc(
                272px + (332 - 272) * ((100vw - 1440px) / (1920 - 1440))
        )
      }
      @media (min-width: 1081px) and (max-width: 1440px){
        margin-bottom: calc(
                169px + (272 - 169) * ((100vw - 1081px) / (1440 - 1081))
        )
      }
      @media (max-width: 1080px) {
        margin-bottom: 150px;
      }
      @media (max-width: 768px) {
        margin-bottom: 0;
      }
      @media (min-width: 320px) and (max-width: 480px){
        margin-bottom: calc(
                13px + (0 - 13) * ((100vw - 320px) / (480 - 320))
        )
      }
      &-controls {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 16px;

        @media (max-width: 425px) {
          flex-direction: column;
          align-items: flex-start;
        }

        &-indicator {
          font-size: 22px;
          font-family: Knewave;
          font-weight: 400;
          text-transform: uppercase;
          line-height: 24px;
          padding: 36px 61px 61px 61px;
          cursor: pointer;
          @include violetBtn;
          transition: filter 1s ease;
          color: var(--font-color-white);
          background-image: url("../../../../images/mainBtnBG.png");
          background-size: cover;
          background-repeat: round;
          max-width: fit-content;
          margin-bottom: 20px;

          @media (min-width: 1440px) and (max-width: 1920px){
            padding: calc(
                    24px + (36 - 24) * ((100vw - 1440px) / (1920 - 1440))
            )
            calc(
                    42px + (60 - 42) * ((100vw - 1440px) / (1920 - 1440))
            )
            calc(
                    44px + (62 - 44) * ((100vw - 1440px) / (1920 - 1440))
            )
            calc(
                    50px + (60 - 50) * ((100vw - 1440px) / (1920 - 1440))
            );
            font-size: calc(
                    16px + (22 - 16) * ((100vw - 1440px) / (1920 - 1440))
            )
          }
          @media (max-width: 1440px) {
            padding: 24px 42px 44px 50px;
            font-size: 16px;
          }
          @media (max-width: 1079px) {
            padding: 12px 23px 28px 23px;
          }
          @media (max-width: 480px) {
            margin-bottom: 20px;
          }
        }
      }
      &-info {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 40px;
        margin-top: 24px;

        @media (min-width: 1440px) and (max-width: 1920px){
          gap: calc(
                  16px + (40 - 16) * ((100vw - 1440px) / (1920 - 1440))
          )
        }
        @media (max-width: 1440px) {
          gap: 16px;
        }
        @media (max-width: 768px) {
          flex-direction: column;
          margin-top: 16px;
        }
        @media (min-width: 480px) and (max-width: 768px){
          gap: calc(
                  8px + (16 - 8) * ((100vw - 480px) / (768 - 480))
          )
        }
        @media (max-width: 480px) {
          gap: 8px;
        }

        &-item {
          padding: 20px 20px 48px 20px;
          background-repeat: round;
          @media (min-width: 1440px) and (max-width: 1920px){
            padding: calc(
                    16px + (20 - 16) * ((100vw - 1440px) / (1920 - 1440))
            )
            calc(
                    16px + (20 - 16) * ((100vw - 1440px) / (1920 - 1440))
            )
            calc(
                    32px + (48 - 32) * ((100vw - 1440px) / (1920 - 1440))
            )
            calc(
                    16px + (20 - 16) * ((100vw - 1440px) / (1920 - 1440))
            );
            gap: calc(
                    16px + (24 - 16) * ((100vw - 1440px) / (1920 - 1440))
            )
          }
          @media (max-width: 1440px) {
            padding: 16px 16px 32px 16px;
          }
          @media (min-width: 768px) and (max-width: 1080px){
            padding: calc(
                    8px + (16 - 8) * ((100vw - 768px) / (1080 - 768))
            )
            calc(
                    8px + (16 - 8) * ((100vw - 768px) / (1080 - 768))
            )
            calc(
                    23px + (32 - 23) * ((100vw - 768px) / (1080 - 768))
            )
            calc(
                    8px + (16 - 8) * ((100vw - 768px) / (1080 - 768))
            );
          }
          @media (max-width: 768px) {
            padding: 16px 16px 32px 16px;
          }
          @media (min-width: 320px) and (max-width: 480px){
            padding: calc(
                    8px + (16 - 8) * ((100vw - 320px) / (480 - 320))
            )
            calc(
                    8px + (16 - 8) * ((100vw - 320px) / (480 - 320))
            )
            calc(
                    23px + (32 - 23) * ((100vw - 320px) / (480 - 320))
            )
            calc(
                    8px + (16 - 8) * ((100vw - 320px) / (480 - 320))
            )
          }
          &-wrap {
            width: 360px;
            height: 72px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: var(--font-color-violet);
            border-radius: 16px;
            color: var(--font-color-white);
            font-size: 18px;
            font-family: Russo One;
            font-weight: 400;
            line-height: 26px;

            @media (min-width: 1440px) and (max-width: 1920px){
              width: calc(
                      288px + (360 - 288) * ((100vw - 1440px) / (1920 - 1440))
              );
              height: calc(
                      56px + (72 - 56) * ((100vw - 1440px) / (1920 - 1440))
              )
            }
            @media (max-width: 1440px) {
              width: 288px;
              height: 56px;
            }
            @media (min-width: 1080px) and (max-width: 1440px){
              font-size: calc(
                      16px + (18 - 16) * ((100vw - 1080px) / (1440 - 1080))
              );
              line-height: calc(
                      24px + (26 - 24) * ((100vw - 1080px) / (1440 - 1080))
              )
            }
            @media (min-width: 768px) and (max-width: 1080px){
              width: calc(
                      200px + (288 - 200) * ((100vw - 768px) / (1080 - 768))
              );
              height: calc(
                      41px + (56 - 41) * ((100vw - 768px) / (1080 - 768))
              );
              font-size: calc(
                      14px + (16 - 14) * ((100vw - 768px) / (1080 - 768))
              );
              line-height: calc(
                      21px + (24 - 21) * ((100vw - 768px) / (1080 - 768))
              )
            }
            @media (max-width: 768px) {
              font-size: 18px;
              line-height: 26px;
              width: 288px;
              height: 56px;
            }
            @media (min-width: 320px) and (max-width: 480px){
              width: calc(
                      200px + (288 - 200) * ((100vw - 320px) / (480 - 320))
              );
              height: calc(
                      41px + (56 - 41) * ((100vw - 320px) / (480 - 320))
              );
              font-size: calc(
                      16px + (18 - 16) * ((100vw - 320px) / (480 - 320))
              );
              line-height: calc(
                      24px + (26 - 24) * ((100vw - 320px) / (480 - 320))
              )
            }

            span {
              font-size: 24px;
              font-family: Russo One;
              font-weight: 400;
              line-height: 30px;

              @media (min-width: 768px) and (max-width: 1080px){
                font-size: calc(
                        18px + (24 - 18) * ((100vw - 768px) / (1080 - 768))
                );
                line-height: calc(
                        26px + (30 - 26) * ((100vw - 768px) / (1080 - 768))
                )
              }
              @media (min-width: 480px) and (max-width: 768px){
                font-size: calc(
                        24px + (18 - 24) * ((100vw - 480px) / (768 - 480))
                );
                line-height: calc(
                        30px + (26 - 30) * ((100vw - 480px) / (768 - 480))
                )
              }
              @media (min-width: 320px) and (max-width: 480px){
                font-size: calc(
                        18px + (24 - 18) * ((100vw - 320px) / (480 - 320))
                );
                line-height: calc(
                        26px + (30 - 26) * ((100vw - 320px) / (480 - 320))
                )
              }
            }
          }
        }
        &-item:nth-child(2) {
          background-image: url("../../../../images/analyticsPage/infoBG1.png");
        }
        &-item:nth-child(1) {
          background-image: url("../../../../images/analyticsPage/infoBG2.png");
        }
        &-item:nth-child(3) {
          background-image: url("../../../../images/analyticsPage/infoBG3.png");
        }
      }
    }
    &__dopBG {
      width: 100%;
      margin: 0 auto;

      @media (min-width: 480px) and (max-width: 768px){
        max-width: calc(444px + (768 - 444) * ((100vw - 480px) / (767 - 480)));
        height: calc(80px + (127 - 80) * ((100vw - 480px) / (767 - 480)));;
      }
      @media (min-width: 320px) and (max-width: 480px){
        margin-bottom: calc(
                13px + (0 - 13) * ((100vw - 320px) / (480 - 320))
        );
        height: calc(
                64px + (77 - 64) * ((100vw - 320px) / (480 - 320))
        );
      }
      @media (min-width: 769px) {
        display: none;
      }


      img {
        width: 100%;
        height: 100%;
      }
    }
    &__lastBlock {
      display: flex;
      align-items: center;
      justify-content: space-evenly;

      @media (max-width: 1440px) {
        justify-content: space-between;
      }
      @media (max-width: 1080px) {
        flex-direction: column-reverse;
      }

      &-listWrap {

        &-list {
          display: flex;

          @media (max-width: 768px) {
            flex-direction: column;
          }


          .slick-slider {
            width: 740px;
            height: 560px;
            margin-right: 32px;

            @media (min-width: 1440px) and (max-width: 1920px){
              width: calc(
                      592px + (742 - 592) * ((100vw - 1440px) / (1920 - 1440))
              );
              height: calc(
                      464px + (560 - 464) * ((100vw - 1440px) / (1920 - 1440))
              );
            }
            @media (min-width: 1080px) and (max-width: 1440px){
              width: calc(
                      553px + (592 - 552) * ((100vw - 1080px) / (1440 - 1080))
              );
              height: calc(
                      426px + (464 - 426) * ((100vw - 1080px) / (1440 - 1080))
              ) !important;
            }
            @media (max-width: 1079px) {
              width: 552px;
              height: 426px;
            }
            @media (max-width: 768px) {
              margin-right: 0;
            }
            @media (min-width: 480px) and (max-width: 768px){
              width: calc(
                      442px + (552 - 440) * ((100vw - 480px) / (768 - 480))
              );
            }
            @media (max-width: 479px) {
              width: 281px;
              height: 599px !important;
            }

            button {
              display: none !important;
            }

            .slick-list {
              height: 566px !important;

              @media (min-width: 1440px) and (max-width: 1920px){
                height: calc(
                        470px + (566 - 464) * ((100vw - 1440px) / (1920 - 1440))
                ) !important;
              }
              @media (min-width: 1080px) and (max-width: 1440px){
                height: calc(
                        426px + (470 - 426) * ((100vw - 1080px) / (1440 - 1080))
                ) !important;
              }
              @media (max-width: 1080px) {
                height: 426px !important;
              }
              @media (max-width: 479px) {
                height: 599px !important;
              }

            }

            .slick-slide {
              padding: 8px  0px;
              // margin-bottom: 10px;

              // padding-bottom:15px;
              box-sizing: content-box;
              height: 76.5px !important;

              @media (min-width: 1440px) and (max-width: 1920px){
                height: calc(
                        60.5px + (76.5 - 60.5) * ((100vw - 1440px) / (1920 - 1440))
                ) !important;
              }
              @media (min-width: 1080px) and (max-width: 1440px){
                padding: calc(
                        4px + (8 - 4) * ((100vw - 1080px) / (1440 - 1080))
                ) 0;
                height: 60.5px !important;
              }
              @media (max-width: 1080px) {
                padding: 4px 0;
                height: 60.5px !important;
              }
              @media (max-width: 479px) {
                height: 90.5px !important;
              }
            }
          }

          &-controls {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 40px;
            user-select: none;

            @media (max-width: 768px) {
              flex-direction: row-reverse;
              gap: 24px;
              height: 64px;
              align-items: flex-end;
              margin-top: 16px;
            }
            @media (min-width: 320px) and (max-width: 480px){
              margin-top: calc(
                      12px + (16 - 12) * ((100vw - 320px) / (480 - 320))
              );
            }

            &-btn {
              cursor: pointer;
              @include yellowBtn;
              transition: filter 1s ease, opacity 0.3s ease;
              @media (max-width: 768px) {
                margin-top: 32px;
              }
            }
            .disabled-btn {
              opacity: 0.5;
              pointer-events: none;
            }
            .last {
              @media (max-width: 768px) {
                transform: rotate(180deg);
              }
            }
            @media (hover: hover) {
              &-btn:hover {
                @include yellowBtnAnim;
              }
            }

            .touch-device &-btn:hover {
              @include yellowBtn;
            }
            &-btn.animate {
              filter: drop-shadow(0 0 2px #FEFF00) drop-shadow(0 0 4px #FEFF00) drop-shadow(0 0 8px #FEFF00) !important;
            }
          }

          &-item {
            padding: 20px 32px 20px 32px;
            background: #00FFD1;
            border-radius: 48px;
            border: 2px black solid;
            display: flex !important;
            align-items: center;
            max-width: 740px;
            box-sizing: border-box;

            @media (min-width: 1440px) and (max-width: 1920px){
              max-width: calc(
                      592px + (740 - 592) * ((100vw - 1440px) / (1920 - 1440))
              );
              padding: calc(
                      16px + (20 - 16) * ((100vw - 1440px) / (1920 - 1440))
              )
              calc(
                      30px + (32 - 30) * ((100vw - 1440px) / (1920 - 1440))
              )
              calc(
                      16px + (20 - 16) * ((100vw - 1440px) / (1920 - 1440))
              )
              calc(
                      32px + (32 - 32) * ((100vw - 1440px) / (1920 - 1440))
              )
            }
            @media (min-width: 1080px) and (max-width: 1440px){
              max-width: calc(
                      552px + (592 - 552) * ((100vw - 1080px) / (1440 - 1080))
              );
              padding: calc(
                      16px + (16 - 16) * ((100vw - 1080px) / (1440 - 1080))
              )
              calc(
                      16px + (30 - 16) * ((100vw - 1080px) / (1440 - 1080))
              )
              calc(
                      16px + (16 - 16) * ((100vw - 1080px) / (1440 - 1080))
              )
              calc(
                      16px + (32 - 16) * ((100vw - 1080px) / (1440 - 1080))
              );
              height: 64px;
            }
            @media (max-width: 1079px) {
              max-width: 552px;
              padding: 16px;
              height: 64px;
            }
            @media (min-width: 480px) and (max-width: 768px){
              max-width: calc(
                      440px + (552 - 440) * ((100vw - 480px) / (768 - 480))
              );
              padding: calc(
                      19px + (16 - 19) * ((100vw - 480px) / (768 - 480))
              )
              calc(
                      16px + (16 - 16) * ((100vw - 480px) / (768 - 480))
              )
              calc(
                      19px + (16 - 19) * ((100vw - 480px) / (768 - 480))
              )
              calc(
                      16px + (16 - 16) * ((100vw - 480px) / (768 - 480))
              );
            }
            @media (max-width: 479px) {
              height: 92px;
              padding: 16px 16px 18px 16px;
              max-width: 280px;
              border-radius: 16px;
            }

            &-title {
              color: black;
              font-size: 32px;
              font-family: Knewave;
              font-weight: 400;
              text-transform: uppercase;
              line-height: 40px;
              max-width: 116px;
              width: 100%;

              @media (min-width: 1440px) and (max-width: 1920px){
                font-size: calc(
                        24px + (32 - 24) * ((100vw - 1440px) / (1920 - 1440))
                );
                line-height: calc(
                        32px + (40 - 32) * ((100vw - 1440px) / (1920 - 1440))
                );
                max-width: calc(
                        80px + (116 - 80) * ((100vw - 1440px) / (1920 - 1440))
                );
              }
              @media (max-width: 1440px){
                font-size: 24px;
                line-height: 32px;
                max-width: 80px;
              }
              @media (min-width: 480px) and (max-width: 768px){
                font-size: calc(
                        18px + (24 - 18) * ((100vw - 480px) / (768 - 480))
                );
                line-height: calc(
                        26px + (32 - 26) * ((100vw - 480px) / (768 - 480))
                );
                max-width: calc(
                        56px + (80 - 56) * ((100vw - 480px) / (768 - 480))
                );
              }
              @media (max-width: 480px) {
                font-size: 18px;
                line-height: 26px;
                max-width: 56px;
              }
              @media (max-width: 479px) {
                margin-right: 16px;
              }

            }
            &-wrap {
              display: flex;
              width: 100%;

              @media (max-width: 479px) {
                flex-direction: column;
                gap: 8px;
              }

              &-value {
                color: black;
                font-size: 18px;
                font-family: Russo One;
                font-weight: 400;
                line-height: 26px;
                max-width: 280px;
                width: 100%;

                @media (min-width: 1440px) and (max-width: 1920px){
                  max-width: calc(
                          224px + (280 - 224) * ((100vw - 1440px) / (1920 - 1440))
                  );
                }
                @media (min-width: 1080px) and (max-width: 1440px){
                  max-width: calc(
                          220px + (224 - 220) * ((100vw - 1080px) / (1440 - 1080))
                  );
                  font-size: calc(
                          16px + (18 - 16) * ((100vw - 1080px) / (1440 - 1080))
                  );
                  line-height: calc(
                          24px + (26 - 24) * ((100vw - 1080px) / (1440 - 1080))
                  );
                }
                @media (max-width: 1080px) {
                  font-size: 16px;
                  line-height: 24px;
                  max-width: 220px
                }
                @media (min-width: 480px) and (max-width: 768px){
                  max-width: calc(
                          176px + (220 - 176) * ((100vw - 480px) / (768 - 480))
                  );
                  font-size: calc(
                          14px + (16 - 14) * ((100vw - 480px) / (768 - 480))
                  );
                  line-height: calc(
                          21px + (24 - 21) * ((100vw - 480px) / (768 - 480))
                  );
                }
                @media (max-width: 480px) {
                  font-size: 14px;
                  line-height: 21px;
                  max-width: 176px
                }

                span {
                  color: black;
                  font-size: 24px;
                  font-family: Russo One;
                  font-weight: 400;
                  line-height: 30px;

                  @media (min-width: 480px) and (max-width: 768px){
                    font-size: calc(
                            18px + (24 - 18) * ((100vw - 480px) / (768 - 480))
                    );
                    line-height: calc(
                            26px + (30 - 26) * ((100vw - 480px) / (768 - 480))
                    );
                  }
                  @media (max-width: 480px) {
                    font-size: 18px;
                    line-height: 26px;
                  }
                }
              }
              .sec {

              }
            }

          }
        }

      }
      &-pieChartWrap {
        width: 564px;
        height: 564px;
        //pointer-events: none;
        position: relative;

        @media (min-width: 1440px) and (max-width: 1920px){
          width: calc(
                  468px + (560 - 468) * ((100vw - 1440px) / (1920 - 1440))
          );
          height: calc(
                  468px + (560 - 468) * ((100vw - 1440px) / (1920 - 1440))
          );
        }
        @media (min-width: 1081px) and (max-width: 1440px){
          width: calc(
                  324px + (468 - 324) * ((100vw - 1081px) / (1440 - 1081))
          );
          height: calc(
                  324px + (468 - 324) * ((100vw - 1081px) / (1440 - 1081))
          );
        }
        @media (max-width: 1080px) {
          width: 468px;
          height: 468px;
          margin-bottom: 32px;
        }
        @media (min-width: 480px) and (max-width: 768px){
          width: calc(
                  324px + (468 - 324) * ((100vw - 480px) / (768 - 480))
          );
          height: calc(
                  324px + (468 - 324) * ((100vw - 480px) / (768 - 480))
          );
        }
        @media (min-width: 320px) and (max-width: 480px){
          width: calc(
                  284px + (324 - 284) * ((100vw - 320px) / (480 - 320))
          );
          height: calc(
                  284px + (324 - 284) * ((100vw - 320px) / (480 - 320))
          );
          margin-bottom: calc(
                  16px + (32 - 16) * ((100vw - 320px) / (480 - 320))
          );
        }

        .recharts-sector {
          outline: none;
        }
        .gText {
          font-size: 22px;
          font-family: Knewave;
          font-weight: 400;
          text-transform: uppercase;
          line-height: 40px;

          @media (min-width: 1440px) and (max-width: 1920px){
            font-size: calc(18px + (22 - 18) * ((100vw - 1440px) / (1920 - 1440)));
            line-height: calc(36px + (40 - 36) * ((100vw - 1440px) / (1920 - 1440)));
          }
          @media (min-width: 1080px) and (max-width: 1440px){
            font-size: calc(12px + (18 - 12) * ((100vw - 1080px) / (1440 - 1080)));
            line-height: calc(24px + (36 - 24) * ((100vw - 1080px) / (1440 - 1080)));
          }
          @media (max-width: 1080px) {
            font-size: 14px;
            line-height: 24px;
          }
          @media (min-width: 320px) and (max-width: 769px){
            font-size: calc(10px + (14 - 10) * ((100vw - 320px) / (768 - 320)));
            line-height: calc(12px + (24 - 12) * ((100vw - 320px) / (768 - 320)));
          }
        }
        .gText2 {
          font-size: 18px;
          font-family: Knewave;
          font-weight: 400;
          text-transform: uppercase;
          line-height: 26px;

          @media (min-width: 1440px) and (max-width: 1920px){
            font-size: calc(17px + (18 - 17) * ((100vw - 1440px) / (1920 - 1440)));
            line-height: calc(22px + (26 - 22) * ((100vw - 1440px) / (1920 - 1440)));
          }
          @media (min-width: 1080px) and (max-width: 1440px){
            font-size: calc(14px + (17 - 14) * ((100vw - 1080px) / (1440 - 1080)));
            line-height: calc(20px + (26 - 20) * ((100vw - 1080px) / (1440 - 1080)));
          }
          @media (max-width: 1080px) {
            font-size: 14px;
            line-height: 20px;
          }
          @media (min-width: 320px) and (max-width: 769px){
            font-size: calc(10px + (14 - 10) * ((100vw - 320px) / (768 - 320)));
            line-height: calc(12px + (24 - 12) * ((100vw - 320px) / (768 - 320)));
          }
        }

        .pieChartModal {
          position: absolute;
          background: #5C36D5;
          padding: 8px 16px 8px 16px;
          border-radius: 16px;
          font-size: 18px;
          font-weight: 400;
          font-family: "Russo One";
          width: fit-content;
          display: none;
          z-index: 10;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          &__first {
            color: #87FCB6;
            font-size: 18px;
            display: flex;
            align-items: center;
            margin-bottom: 10px;

            @media (max-width: 1440px){
              margin-bottom: 8px;
              font-size: 16px;
            }
            @media (max-width: 768px){
              margin-bottom: 2px;
              font-size: 14px;
            }
            @media (max-width: 480px){
              font-size: 12px;
            }
          }
          &__second {
            color: #EE9DD5;
            font-size: 18px;
            display: flex;
            align-items: center;

            @media (max-width: 1440px){
              font-size: 16px;
            }
            @media (max-width: 768px){
              font-size: 14px;
            }
            @media (max-width: 480px){
              font-size: 12px;
            }
          }
        }
        .pieChartModalActive {
          display: block !important;
        }
      }
    }
  }
}
