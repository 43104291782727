.timeButtons {
  display: flex;
  align-items: start;

  @media (min-width: 1080px) and (max-width: 1440px) {
  margin-bottom: calc(24px + (10 - 24) * ((100vw - 1080px) / (1440 - 1080)))
}
  @media (max-width: 1079px) {
    gap: 8px;
  }

  .btn {
    border-radius: 16px;
    border: 2px #8060ED solid;
    padding: 10px;
    font-size: 18px;
    font-family: Russo One;
    font-weight: 400;
    line-height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--font-color-white50);
    cursor: pointer;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      inset: -5px;
      bottom: -20px;
      background-image: url("../../images/timeBtnRes.png");
      background-size: cover;
      background-repeat: round;
      opacity: 0;
      transition: opacity 0.4s;
    }
    @media (max-width: 1079px) {
    min-width: 48px;
    height: 48px;
    padding: 10px 8px;
    box-sizing: border-box;
    border-radius: 8px;
    font-size: 14px;
    line-height: 21px;
  }
    @media (min-width: 320px) and (max-width: 480px) {
      font-size: calc(10px + (14 - 10) * ((100vw - 320px) / (480 - 320)));
      line-height: calc(14px + (21 - 14) * ((100vw - 320px) / (480 - 320)));
      min-width: calc(40px + (48 - 40) * ((100vw - 320px) / (480 - 320)));
      height: calc(40px + (48 - 40) * ((100vw - 320px) / (480 - 320)));
      padding: calc(4px + (10 - 4) * ((100vw - 320px) / (480 - 320))) calc(4px + (8 - 4) * ((100vw - 320px) / (480 - 320)));
    }
  }
  .active {
    position: relative;
    color: var(--font-color-white);
    background-size: cover;
    background-repeat: round;
    &:before {
      opacity: 1;
    }
  }
  .activeSec {
    position: relative;
    color: var(--font-color-white);
    background-size: cover;
    background-repeat: round;

    &:before {
      opacity: 1;
    }
  }
  .btn {
    &:nth-child(1) { margin-right: 13px; }
    &:nth-child(2) { margin-right: 17px; }
    &:nth-child(3) { margin-right: 11px; }
    &:nth-child(4) { margin-right: 17px; }

    @media (max-width: 1079px) {
      margin-right: 0 !important;
    }
  }
}
.main {
  margin-bottom: 30px;
}
