@import "src/pages/_variables.scss";
.headerWrapper {
  width: 100%;
  background: #000000;
  position: relative;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: Knewave;
    padding: 24px 0;

    @media (min-width: 768px) and (max-width: 1080px){
      padding: calc(16px + (24 - 16) * ((100vw - 768px) / (1080 - 768))) 0;
    }
    @media (max-width: 768px) {
      padding-bottom: 0 !important;
    }
    @media (min-width: 480px) and (max-width: 768px){
      padding: calc(8px + (16 - 8) * ((100vw - 480px) / (768 - 480))) 0;
    }
    @media (max-width: 480px) {
      padding: 8px 0;
    }

    &__firstSection {
      display: flex;
      align-items: center;

      &-logo {
        margin-right: 70px;
        cursor: pointer;

        @media (min-width: 768px) and (max-width: 1920px){
          margin-right: calc(
                  24px + (70 - 24) * ((100vw - 768px) / (1920 - 768))
          );
        }
        a {
          display: block
        }
        img {
          max-width: 112px;
          max-height: 96px;
          width: 100%;
          height: 100%;

          @media (min-width: 1440px) and (max-width: 1920px){
            max-width: calc(
                    92px + (112 - 92) * ((100vw - 1440px) / (1920 - 1440))
            );
            max-height: calc(
                    80px + (92 - 80) * ((100vw - 1440px) / (1920 - 1440))
            );
          }
          @media (max-width: 1440px) {
            max-width: 92px;
            max-height: 80px;
          }
          @media (min-width: 768px) and (max-width: 1080px){
            max-width: calc(
                    80px + (92 - 80) * ((100vw - 768px) / (1080 - 768))
            );
            max-height: calc(
                    64px + (80 - 64) * ((100vw - 768px) / (1080 - 768))
            );
          }
          @media (max-width: 768px) {
            max-height: 64px;
          }
          @media (min-width: 480px) and (max-width: 768px){
            max-width: calc(
                    74px + (80 - 74) * ((100vw - 480px) / (768 - 480))
            );
          }
          @media (min-width: 320px) and (max-width: 480px){
            max-width: calc(
                    67px + (74 - 67) * ((100vw - 320px) / (480 - 320))
            );
            max-height: calc(
                    56px + (64 - 56) * ((100vw - 320px) / (480 - 320))
            );
          }

          @media (max-width: 1079px) {
            max-width: 72px;
          }
        }
      }
      &-items {
        display: flex;
        align-items: center;
        gap: 24px;

        @media (max-width: 768px) {
          display: none;
        }

        &-item {
          color: rgba(255, 255, 255, 0.3);
          font-size: 18px;
          font-weight: 400;
          text-transform: uppercase;
          cursor: pointer;
          @include whiteBtn;
          transition: filter 1s ease;

          @media (min-width: 1440px) and (max-width: 1920px){
            font-size: calc(
                    16px + (18 - 16) * ((100vw - 1440px) / (1920 - 1440))
            );
          }
          @media (max-width: 1440px){
            font-size: 16px
          }

          a {
            text-decoration: none;
            color: inherit;
          }
        }
        .active {
          color: var(--font-color-white);
        }
        &-item:not(:last-child) {
          @media (max-width: 1180px){
            display: none;
          }
        }
        .touch-device &-item:hover {
          @include whiteBtn;
        }
        &-item:hover {
          @include whiteBtnAnim;
        }
      }

    }
    &__lastSection {
      display: flex;
      align-items: center;

      @media (max-width: 768px) {
        display: none;
      }

      &-socialButtons {
        display: flex;
        align-items: center;
        gap: 16px;


        @media (min-width: 768px) and (max-width: 1080px){
          gap: calc(
                  8px + (16 - 8) * ((100vw - 768px) / (1080 - 768))
          );
        }

        &-item {
          background-image: url("../../images/socialbg.svg");
          width: 56px;
          height: 56px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-size: cover;
          background-repeat: no-repeat;
          cursor: pointer;
          @include yellowBtn;
          transition: filter 1s ease;

          @media (min-width: 1440px) and (max-width: 1920px){
            width: calc(
                    40px + (56 - 40) * ((100vw - 1440px) / (1920 - 1440))
            );
            height: calc(
                    40px + (56 - 40) * ((100vw - 1440px) / (1920 - 1440))
            );
          }
          @media (max-width: 1440px){
            width: 40px;
            height: 40px;
          }

          img {
            width: 32px;

            @media (min-width: 1440px) and (max-width: 1920px){
              width: calc(
                      24px + (32 - 24) * ((100vw - 1440px) / (1920 - 1440))
              );
            }
            @media (max-width: 1440px) {
              width: 24px;
            }
          }
        }
        .touch-device &-item:hover {
          @include yellowBtn;
        }
        &-item:hover {
          @include yellowBtnAnim;
        }
      }
      &-walletBtn {
        background-image: url("../../images/walletBtnBg.svg");
        background-size: cover;
        background-repeat: round;
        color: var(--font-color-black);
        padding: 12px 58px;
        margin-left: 24px;
        font-size: 16px;
        text-transform: uppercase;
      }
    }
    &__mobBtn {
      display: none;
      background-image: url("../../images/mobHeaderBtn.png");
      background-repeat: round;
      cursor: pointer;
      width: 48px;
      height: 48px;
      box-sizing: border-box;
      padding: 12px;
      @media (max-width: 768px) {
        display: flex;
      }
    }
  }

  .mobile-menu {
    position: absolute;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.6s ease;
    width: 100%;
    background-color: #000;
    z-index: 10;
    padding-left: 20px;

    .button--connectwallet {
      margin-left: 0;
    }

    &__titleWrap {
      margin-bottom: 80px;
      margin-top: 40px;
      gap: 24px;
      display: flex;
      flex-direction: column;
      &-title {
        color: var(--font-color-white);
        font-size: 32px;
        font-family: Knewave;
        font-weight: 400;
        text-transform: uppercase;
        line-height: 40px;
        cursor: pointer;
        transition: filter 0.5s ease;

        @media (min-width: 320px) and (max-width: 480px){
          font-size: calc(24px + (32 - 24) * ((100vw - 320px) / (480 - 320)));
          line-height: calc(32px + (40 - 32) * ((100vw - 320px) / (480 - 320)));
          margin-top: calc(30px + (40 - 30) * ((100vw - 320px) / (480 - 320)));
        }

        a {
          text-decoration: none;
          color: inherit;
        }
      }
      @media (hover: hover) {
        &-title:hover {
          @include whiteBtn;
        }
      }

      .touch-device &-title:hover {
        @include whiteBtn;
      }
      &-title.animate {
        filter: drop-shadow(0 0 10px rgba(255, 255,255, 0.7)) drop-shadow(0 0 15px rgba(255, 255,255, 0.7)) drop-shadow(0 0 20px rgba(255, 255,255, 0.7)) !important;
      }
    }

    &__text {
      color: var(--font-color-white80);
      font-size: 16px;
      font-family: Russo One;
      font-weight: 400;
      line-height: 24px;
      margin-top: 48px;
      margin-bottom: 16px;

      @media (min-width: 320px) and (max-width: 480px){
        font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (480 - 320)));
        line-height: calc(21px + (24 - 21) * ((100vw - 320px) / (480 - 320)));
      }
    }
    &__btns {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-bottom: 80px;

      &-item {
        background-image: url("../../images/socialbg.svg");
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-size: cover;
        background-repeat: no-repeat;
        cursor: pointer;
        @include yellowBtn;
        transition: filter 1s ease;
        user-select: none;
      }

      @media (hover: hover) {
        &-item:hover {
          @include yellowBtnAnim;
        }
      }

      .touch-device &-item:hover {
        @include yellowBtn;
      }
      &-item.animate {
        filter: drop-shadow(0 0 2px #FEFF00) drop-shadow(0 0 4px #FEFF00) drop-shadow(0 0 8px #FEFF00) !important;
      }
    }
  }

  .mobile-menu.open {
    //padding: 40px 0 50px 20px;
    max-height: 500px; /* Примерное значение. Установите это значение достаточно большим, чтобы покрыть всю потенциальную высоту вашего меню. */
  }
}

