@import "src/pages/_variables.scss";
.vaultSectionWrapper {
  width: 100%;

  .vaultSection {

    &__header {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 16px;

      @media (min-width: 1080px) and (max-width: 1440px) {
        margin-bottom: calc(
                40px + (16 - 40) * ((100vw - 1080px) / (1440 - 1080))
        );
      }
      @media (max-width: 1079px) {
        margin-bottom: 40px;
      }
      @media (min-width: 480px) and (max-width: 768px) {
        margin-bottom: calc(
                32px + (40 - 32) * ((100vw - 480px) / (768 - 480))
        );
      }
      @media (min-width: 320px) and (max-width: 480px) {
        margin-bottom: calc(
                24px + (32 - 24) * ((100vw - 320px) / (480 - 320))
        );
      }

      &-title {
        color: var(--font-color-white);
        font-size: 64px;
        font-family: Knewave;
        font-weight: 400;
        text-transform: uppercase;
        line-height: 74px;
        margin-bottom: 52px;
        position: relative;

        @media (min-width: 1440px) and (max-width: 1920px) {
          margin-bottom: calc(
                  42px + (52 - 42) * ((100vw - 1440px) / (1920 - 1440))
          );
        }
        @media (min-width: 1080px) and (max-width: 1440px) {
          font-size: calc(
                  48px + (64 - 48) * ((100vw - 1080px) / (1440 - 1080))
          );
          line-height: calc(
                  68px + (74 - 68) * ((100vw - 1080px) / (1440 - 1080))
          );
          margin-bottom: calc(
                  32px + (42 - 32) * ((100vw - 1080px) / (1440 - 1080))
          );
        }
        @media (max-width: 1080px) {
          font-size: 48px;
          line-height: 68px;
          margin-bottom: 32px;
        }
        @media (min-width: 480px) and (max-width: 768px) {
          font-size: calc(
                  32px + (48 - 32) * ((100vw - 480px) / (768 - 480))
          );
          line-height: calc(
                  40px + (68 - 40) * ((100vw - 480px) / (768 - 480))
          );
          margin-bottom: calc(
                  20px + (32 - 20) * ((100vw - 480px) / (768 - 480))
          );
        }
        @media (min-width: 320px) and (max-width: 480px) {
          font-size: calc(
                  24px + (32 - 24) * ((100vw - 320px) / (480 - 320))
          );
          line-height: calc(
                  32px + (40 - 32) * ((100vw - 320px) / (480 - 320))
          );
          margin-bottom: calc(
                  24px + (20 - 24) * ((100vw - 320px) / (480 - 320))
          );
        }

        &-imgWrapper {
          width: 420px;
          position: absolute;
          left: 100%;
          top: 0;
          transform: translateY(-70%);

          @media (min-width: 1440px) and (max-width: 1920px) {
            width: calc(
                    260px + (420 - 260) * ((100vw - 1440px) / (1920 - 1440))
            );
          }
          @media (min-width: 1080px) and (max-width: 1440px) {
            width: calc(
                    200px + (260 - 200) * ((100vw - 1080px) / (1440 - 1080))
            );
          }
          @media (max-width: 1440px) {
            top: 40%;
          }
          @media (min-width: 768px) and (max-width: 1080px) {
            width: calc(
                    140px + (200 - 140) * ((100vw - 768px) / (1080 - 768))
            );
          }
          @media (max-width: 768px) {
            width: 140px;
            top: 60%;
          }
          @media (min-width: 320px) and (max-width: 480px) {
            width: calc(
                    100px + (140 - 100) * ((100vw - 320px) / (480 - 320))
            );
          }
          img {
            width: 100%;
          }
        }
      }
      &-description {
        color: var(--font-color-gray);
        font-size: 24px;
        font-family: Russo One;
        font-weight: 400;
        line-height: 30px;
        max-width: 791px;
        text-align: center;

        @media (min-width: 1440px) and (max-width: 1920px) {
          max-width: calc(
                  643px + (791 - 643) * ((100vw - 1440px) / (1920 - 1440))
          );
          font-size: calc(
                  16px + (24 - 16) * ((100vw - 1440px) / (1920 - 1440))
          );
          line-height: calc(
                  24px + (30 - 24) * ((100vw - 1440px) / (1920 - 1440))
          );
        }
        @media (max-width: 1440px) {
          font-size: 16px;
          line-height: 24px;
          max-width: 643px;
        }
        @media (min-width: 768px) and (max-width: 1080px) {
          max-width: calc(
                  549px + (643 - 549) * ((100vw - 768px) / (1080 - 768))
          );
        }
        @media (min-width: 480px) and (max-width: 768px) {
          max-width: calc(
                  357px + (549 - 357) * ((100vw - 480px) / (768 - 480))
          );
        }
        @media (min-width: 320px) and (max-width: 480px) {
          max-width: calc(
                  280px + (357 - 280) * ((100vw - 320px) / (480 - 320))
          );
          font-size: calc(
                  14px + (16 - 14) * ((100vw - 320px) / (480 - 320))
          );
          line-height: calc(
                  21px + (24 - 21) * ((100vw - 320px) / (480 - 320))
          );
        }
      }
    }
    &__schedule {
      margin-top: 16px;
      padding-bottom: 400px;

      @media (min-width: 1440px) and (max-width: 1920px) {
        padding-bottom: calc(
                320px + (400 - 320) * ((100vw - 1440px) / (1920 - 1440))
        );
      }
      @media (min-width: 1080px) and (max-width: 1440px) {
        padding-bottom: calc(
                160px + (320 - 160) * ((100vw - 1080px) / (1440 - 1080))
        );
        margin-top: calc(
                40px + (16 - 40) * ((100vw - 1080px) / (1440 - 1080))
        );
      }
      @media (max-width: 1080px) {
        margin-top: 40px;
      }
      @media (max-width: 1079px) {
        width: fit-content;
        margin: 0 auto;
      }
      @media (min-width: 768px) and (max-width: 1080px) {
        padding-bottom: calc(
                112px + (160 - 112) * ((100vw - 768px) / (1080 - 768))
        );
      }
      @media (max-width: 768px) {
        padding-bottom: 112px;
      }
      @media (min-width: 480px) and (max-width: 768px) {
        margin-top: calc(
                32px + (40 - 32) * ((100vw - 480px) / (768 - 480))
        );
      }
      @media (min-width: 320px) and (max-width: 480px) {
        padding-bottom: calc(
                80px + (112 - 80) * ((100vw - 320px) / (480 - 320))
        );
        margin-top: calc(
                24px + (32 - 24) * ((100vw - 320px) / (480 - 320))
        );
      }

      &-buttons {
        display: flex;
        align-items: center;
        gap: 24px;
        &-btn {
          font-size: 22px;
          font-family: Knewave;
          font-weight: 400;
          text-transform: uppercase;
          line-height: 24px;
          padding: 36px 59px 62px 59px;
          cursor: pointer;
          @include violetBtn;
          transition: filter 1s ease;

          @media (min-width: 1440px) and (max-width: 1920px){
            padding: calc(
                    24px + (36 - 24) * ((100vw - 1440px) / (1920 - 1440))
            )
            calc(
                    42px + (59 - 42) * ((100vw - 1440px) / (1920 - 1440))
            )
            calc(
                    44px + (62 - 44) * ((100vw - 1440px) / (1920 - 1440))
            )
            calc(
                    50px + (59 - 50) * ((100vw - 1440px) / (1920 - 1440))
            );
            font-size: calc(
                    16px + (22 - 16) * ((100vw - 1440px) / (1920 - 1440))
            )
          }
          @media (max-width: 1440px) {
            padding: 24px 42px 44px 50px;
            font-size: 16px;
          }
          @media (max-width: 1079px) {
            padding: 12px 23px 28px 23px;
          }
        }
        @media (hover: hover) {
          &-btn:hover {
            @include violetBtnAnim;
          }
        }

        .touch-device &-btn:hover {
          @include violetBtn;
        }
        &-btn.animate {
          transition: filter 0.5s ease;
          filter: drop-shadow(0 0 2px #5C36D5) drop-shadow(0 0 4px #5C36D5) drop-shadow(0 0 8px #5C36D5) !important;
        }

        .active {
          color: var(--font-color-white);
          background-image: url("../../../../images/mainBtnBG.png");
          background-size: cover;
          background-repeat: round;
        }
        .inactive {
          color: var(--font-color-white50);
          background-image: url("../../../../images/secondaryBtnBG.png");
          background-size: cover;
          background-repeat: round;
        }
      }
      &-graph {
        background-image: url("../../../../images/mainPage/scheduleBG.png");
        background-size: cover;
        background-repeat: round;
        display: flex;
        justify-content: space-between;
        padding: 71px 56px 159px 56px;

        @media (min-width: 1440px) and (max-width: 1920px) {
          padding: calc(68px + (71 - 68) * ((100vw - 1440px) / (1920 - 1440)))
          calc(49px + (56 - 49) * ((100vw - 1440px) / (1920 - 1440)))
          calc(136px + (159 - 136) * ((100vw - 1440px) / (1920 - 1440)))
          calc(53px + (56 - 53) * ((100vw - 1440px) / (1920 - 1440)));
        }
        @media (min-width: 1080px) and (max-width: 1440px) {
          padding: calc(56px + (68 - 56) * ((100vw - 1080px) / (1440 - 1080)))
          calc(32px + (49 - 32) * ((100vw - 1080px) / (1440 - 1080)))
          calc(109px + (136 - 109) * ((100vw - 1080px) / (1440 - 1080)))
          calc(32px + (53 - 32) * ((100vw - 1080px) / (1440 - 1080)));
        }
        @media (max-width: 1079px) {
          flex-direction: column;
          width: fit-content;
          padding: 40px 24px 97px 24px;
          background-image: url("../../../../images/mainPage/scheduleBGMob.png");
        }
        @media (max-width: 768px) {
          padding: 40px 18px 97px 18px;
        }
        @media (min-width: 320px) and (max-width: 480px) {
          background-image: url("../../../../images/mainPage/voltBG2.png");
          padding: calc(36px + (40 - 36) * ((100vw - 320px) / (480 - 320)))
          calc(16px + (18 - 16) * ((100vw - 320px) / (480 - 320)))
          calc(50px + (60 - 50) * ((100vw - 320px) / (480 - 320)))
          calc(16px + (18 - 16) * ((100vw - 320px) / (480 - 320)));
        }
        &-firstBlock {

          .recharts-surface {
            will-change: opacity;
          }
          line.recharts-cartesian-axis-line {
            stroke: rgba(0, 0, 0, 0.10);
          }
        }
        &-information {
          @media (max-width: 1079px) {
            display: flex;
          }
          @media (max-width: 767px) {
            flex-direction: column;
          }
          @media (min-width: 480px) and (max-width: 1080px) {
            margin-top: 37px;
          }
          &-time {
            background-image: url("../../../../images/mainPage/timeBG.png");
            background-size: cover;
            background-repeat: round;
            padding: 59px 22px 60px 21px;

            @media (min-width: 1440px) and (max-width: 1920px) {
              padding: calc(
                      32px + (59 - 32) * ((100vw - 1440px) / (1920 - 1440))
              ) calc(
                      27px + (22 - 27) * ((100vw - 1440px) / (1920 - 1440))
              ) calc(
                      28px + (60 - 28) * ((100vw - 1440px) / (1920 - 1440))
              ) calc(
                      27px + (21 - 27) * ((100vw - 1440px) / (1920 - 1440))
              );
            }
            @media (max-width: 1440px) {
              padding: 32px 27px 28px 27px;
            }
            @media (max-width: 1079px) {
              padding: 28px 23px 27px 23px;
            }
            @media (max-width: 767px) {
              margin-bottom: 16px;
              margin-top: 36px;
            }
            @media (min-width: 320px) and (max-width: 480px) {
              padding: calc(
                      30px + (28 - 30) * ((100vw - 320px) / (480 - 320))
              ) calc(
                      20px + (23 - 20) * ((100vw - 320px) / (480 - 320))
              ) calc(
                      29px + (27 - 29) * ((100vw - 320px) / (480 - 320))
              ) calc(
                      20px + (23 - 20) * ((100vw - 320px) / (480 - 320))
              );
            }

            .title {
              color: var(--font-color-white);
              font-size: 24px;
              font-family: Knewave;
              font-weight: 400;
              text-transform: uppercase;
              line-height: 32px;

              @media (max-width: 767px) {
                text-align: center;
              }
              @media (min-width: 320px) and (max-width: 480px) {
                font-size: calc(18px + (24 - 18) * ((100vw - 320px) / (480 - 320)));
                line-height: calc(26px + (32 - 26) * ((100vw - 320px) / (480 - 320)))
              }
            }
            .date {
              margin-top: 16px;
              color: var(--font-color-black);
              font-size: 32px;
              font-family: Knewave;
              font-weight: 400;
              text-transform: uppercase;
              line-height: 40px;
              gap: 24px;
              display: flex;
              align-items: center;
              justify-content: center;

              @media (min-width: 1080px) and (max-width: 1440px) {
                font-size: calc(24px + (32 - 24) * ((100vw - 1080px) / (1440 - 1080)));
                line-height: calc(32px + (40 - 32) * ((100vw - 1080px) / (1440 - 1080)))
              }
              @media (max-width: 1080px) {
                font-size: 24px;
                line-height: 32px;
              }
              @media (min-width: 320px) and (max-width: 480px) {
                font-size: calc(18px + (24 - 18) * ((100vw - 320px) / (480 - 320)));
                line-height: calc(26px + (32 - 26) * ((100vw - 320px) / (480 - 320)))
              }

              .dots {
                color: var(--font-color-white);
              }
              .timeCounter {
                width: 50px;
              }
            }
            .dateName {
              display: flex;
              align-items: center;
              margin-top: 16px;
              justify-content: center;
              gap: 45px;
              .item {
                color: var(--font-color-white50);
                font-size: 16px;
                font-family: Russo One;
                font-weight: 400;
                line-height: 24px;

                @media (max-width: 1079px) {
                  font-size: 14px;
                  line-height: 21px;
                }
              }
              @media (max-width: 1440px) {
                gap: 37px;
              }

            }
          }
          &-voltValues {
            display: flex;
            flex-direction: column;
            gap: 16px;
            margin-top: 32px;

            @media (max-width: 1079px) {
              margin-top: 0;
              margin-left: 16px;
              gap: 8px;
            }
            @media (max-width: 767px) {
              margin-top: 0;
              margin-left: 0;
              gap: 8px;
            }
            .value {
              color: var(--font-color-black);
              font-size: 18px;
              font-family: Russo One;
              font-weight: 400;
              line-height: 24px;
              display: flex;
              align-items: center;
              height: 64px;
              padding-left: 32px;
              background-repeat: round;
              background-size: cover;
              box-sizing: border-box;

              @media (min-width: 1440px) and (max-width: 1920px) {
                font-size: calc(
                        16px + (18 - 16) * ((100vw - 1440px) / (1920 - 1440))
                );
              }
              @media (max-width: 1440px) {
                font-size: 16px;
              }
              @media (max-width: 1079px) {
                padding-right: 80px;
              }
              @media (min-width: 480px) and (max-width: 1080px) {
                max-height: 54px;
              }
              @media (min-width: 320px) and (max-width: 480px) {
                font-size: calc(
                        14px + (16 - 14) * ((100vw - 320px) / (480 - 320))
                );
                line-height: calc(
                        21px + (24 - 21) * ((100vw - 320px) / (480 - 320))
                );
                max-height: calc(
                        48px + (54 - 48) * ((100vw - 320px) / (480 - 320))
                );
              }
              @media (max-width: 480px) {
                padding-right: 0;
              }

              span {
                font-size: 24px;
                line-height: 30px;

                @media (min-width: 320px) and (max-width: 480px) {
                  font-size: calc(
                          18px + (24 - 18) * ((100vw - 320px) / (480 - 320))
                  );
                  line-height: calc(
                          26px + (30 - 26) * ((100vw - 320px) / (480 - 320))
                  );
                }
              }
            }
            .value:nth-child(2) {
              background-image: url("../../../../images/mainPage/vvBG.png");
            }
            .value:nth-child(1) {
              background-image: url("../../../../images/mainPage/hpBG.png");
            }
            .value:nth-child(3) {
              background-image: url("../../../../images/mainPage/fpBG.png");
            }
          }
        }
      }
      .fade-in {
        animation: fadeIn 0.5s;
      }

      .fade-out {
        animation: fadeOut 0.5s;
      }

      @keyframes fadeIn {
        from { opacity: 0; }
        to { opacity: 1; }
      }

      @keyframes fadeOut {
        from { opacity: 1; }
        to { opacity: 0; }
      }
      &-buttonWrap {
        display: flex;
        justify-content: center;
        margin-top: 48px;

        @media (min-width: 768px) and (max-width: 1080px){
          margin-top: calc(40px + (48 - 40) * ((100vw - 768px) / (1080 - 768)));
        }
        @media (max-width: 768px) {
          margin-top: 40px;
        }
        @media (min-width: 320px) and (max-width: 480px){
          margin-top: calc(24px + (40 - 24) * ((100vw - 320px) / (480 - 320)));
        }
        &-button {
          color: var(--font-color-black);
          font-size: 22px;
          font-family: Knewave;
          font-weight: 400;
          text-transform: uppercase;
          line-height: 26px;
          background-image: url("../../../../images/mainPage/detailsBG.png");
          background-repeat: round;
          padding: 24px 98px;
          @include lightBreenBtn;
          transition: filter 1s ease;
          cursor: pointer;

          @media (min-width: 1440px) and (max-width: 1920px){
            padding: calc(12px + (24 - 12) * ((100vw - 1440px) / (1920 - 1440))) calc(75px + (98 - 75) * ((100vw - 1440px) / (1920 - 1440)));
            font-size: calc(16px + (22 - 16) * ((100vw - 1440px) / (1920 - 1440)));
            max-width: calc(240px + (320 - 240) * ((100vw - 1440px) / (1920 - 1440)));
          }
          @media (max-width: 1440px) {
            padding: 12px 75px;
            font-size: 16px;
            max-width: 240px;
          }
          @media (max-width: 1079px) {
            padding: 12px 65px;
          }

          a {
            text-decoration: none;
            color: inherit;
          }
        }
        @media (hover: hover) {
          &-button:hover {
            @include lightBreenBtnAnim;
          }
        }

        .touch-device &-button:hover {
          @include lightBreenBtn;
        }
        &-button.animate {
          transition: filter 0.5s ease;
          filter: drop-shadow(0 0 2px #00FED1) drop-shadow(0 0 4px #00FED1) drop-shadow(0 0 8px #00FED1) !important;
        }
      }
    }
  }
}
