:root {
  --font-color-black: #000000;
  --font-color-black50: rgba(0, 0, 0, 0.50);;
  --font-color-white: #FFFFFF;
  --font-color-yellow: #FFFF00;
  --font-color-white80: rgba(255, 255, 255, 0.80);
  --font-color-white90: rgba(255, 255, 255, 0.9);
  --font-color-white50: rgba(255, 255, 255, 0.50);
  --font-color-gray: #AAAAAA;
  --font-color-darkGreen: #7BD7A0;
  --font-color-violet: #5C36D5;
}

.limitWidth {
  max-width: 1680px;
  margin: 0 auto;

  @media (min-width: 1440px) and (max-width: 1920px){
    max-width: calc(
            1360px + (1680 - 1360) * ((100vw - 1440px) / (1920 - 1440))
    );
  }
  @media (min-width: 480px) and (max-width: 1440px){
    max-width: calc(
                440px + (1360 - 440) * ((100vw - 480px) / (1440 - 480))
        );
  }
  @media (min-width: 320px) and (max-width: 480px){
    max-width: calc(
            280px + (440 - 280) * ((100vw - 320px) / (480 - 320))
    );
  }
}
.limitWidthSec {
  max-width: 1680px;
  margin: 0 auto;
  @media (min-width: 1440px) and (max-width: 1920px){
    max-width: calc(
            1360px + (1680 - 1360) * ((100vw - 1440px) / (1920 - 1440))
    );
  }
  @media (min-width: 1080px) and (max-width: 1440px){
    max-width: calc(
            1000px + (1360 - 1000) * ((100vw - 1080px) / (1440 - 1080))
    );
  }
}

@mixin blur {
  filter: blur(10px);
}

@mixin whiteBtn {
  filter: drop-shadow(0 0 0px rgba(255, 255,255, 0.7)) drop-shadow(0 0 0px rgba(255, 255,255, 0.7)) drop-shadow(0 0 0px rgba(255, 255,255, 0.7));
}
@mixin whiteBtnAnim {
  filter: drop-shadow(0 0 10px rgba(255, 255,255, 0.7)) drop-shadow(0 0 15px rgba(255, 255,255, 0.7)) drop-shadow(0 0 20px rgba(255, 255,255, 0.7));
}
@mixin yellowBtn {
  filter: drop-shadow(0 0 0px #FEFF00) drop-shadow(0 0 0px #FEFF00) drop-shadow(0 0 0px #FEFF00);
}
@mixin yellowBtnAnim {
  filter: drop-shadow(0 0 2px #FEFF00) drop-shadow(0 0 4px #FEFF00) drop-shadow(0 0 8px #FEFF00);
}
@mixin grayBtn {
  filter: drop-shadow(0 0 0px #AAAAAA) drop-shadow(0 0 0px #AAAAAA) drop-shadow(0 0 0px #AAAAAA);
}
@mixin grayBtnAnim {
  filter: drop-shadow(0 0 10px #AAAAAA) drop-shadow(0 0 15px #AAAAAA) drop-shadow(0 0 20px #AAAAAA);
}
@mixin violetBtn {
  filter: drop-shadow(0 0 0px #5C36D5) drop-shadow(0 0 0px #5C36D5) drop-shadow(0 0 0px #5C36D5);
}
@mixin violetBtnAnim {
  filter: drop-shadow(0 0 2px #5C36D5) drop-shadow(0 0 4px #5C36D5) drop-shadow(0 0 8px #5C36D5);
}
@mixin lightBreenBtn {
  filter: drop-shadow(0 0 0px #00FED1) drop-shadow(0 0 0px #00FED1) drop-shadow(0 0 0px #00FED1);
}
@mixin lightBreenBtnAnim {
  filter: drop-shadow(0 0 2px #00FED1) drop-shadow(0 0 4px #00FED1) drop-shadow(0 0 8px #00FED1);
}
@keyframes fadeOutEffect {
  to {
    filter: drop-shadow(0 0 0px #FEFF00) drop-shadow(0 0 0px #FEFF00) drop-shadow(0 0 0px #FEFF00);
  }
}
@keyframes neonGlow {
  0% {
    filter: brightness(1.3) drop-shadow(0 0 0px #0075FF) drop-shadow(0 0 0px #0075FF) drop-shadow(0 0 0px #0075FF);
  }
  50% {
    filter: brightness(1.3) drop-shadow(0 0 8px #0075FF) drop-shadow(0 0 15px #0075FF) drop-shadow(0 0 20px #0075FF);
  }
  100% {
    filter: brightness(1.3) drop-shadow(0 0 5px #0075FF) drop-shadow(0 0 10px #0075FF) drop-shadow(0 0 15px #0075FF);
  }
}
@keyframes glowingCrown {
  0%, 100% {
    filter: brightness(1.3) drop-shadow(0 0 0px #8CFFB9) drop-shadow(0 0 0px #8CFFB9) drop-shadow(0 0 0px #8CFFB9);
  }
  50% {
    filter: brightness(1.3) drop-shadow(0 0 8px #8CFFB9) drop-shadow(0 0 15px #8CFFB9) drop-shadow(0 0 20px #8CFFB9);
  }
  100% {
    filter: brightness(1.3) drop-shadow(0 0 5px #8CFFB9) drop-shadow(0 0 10px #8CFFB9) drop-shadow(0 0 15px #8CFFB9);
  }
}
@keyframes glowingCrownSec {
  0%, 100% {
    filter: brightness(1) drop-shadow(0 0 2px #5dce8d) drop-shadow(0 0 4px #5dce8d) drop-shadow(0 0 6px #5dce8d);
  }
  50% {
    filter: brightness(1) drop-shadow(0 0 8px #5dce8d) drop-shadow(0 0 10px #5dce8d) drop-shadow(0 0 12px #5dce8d);
  }
  100% {
    filter: brightness(1) drop-shadow(0 0 4px #5dce8d) drop-shadow(0 0 6px #5dce8d) drop-shadow(0 0 8px #5dce8d);
  }
}
@keyframes glowingLighting {
  0%, 100% {
    filter: brightness(1.3) drop-shadow(0 0 0px #5D37D7) drop-shadow(0 0 0px #5D37D7) drop-shadow(0 0 0px #5D37D7);
  }
  50% {
    filter: brightness(1.3) drop-shadow(0 0 8px #5D37D7) drop-shadow(0 0 15px #5D37D7) drop-shadow(0 0 20px #5D37D7);
  }
  100% {
    filter: brightness(1.3) drop-shadow(0 0 5px #5D37D7) drop-shadow(0 0 10px #5D37D7) drop-shadow(0 0 15px #5D37D7);
  }
}
@keyframes scullLighting {
  0%, 100% {
    filter: brightness(1.3) drop-shadow(0 0 0px #FEFF00) drop-shadow(0 0 0px #FEFF00) drop-shadow(0 0 0px #FEFF00);
  }
  50% {
    filter: brightness(1.3) drop-shadow(0 0 5px #FEFF00) drop-shadow(0 0 10px #FEFF00) drop-shadow(0 0 15px #FEFF00);
  }
  100% {
    filter: brightness(1.3) drop-shadow(0 0 3px #FEFF00) drop-shadow(0 0 6px #FEFF00) drop-shadow(0 0 12px #FEFF00);
  }
}


