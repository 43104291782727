@import "src/pages/_variables.scss";
.teamSectionWrapper {
  width: 100%;
  margin-bottom: 240px;

  @media (min-width: 1440px) and (max-width: 1920px){
    margin-bottom: calc(
            216px + (240 - 216) * ((100vw - 1440px) / (1920 - 1440))
    );
  }
  @media (min-width: 1080px) and (max-width: 1440px){
    margin-bottom: calc(
            104px + (216 - 104) * ((100vw - 1080px) / (1440 - 1080))
    );
  }
  @media (min-width: 768px) and (max-width: 1080px){
    margin-bottom: calc(
            152px + (104 - 152) * ((100vw - 768px) / (1080 - 768))
    );
  }
  @media (min-width: 480px) and (max-width: 768px){
    margin-bottom: calc(
            88px + (152 - 88) * ((100vw - 480px) / (768 - 480))
    );
  }
  @media (min-width: 320px) and (max-width: 480px){
    margin-bottom: calc(
            80px + (88 - 80) * ((100vw - 320px) / (480 - 320))
    );
  }
  .teamSection {

    &__header {
      display: flex;
      justify-content: center;
      margin-bottom: 62px;

      @media (min-width: 1440px) and (max-width: 1920px){
        margin-bottom: calc(
                42px + (62 - 42) * ((100vw - 1440px) / (1920 - 1440))
        );
      }
      @media (min-width: 1080px) and (max-width: 1440px){
        margin-bottom: calc(
                32px + (42 - 32) * ((100vw - 1080px) / (1440 - 1080))
        );
      }
      @media (min-width: 768px) and (max-width: 1080px){
        margin-bottom: calc(
                40px + (32 - 40) * ((100vw - 768px) / (1080 - 768))
        );
      }
      @media (min-width: 480px) and (max-width: 768px){
        margin-bottom: calc(
                24px + (40 - 24) * ((100vw - 480px) / (768 - 480))
        );
      }
      @media (max-width: 480px) {
        margin-bottom: 24px;
      }
      &-title {
        color: var(--font-color-white);
        font-size: 64px;
        font-family: Knewave;
        font-weight: 400;
        text-transform: uppercase;
        line-height: 74px;
        position: relative;
        @media (min-width: 1080px) and (max-width: 1440px){
          font-size: calc(
                  48px + (64 - 64) * ((100vw - 1080px) / (1440 - 1080))
          );
          line-height: calc(
                  68px + (74 - 68) * ((100vw - 1080px) / (1440 - 1080))
          );
        }
        @media (max-width: 1080px) {
          font-size: 48px;
          line-height: 68px;
        }
        @media (min-width: 480px) and (max-width: 768px){
          font-size: calc(
                  32px + (48 - 32) * ((100vw - 480px) / (768 - 480))
          );
          line-height: calc(
                  40px + (68 - 40) * ((100vw - 480px) / (768 - 480))
          );
        }
        @media (min-width: 320px) and (max-width: 480px){
          font-size: calc(
                  24px + (32 - 24) * ((100vw - 320px) / (480 - 320))
          );
          line-height: calc(
                  32px + (40 - 32) * ((100vw - 320px) / (480 - 320))
          );
        }

        img {
          position: absolute;
          right: 100%;
          bottom: -100%;
          width: 420px;

          @media (min-width: 1440px) and (max-width: 1920px){
            width: calc(
                    320px + (420 - 320) * ((100vw - 1440px) / (1920 - 1440))
            );
          }
          @media (max-width: 1440px) {
            bottom: -30%;
          }
          @media (min-width: 1080px) and (max-width: 1440px){
            width: calc(
                    240px + (320 - 240) * ((100vw - 1080px) / (1440 - 1080))
            );
          }
          @media (min-width: 768px) and (max-width: 1080px){
            width: calc(
                    140px + (240 - 140) * ((100vw - 768px) / (1080 - 768))
            );
          }
          @media (max-width: 768px) {
            width: 140px;
          }
          @media (min-width: 320px) and (max-width: 480px){
            width: calc(
                    80px + (140 - 80) * ((100vw - 320px) / (480 - 320))
            );
          }
        }
      }
    }
    &__slider {

      .mySwiper {
        padding: 18px 0 18px 0;
        .swiper-wrapper {
          display: flex;
          align-items: center;
          height: 788px;
          gap: 100px;

          @media (min-width: 1440px) and (max-width: 1920px){
            gap: calc(
                    80px + (100 - 80) * ((100vw - 1440px) / (1920 - 1440))
            );
            height: calc(
                    608px + (788 - 608) * ((100vw - 1440px) / (1920 - 1440))
            );

          }
          @media (min-width: 1080px) and (max-width: 1440px){
            height: calc(
                    438px + (608 - 438) * ((100vw - 1080px) / (1440 - 1080))
            );
          }
          @media (max-width: 1440px) {
            gap: 80px;
          }
          @media (max-width: 1120px) {
            gap: 70px;
          }
          @media (max-width: 1080px) {
            gap: 70px;
          }
          @media (max-width: 1079px) {
            height: 438px;
          }
          @media (max-width: 1000px) {
            height: 458px;
          }
          @media (max-width: 999px) {
            height: 538px;
          }
          @media (min-width: 768px) and (max-width: 999px){
            height: calc(
                    558px + (538 - 558) * ((100vw - 768px) / (999 - 768))
            );
          }
          @media (min-width: 480px) and (max-width: 768px){
            height: calc(
                    348px + (558 - 348) * ((100vw - 480px) / (768 - 480))
            );
          }
          @media (min-width: 320px) and (max-width: 480px){
            height: calc(
                    318px + (348 - 318) * ((100vw - 320px) / (480 - 320))
            );
          }
          @media (max-width: 1079px) {
            gap: 100px;
          }
          @media (min-width: 1001px) and (max-width: 1079px){
            gap: calc(
                    70px + (100 - 70) * ((100vw - 1000px) / (1079 - 1000))
            );
          }
          @media (max-width: 1000px) {
            gap: 45px;
          }
          @media (max-width: 999px) {
            gap: 0;
          }

          .swiper-slide {
            background-position: center;
            background-size: cover;
            width: 486px !important;
            height: 486px;
            box-sizing: border-box;
            background-color: yellow;
            border-radius: 24px;
            transition: background-color 0.5s ease, transform 0.5s ease;
            display: flex;
            align-items: center;
            justify-content: center;

            @media (min-width: 1440px) and (max-width: 1920px){
              width: calc(
                      388px + (486 - 388) * ((100vw - 1440px) / (1920 - 1440))
              ) !important;
              height: calc(
                      388px + (486 - 388) * ((100vw - 1440px) / (1920 - 1440))
              );
            }
            @media (min-width: 1080px) and (max-width: 1440px){
              width: calc(
                      278px + (388 - 278) * ((100vw - 1080px) / (1440 - 1080))
              ) !important;
              height: calc(
                      278px + (388 - 278) * ((100vw - 1080px) / (1440 - 1080))
              );
            }
            @media (max-width: 1080px) {
              width: 278px !important;
              height: 278px
            }
            @media (max-width: 1000px) {
              width: 298px !important;
              height: 298px
            }
            @media (min-width: 768px) and (max-width: 999px){
              width: calc(
                      360px + (298 - 360) * ((100vw - 768px) / (999 - 768))
              )!important;
              height: calc(
                      360px + (298 - 360) * ((100vw - 768px) / (999 - 768))
              );
            }
            @media (min-width: 480px) and (max-width: 768px){
              width: calc(
                      220px + (360 - 220) * ((100vw - 480px) / (768 - 480))
              ) !important;
              height: calc(
                      220px + (360 - 220) * ((100vw - 480px) / (768 - 480))
              );
            }
            @media (min-width: 320px) and (max-width: 480px){
              width: calc(
                      180px + (220 - 180) * ((100vw - 320px) / (480 - 320))
              )!important;
              height: calc(
                      180px + (220 - 180) * ((100vw - 320px) / (480 - 320))
              );
            }

            .image {
              max-width: 422px;
              width: 100%;
              max-height: 422px;
              height: 100%;
              position: absolute;
              transform: scaleX(1) scaleY(1);
              transition: max-width 1s ease, max-height 1s ease, transform 1s ease;

              @media (min-width: 1440px) and (max-width: 1920px){
                max-width: calc(
                        324px + (422 - 324) * ((100vw - 1440px) / (1920 - 1440))
                );
                max-height: calc(
                        324px + (422 - 324) * ((100vw - 1440px) / (1920 - 1440))
                );
              }
              @media (min-width: 1080px) and (max-width: 1440px){
                max-width: calc(
                        246px + (324 - 246) * ((100vw - 1080px) / (1440 - 1080))
                );
                max-height: calc(
                        246px + (324 - 246) * ((100vw - 1080px) / (1440 - 1080))
                );
              }
              @media (max-width: 1080px) {
                max-width: 246px;
                max-height: 246px;
              }
              @media (max-width: 1000px) {
                max-width: 256px;
                max-height: 256px;
              }
              @media (min-width: 768px) and (max-width: 999px){
                max-width: calc(
                        328px + (256 - 328) * ((100vw - 768px) / (999 - 768))
                );
                max-height: calc(
                        328px + (256 - 328) * ((100vw - 768px) / (999 - 768))
                );
              }
              @media (min-width: 480px) and (max-width: 768px){
                max-width: calc(
                        188px + (328 - 188) * ((100vw - 480px) / (768 - 480))
                );
                max-height: calc(
                        188px + (328 - 188) * ((100vw - 480px) / (768 - 480))
                );
              }
              @media (min-width: 320px) and (max-width: 480px){
                max-width: calc(
                        156px + (188 - 156) * ((100vw - 320px) / (480 - 320))
                );
                max-height: calc(
                        156px + (188 - 156) * ((100vw - 320px) / (480 - 320))
                );
              }
            }

            .activeSlide {
              display: flex;
              justify-content: center;
              align-items: center;
              background-image: url("../../../../images/mainPage/twitBg.png");
              gap: 16px;
              background-repeat: round;
              padding: 19px 0 82px 0;
              background-size: cover;
              width: 100%;
              position: absolute;
              bottom: -2%;
              opacity: 0;
              transition: opacity 0.2s ease;
              transform: scaleX(1) scaleY(0.8);

              @media (min-width: 1440px) and (max-width: 1920px){
                padding: calc(12px + (19 - 12) * ((100vw - 1440px) / (1920 - 1440)))
                0
                calc(62px + (82 - 62) * ((100vw - 1440px) / (1920 - 1440)))
                0;
              }
              @media (min-width: 1080px) and (max-width: 1440px){
                padding: calc(4px + (12 - 4) * ((100vw - 1080px) / (1440 - 1080)))
                0
                calc(38px + (62 - 38) * ((100vw - 1080px) / (1440 - 1080)))
                0;
              }
              @media (min-width: 768px) and (max-width: 999px){
                transform: scaleX(1) scaleY(0.75);
              }
              @media (max-width: 768px) {
                padding: 4px 0 37px 0;
                bottom: 3%;
              }
              @media (max-width: 750px) {
                transform: scaleX(1) scaleY(0.9);
              }
              @media (max-width: 650px) {
                bottom: 1%;
              }
              @media (max-width: 600px) {
                transform: scaleX(0.9) scaleY(0.8);
              }
              @media (max-width: 540px) {
                transform: scaleX(0.9) scaleY(0.7);
                bottom: 0;
              }
              @media (max-width: 400px) {
                transform: scaleX(0.9) scaleY(0.6);
                bottom: -1%;
              }
              @media (min-width: 480px) and (max-width: 768px){
                max-width: calc(
                        220px + (380 - 220) * ((100vw - 480px) / (768 - 480))
                );
              }
              @media (min-width: 320px) and (max-width: 480px){
                max-width: calc(
                        190px + (220 - 190) * ((100vw - 320px) / (480 - 320))
                );
              }
              @media (max-width: 1080px) {
                padding: 4px 0 38px 0;
              }
              @media (max-width: 999px) {
                padding: 8px 0 58px 0;
              }
              @media (max-width: 768px) {
                padding: 4px 0 37px 0;
              }
              img {
                width: 32px;

                @media (min-width: 768px) and (max-width: 1080px){
                  width: calc(
                          24px + (32 - 24) * ((100vw - 768px) / (1080 - 768))
                  );
                }
                @media (min-width: 480px) and (max-width: 768px){
                  width: calc(
                          16px + (24 - 16) * ((100vw - 480px) / (768 - 480))
                  );
                }
                @media (max-width: 480px) {
                  width: 16px;
                }
              }
              span {
                color: var(--font-color-white);
                font-size: 22px;
                font-family: Knewave;
                font-weight: 400;
                text-transform: uppercase;
                line-height: 26px;

                @media (min-width: 1440px) and (max-width: 1920px){
                  font-size: calc(
                          16px + (22 - 16) * ((100vw - 1440px) / (1920 - 1440))
                  );
                  line-height: calc(
                          24px + (26 - 24) * ((100vw - 1440px) / (1920 - 1440))
                  );
                }
                @media (max-width: 1440px) {
                  font-size: 16px;
                  line-height: 24px;
                }
                @media (min-width: 320px) and (max-width: 480px){
                  font-size: calc(
                          14px + (16 - 14) * ((100vw - 320px) / (480 - 320))
                  );
                  line-height: calc(
                          21px + (24 - 21) * ((100vw - 320px) / (480 - 320))
                  );
                }
              }
            }
          }
          .swiper-slide::before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-image: url('../../../../images/mainPage/nftTeamBG.png');
            opacity: 0;
            transform: scale(0);
            transition: opacity 0.7s ease, transform 2.5s ease;
            z-index: -1;
            background-size: cover;
            background-repeat: round;
          }
          .swiper-slide-active {
            width: 486px !important;
            height: 486px;
            transform: rotateX(0deg) rotateY(0deg) scaleX(1.1) scaleY(1.5) !important;
            position: relative;

            @media (min-width: 1440px) and (max-width: 1920px){
              width: calc(
                      388px + (486 - 388) * ((100vw - 1440px) / (1920 - 1440))
              ) !important;
              height: calc(
                      388px + (486 - 388) * ((100vw - 1440px) / (1920 - 1440))
              );
            }
            @media (min-width: 1080px) and (max-width: 1440px){
              width: calc(
                      278px + (388 - 278) * ((100vw - 1080px) / (1440 - 1080))
              ) !important;
              height: calc(
                      278px + (388 - 278) * ((100vw - 1080px) / (1440 - 1080))
              );
            }
            @media (max-width: 1080px) {
              width: 278px !important;
              height: 278px
            }
            @media (max-width: 1000px) {
              width: 298px !important;
              height: 298px
            }
            @media (min-width: 768px) and (max-width: 999px){
              width: calc(
                      360px + (298 - 360) * ((100vw - 768px) / (999 - 768))
              )!important;
              height: calc(
                      360px + (298 - 360) * ((100vw - 768px) / (999 - 768))
              );
            }
            @media (min-width: 480px) and (max-width: 768px){
              width: calc(
                      220px + (360 - 220) * ((100vw - 480px) / (768 - 480))
              ) !important;
              height: calc(
                      220px + (360 - 220) * ((100vw - 480px) / (768 - 480))
              );
            }
            @media (min-width: 320px) and (max-width: 480px){
              width: calc(
                      180px + (220 - 180) * ((100vw - 320px) / (480 - 320))
              )!important;
              height: calc(
                      180px + (220 - 180) * ((100vw - 320px) / (480 - 320))
              );
            }

            .image {
              max-width: 422px;
              width: 100%;
              max-height: 422px;
              height: 100%;
              transform: scaleX(1) scaleY(0.8) translateY(-15%);
              position: absolute;
              transition: transform 1s ease;

              @media (min-width: 1440px) and (max-width: 1920px){
                max-width: calc(
                        324px + (422 - 324) * ((100vw - 1440px) / (1920 - 1440))
                );
                max-height: calc(
                        324px + (422 - 324) * ((100vw - 1440px) / (1920 - 1440))
                );
              }
              @media (min-width: 1080px) and (max-width: 1440px){
                max-width: calc(
                        246px + (324 - 246) * ((100vw - 1080px) / (1440 - 1080))
                );
                max-height: calc(
                        246px + (324 - 246) * ((100vw - 1080px) / (1440 - 1080))
                );
              }
              @media (max-width: 1080px) {
                max-width: 246px;
                max-height: 246px;
              }
              @media (max-width: 1000px) {
                max-width: 256px;
                max-height: 256px;
              }
              @media (min-width: 768px) and (max-width: 999px){
                max-width: calc(
                        328px + (256 - 328) * ((100vw - 768px) / (999 - 768))
                );
                max-height: calc(
                        328px + (256 - 328) * ((100vw - 768px) / (999 - 768))
                );
                transform: scaleX(1) scaleY(0.75) translateY(-20%);
              }
              @media (max-width: 999px) {
                transform: scaleX(1) scaleY(0.75) translateY(-20%);
              }
              @media (max-width: 850px) {
                transform: scaleX(1) scaleY(0.8) translateY(-15%);
              }
              @media (min-width: 480px) and (max-width: 768px){
                max-width: calc(
                        188px + (328 - 188) * ((100vw - 480px) / (768 - 480))
                );
                max-height: calc(
                        188px + (328 - 188) * ((100vw - 480px) / (768 - 480))
                );
              }
              @media (max-width: 450px) {
                transform: scaleX(1) scaleY(0.7) translateY(-15%);
              }
              @media (min-width: 320px) and (max-width: 480px){
                max-width: calc(
                        156px + (188 - 156) * ((100vw - 320px) / (480 - 320))
                );
                max-height: calc(
                        156px + (188 - 156) * ((100vw - 320px) / (480 - 320))
                );
              }
            }
            .activeSlide {
              bottom: -2%;
              position: absolute;
              transition: opacity 1s ease-in-out;
              opacity: 1;
              transform: scaleX(1) scaleY(0.8);

              @media (max-width: 450px) {
                transform: scaleX(1) scaleY(0.7);
              }
              @media (min-width: 768px) and (max-width: 999px){
                transform: scaleX(1) scaleY(0.75);
              }
              @media (max-width: 750px) {
                transform: scaleX(1) scaleY(0.9);
              }
              @media (max-width: 768px) {
                padding: 4px 0 37px 0;
                bottom: 3%;
              }
              @media (max-width: 650px) {
                bottom: 1%;
              }
              @media (max-width: 600px) {
                transform: scaleX(0.9) scaleY(0.8);
              }
              @media (max-width: 540px) {
                transform: scaleX(0.9) scaleY(0.7);
                bottom: 0;
              }
              @media (max-width: 400px) {
                transform: scaleX(0.9) scaleY(0.6);
                bottom: -1%;
              }


              img {
                width: 32px;

                @media (min-width: 768px) and (max-width: 1080px){
                  width: calc(
                          24px + (32 - 24) * ((100vw - 768px) / (1080 - 768))
                  );
                }
                @media (min-width: 480px) and (max-width: 768px){
                  width: calc(
                          16px + (24 - 16) * ((100vw - 480px) / (768 - 480))
                  );
                }
                @media (max-width: 480px) {
                  width: 16px;
                }
              }

              span {
                color: var(--font-color-white);
                font-size: 22px;
                font-family: Knewave;
                font-weight: 400;
                text-transform: uppercase;
                line-height: 26px;

                @media (min-width: 1440px) and (max-width: 1920px){
                  font-size: calc(
                          16px + (22 - 16) * ((100vw - 1440px) / (1920 - 1440))
                  );
                  line-height: calc(
                          24px + (26 - 24) * ((100vw - 1440px) / (1920 - 1440))
                  );
                }
                @media (max-width: 1440px) {
                  font-size: 16px;
                  line-height: 24px;
                }
                @media (min-width: 320px) and (max-width: 480px){
                  font-size: calc(
                          14px + (16 - 14) * ((100vw - 320px) / (480 - 320))
                  );
                  line-height: calc(
                          21px + (24 - 21) * ((100vw - 320px) / (480 - 320))
                  );
                }
              }
            }
          }
          .swiper-slide-active::before {
            opacity: 1;
            transform: scaleY(1.1) scaleX(1.3);
            transition: opacity 0.7s ease, transform 0.5s ease;

            @media (max-width: 999px) {
              transform: scaleY(1.1) scaleX(1.17);
            }
          }
        }
      }
    }
    &__teamItemWrap {
      display: flex;
      align-items: center;
      justify-content: space-around;

      &-item {
        background-size: cover;
        padding: 30px;
        box-sizing: border-box;
        background-color: yellow;
        border-radius: 24px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        @media (min-width: 768px) and (max-width: 999px){
          padding: calc(20px + (30 - 20) * ((100vw - 768px) / (999 - 768)));
        }
        @media (min-width: 480px) and (max-width: 768px){
          padding: calc(15px + (20 - 15) * ((100vw - 480px) / (768 - 480)));
          border-radius: calc(16px + (24 - 16) * ((100vw - 480px) / (768 - 480)));
        }
        @media (min-width: 320px) and (max-width: 480px){
          padding: calc(10px + (15 - 10) * ((100vw - 320px) / (480 - 320)));
          border-radius: calc(12px + (16 - 12) * ((100vw - 320px) / (480 - 320)));
        }
        .image {
          max-width: 422px;
          width: 100%;
          max-height: 422px;
          height: 100%;

          @media (min-width: 1440px) and (max-width: 1920px){
            max-width: calc(
                    324px + (422 - 324) * ((100vw - 1440px) / (1920 - 1440))
            );
            max-height: calc(
                    324px + (422 - 324) * ((100vw - 1440px) / (1920 - 1440))
            );
          }
          @media (min-width: 1080px) and (max-width: 1440px){
            max-width: calc(
                    246px + (324 - 246) * ((100vw - 1080px) / (1440 - 1080))
            );
            max-height: calc(
                    246px + (324 - 246) * ((100vw - 1080px) / (1440 - 1080))
            );
          }
          @media (max-width: 1080px) {
            max-width: 246px;
            max-height: 246px;
          }
          @media (max-width: 1000px) {
            max-width: 256px;
            max-height: 256px;
          }
          @media (min-width: 768px) and (max-width: 999px){
            max-width: 256px;
            max-height: 256px;
          }
          @media (min-width: 480px) and (max-width: 768px){
            max-width: calc(
                    188px + (256 - 188) * ((100vw - 480px) / (768 - 480))
            );
            max-height: calc(
                    188px + (256 - 188) * ((100vw - 480px) / (768 - 480))
            );
          }
          @media (min-width: 320px) and (max-width: 480px){
            max-width: calc(
                    116px + (188 - 116) * ((100vw - 320px) / (480 - 320))
            );
            max-height: calc(
                    116px + (188 - 116) * ((100vw - 320px) / (480 - 320))
            );
          }
        }
        .activeSlide {
          display: flex;
          justify-content: center;
          align-items: center;
          background-image: url("../../../../images/mainPage/twitBg.png");
          gap: 16px;
          background-repeat: round;
          padding: 19px 0 82px 0;
          background-size: cover;
          width: 100%;
          margin-top: 20px;

          @media (min-width: 1440px) and (max-width: 1920px){
            padding: calc(12px + (19 - 12) * ((100vw - 1440px) / (1920 - 1440)))
            0
            calc(62px + (82 - 62) * ((100vw - 1440px) / (1920 - 1440)))
            0;
          }
          @media (min-width: 1080px) and (max-width: 1440px){
            padding: calc(4px + (12 - 4) * ((100vw - 1080px) / (1440 - 1080)))
            0
            calc(38px + (62 - 38) * ((100vw - 1080px) / (1440 - 1080)))
            0;
          }
          @media (max-width: 999px) {
            margin-top: 0;
          }
          @media (min-width: 768px) and (max-width: 999px){
            transform: scaleX(1) scaleY(0.75);
          }
          @media (max-width: 768px) {
            padding: 4px 0 37px 0;
            bottom: 3%;
          }
          @media (max-width: 750px) {
            transform: scaleX(1) scaleY(0.9);
          }
          @media (max-width: 650px) {
            bottom: 1%;
          }
          @media (max-width: 600px) {
            transform: scaleX(0.9) scaleY(0.8);
          }
          @media (max-width: 540px) {
            transform: scaleX(0.9) scaleY(0.7);
            bottom: 0;
          }
          @media (max-width: 400px) {
            transform: scaleX(0.9) scaleY(0.6);
            bottom: -1%;
          }
          @media (min-width: 480px) and (max-width: 768px){
            max-width: calc(
                    220px + (380 - 220) * ((100vw - 480px) / (768 - 480))
            );
          }
          @media (min-width: 320px) and (max-width: 480px){
            max-width: calc(190px + (220 - 190) * ((100vw - 320px) / (480 - 320)));
            gap: calc(4px + (16 - 4) * ((100vw - 320px) / (480 - 320)));
          }
          @media (max-width: 1080px) {
            padding: 4px 0 38px 0;
          }
          @media (max-width: 999px) {
            padding: 8px 0 58px 0;
          }
          @media (max-width: 768px) {
            padding: 4px 0 37px 0;
            margin-top: 10px;
          }
          @media (max-width: 485px) {
            padding: 4px 0 37px 0;
            margin-top: 0;
          }
          img {
            width: 32px;

            @media (min-width: 768px) and (max-width: 1080px){
              width: calc(
                      24px + (32 - 24) * ((100vw - 768px) / (1080 - 768))
              );
            }
            @media (min-width: 480px) and (max-width: 768px){
              width: calc(
                      16px + (24 - 16) * ((100vw - 480px) / (768 - 480))
              );
            }
            @media (max-width: 480px) {
              width: 16px;
            }
          }
          span {
            color: var(--font-color-white);
            font-size: 22px;
            font-family: Knewave;
            font-weight: 400;
            text-transform: uppercase;
            line-height: 26px;

            @media (min-width: 1440px) and (max-width: 1920px){
              font-size: calc(
                      16px + (22 - 16) * ((100vw - 1440px) / (1920 - 1440))
              );
              line-height: calc(
                      24px + (26 - 24) * ((100vw - 1440px) / (1920 - 1440))
              );
            }
            @media (max-width: 1440px) {
              font-size: 16px;
              line-height: 24px;
            }
            @media (min-width: 320px) and (max-width: 480px){
              font-size: calc(12px + (16 - 12) * ((100vw - 320px) / (480 - 320)));
              line-height: calc(21px + (24 - 21) * ((100vw - 320px) / (480 - 320)));
            }
          }
        }
      }
    }
    &__btnWrap {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 40px;
      margin-top: 48px;

      @media (min-width: 1440px) and (max-width: 1920px){
        gap: calc(
                24px + (40 - 24) * ((100vw - 1440px) / (1920 - 1440))
        );
      }
      @media (max-width: 1440px) {
        gap: 24px;
      }

      @media (min-width: 768px) and (max-width: 1080px){
        margin-top: calc(
                40px + (48 - 40) * ((100vw - 768px) / (1080 - 768))
        );
      }
      @media (max-width: 768px) {
        margin-top: 40px;
      }
      @media (min-width: 320px) and (max-width: 480px){
        margin-top: calc(
                24px + (40 - 24) * ((100vw - 320px) / (480 - 320))
        );
      }

      &-img {
        max-width: 80px;
        width: 100%;
        cursor: pointer;
        @include lightBreenBtn;
        transition: filter 1s ease;

        @media (min-width: 1440px) and (max-width: 1920px){
          max-width: calc(
                  64px + (80 - 64) * ((100vw - 1440px) / (1920 - 1440))
          );
        }
        @media (max-width: 1440px) {
          max-width: 64px;
        }
      }
      @media (hover: hover) {
        &-img:hover {
          @include lightBreenBtnAnim;
        }
      }

      .touch-device &-img:hover {
        @include lightBreenBtn;
      }
      &-img.animate {
        transition: filter 0.5s ease;
        filter: drop-shadow(0 0 2px #00FED1) drop-shadow(0 0 4px #00FED1) drop-shadow(0 0 8px #00FED1) !important;
      }

    }
    .disabled-btn {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}
