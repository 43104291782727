@import "src/pages/_variables.scss";

.cookingSectionWrapper {
  width: 100%;
  padding-bottom: 400px;

  @media (min-width: 1440px) and (max-width: 1920px) {
    padding-bottom: calc(320px + (400 - 320) * ((100vw - 1440px) / (1920 - 1440)));
  }

  @media (min-width: 1080px) and (max-width: 1440px) {
    padding-bottom: calc(160px + (320 - 160) * ((100vw - 1080px) / (1440 - 1080)));
  }

  @media (min-width: 768px) and (max-width: 1080px) {
    padding-bottom: calc(112px + (160 - 112) * ((100vw - 768px) / (1080 - 768)));
  }

  @media (max-width: 768px) {
    padding-bottom: 112px;
  }

  @media (min-width: 320px) and (max-width: 480px) {
    padding-bottom: calc(80px + (112 - 80) * ((100vw - 320px) / (480 - 320)));
  }

  .cookingSection {

    &__firstBlock {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 48px;

      @media (max-width: 1079px) {
        flex-direction: column;
        margin-bottom: 40px;
      }

      @media (min-width: 480px) and (max-width: 768px) {
        margin-bottom: calc(32px + (40 - 32) * ((100vw - 480px) / (768 - 480)));
      }

      @media (max-width: 480px) {
        margin-bottom: 32px;
      }

      &-title {
        font-size: 64px;
        font-family: Knewave;
        font-weight: 400;
        text-transform: uppercase;
        color: var(--font-color-white);
        max-width: 686px;
        width: 100%;
        position: relative;
        z-index: 0;

        @media (min-width: 1080px) and (max-width: 1440px) {
          font-size: calc(48px + (64 - 48) * ((100vw - 1080px) / (1440 - 1080)));
        }

        @media (max-width: 1079px) {
          max-width: 100%;
          margin-bottom: 32px;
        }

        @media (min-width: 480px) and (max-width: 768px) {
          font-size: calc(32px + (48 - 32) * ((100vw - 480px) / (768 - 480)));
          margin-bottom: calc(30px + (32 - 30) * ((100vw - 480px) / (768 - 480)));
        }

        @media (min-width: 320px) and (max-width: 480px) {
          font-size: calc(24px + (32 - 24) * ((100vw - 320px) / (480 - 320)));
          margin-bottom: calc(24px + (32 - 24) * ((100vw - 320px) / (480 - 320)));
        }

        .img-wrapper {
          position: absolute;
          bottom: 40%;
          left: -17%;
          width: 320px;
          height: 320px;
          z-index: -1;

          @media (max-width: 1780px) {
            bottom: 20%;
            left: -8%;
          }

          @media (min-width: 1080px) and (max-width: 1440px) {
            width: calc(240px + (320 - 240) * ((100vw - 1080px) / (1440 - 1080)));
            height: calc(240px + (320 - 240) * ((100vw - 1080px) / (1440 - 1080)));
          }

          @media (min-width: 768px) and (max-width: 1080px) {
            width: calc(160px + (240 - 160) * ((100vw - 768px) / (1080 - 768)));
            height: calc(160px + (240 - 160) * ((100vw - 768px) / (1080 - 768)));
          }

          @media (min-width: 320px) and (max-width: 480px) {
            width: calc(120px + (160 - 120) * ((100vw - 320px) / (480 - 320)));
            height: calc(120px + (160 - 120) * ((100vw - 320px) / (480 - 320)));
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            animation: glowingCrown 2s infinite alternate;
          }
        }


      }

      &-description {
        font-size: 24px;
        font-family: Russo One;
        font-weight: 400;
        line-height: 30px;
        color: var(--font-color-gray);
        max-width: 637px;
        width: 100%;


        @media (min-width: 1440px) and (max-width: 1920px) {
          font-size: calc(16px + (24 - 16) * ((100vw - 1440px) / (1920 - 1440)));
          line-height: calc(24px + (30 - 24) * ((100vw - 1440px) / (1920 - 1440)));
          max-width: calc(637px + (768 - 637) * ((100vw - 1440px) / (1920 - 1440)));
        }

        @media (max-width: 1440px) {
          font-size: 16px;
          line-height: 24px;
        }

        @media (min-width: 1080px) and (max-width: 1440px) {
          max-width: calc(480px + (637 - 480) * ((100vw - 1080px) / (1440 - 1080)));
        }

        @media (max-width: 1079px) {
          max-width: 100%;
        }

        @media (min-width: 320px) and (max-width: 480px) {
          font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (480 - 320)));
        }
      }
    }

    &__lastBlock {
      background-image: url("../../../../images/mainPage/cookingBG.png");
      display: flex;
      background-size: cover;
      background-repeat: round;
      padding: 110px 56px 110px 56px;
      justify-content: space-between;

      @media (min-width: 1440px) and (max-width: 1920px) {
        padding: calc(97px + (110 - 97) * ((100vw - 1440px) / (1920 - 1440))) 56px calc(97px + (110 - 97) * ((100vw - 1440px) / (1920 - 1440))) 56px;
      }

      @media (max-width: 1199px) {
        padding: 55px 32px;
      }

      @media (max-width: 1079px) {
        flex-direction: column-reverse;
        background-image: url("../../../../images/mainPage/cookingBGmob.png");
      }

      @media (max-width: 1079px) {
        width: fit-content;
        margin: 0 auto;
      }

      @media (max-width: 767px) {
        padding: 32px 16px 120px 16px;
        background-image: url("../../../../images/mainPage/cookingBG767.png");
      }

      @media (max-width: 360px) {
        padding: 32px 16px 80px 16px;
      }

      &-slider {
        position: relative;

        @media (max-width: 1079px) {
          max-width: 570px;
        }

        @media (max-width: 767px) {
          max-width: 640px;
        }

        @media (min-width: 480px) and (max-width: 767px) {
          max-width: calc(408px + (640 - 408) * ((100vw - 480px) / (767 - 480)));
        }

        @media (min-width: 320px) and (max-width: 480px) {
          max-width: calc(248px + (408 - 248) * ((100vw - 320px) / (480 - 320)));
        }

        &-controls {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          z-index: 0;
          right: -10%;
          gap: 40px;

          @media (min-width: 1440px) and (max-width: 1920px) {
            gap: calc(24px + (40 - 24) * ((100vw - 1440px) / (1920 - 1440)));
          }

          @media (max-width: 767px) {
            top: 100%;
            transform: translateX(-50%);
            flex-direction: row-reverse;
            left: 50%;
            right: inherit;
            margin-top: 24px;
            gap: 24px;
          }

          @media (min-width: 320px) and (max-width: 480px) {
            margin-top: calc(2px + (24 - 2) * ((100vw - 320px) / (480 - 320)));
          }

          &-btn {
            cursor: pointer;
            @include yellowBtn;
            transition: filter 1s ease, opacity 0.3s ease;
            width: 80px;

            @media (min-width: 1440px) and (max-width: 1920px) {
              width: calc(63px + (80 - 63) * ((100vw - 1440px) / (1920 - 1440)));
            }

            @media (max-width: 1440px) {
              width: 63px;
            }

            @media (max-width: 1199px) {
              width: 60px;
            }

            img {
              width: 100%;
            }
          }

          .disabled-btn {
            opacity: 0.5;
            pointer-events: none;
          }

          .sec {
            @media (max-width: 767px) {
              transform: rotate(180deg);
            }
          }

          @media (hover: hover) {
            &-btn:hover {
              @include yellowBtnAnim;
            }
          }

          .touch-device &-btn:hover {
            @include yellowBtn;
          }

          &-btn.animate {
            filter: drop-shadow(0 0 2px #FEFF00) drop-shadow(0 0 4px #FEFF00) drop-shadow(0 0 8px #FEFF00) !important;
          }
        }

        .slick-slider {
          button {
            display: none !important;
          }
        }

        .slick-list {
          height: 662px !important;
          width: 750px !important;

          @media (max-width: 400px) {
            padding: 6px 0 0 0;
          }

          @media (min-width: 1440px) and (max-width: 1920px) {
            width: calc(581px + (750 - 581) * ((100vw - 1440px) / (1920 - 1440))) !important;
            height: calc(499px + (662 - 499) * ((100vw - 1440px) / (1920 - 1440))) !important;
          }

          @media (min-width: 1200px) and (max-width: 1440px) {
            width: calc(510px + (581 - 510) * ((100vw - 1200px) / (1440 - 1200))) !important;
          }

          @media (max-width: 1199px) {
            height: 500px !important;
          }

          @media (max-width: 1079px) {
            max-width: 544px;
            height: 400px !important;
          }

          @media (min-width: 1080px) and (max-width: 1200px) {
            max-width: calc(440px + (490 - 440) * ((100vw - 1080px) / (1200 - 1080))) !important;
          }

          @media (max-width: 767px) {
            max-width: 640px;
          }

          @media (min-width: 480px) and (max-width: 767px) {
            max-width: calc(409px + (640 - 409) * ((100vw - 480px) / (767 - 480)));
            height: calc(426px + (450 - 426) * ((100vw - 480px) / (767 - 480))) !important;
          }

          @media (min-width: 320px) and (max-width: 480px) {
            max-width: calc(250px + (408 - 250) * ((100vw - 320px) / (480 - 320)));
            height: calc(500px + (410 - 500) * ((100vw - 320px) / (480 - 320))) !important;
          }
        }

        .slick-slide {
          padding-bottom: 16px;
          box-sizing: border-box;

          @media (min-width: 1440px) and (max-width: 1920px) {
            width: calc(581px + (740 - 581) * ((100vw - 1440px) / (1920 - 1440))) !important;
          }

          @media (min-width: 1200px) and (max-width: 1440px) {
            max-width: calc(470px + (581 - 470) * ((100vw - 1200px) / (1440 - 1200))) !important;
          }
        }

        &-item {
          border-radius: 16px;
          border: 2px black solid;
          display: flex !important;
          padding: 16px 16px 14px 16px;
          background: var(--font-color-darkGreen);
          max-width: 740px;
          box-sizing: border-box;
          user-select: none;

          @media (min-width: 1440px) and (max-width: 1920px) {
            max-width: calc(560px + (740 - 560) * ((100vw - 1440px) / (1920 - 1440))) !important;
          }

          @media (min-width: 1200px) and (max-width: 1440px) {
            max-width: calc(470px + (560 - 470) * ((100vw - 1200px) / (1440 - 1200))) !important;
          }

          @media (max-width: 1199px) {
            flex-direction: column;
            padding: 16px 16px 16px 16px;
          }

          @media (min-width: 1080px) and (max-width: 1200px) {
            max-width: calc(400px + (450 - 400) * ((100vw - 1080px) / (1200 - 1080))) !important;
          }

          @media (min-width: 480px) and (max-width: 767px) {
            max-width: calc(408px + (640 - 408) * ((100vw - 480px) / (767 - 480)));
          }

          @media (min-width: 420px) and (max-width: 430px) {
            height: calc(205px + (210 - 205) * ((100vw - 420px) / (430 - 420))) !important;
          }

          @media (min-width: 320px) and (max-width: 480px) {
            max-width: calc(248px + (408 - 248) * ((100vw - 320px) / (480 - 320)));
            height: calc(237px + (195 - 237) * ((100vw - 320px) / (480 - 320)));
          }

          &-date {
            margin-top: 8px;
            min-width: 128px;
            color: var(--font-color-black50);
            font-size: 24px;
            font-family: Russo One;
            font-weight: 400;
            line-height: 24px;

            @media (min-width: 1440px) and (max-width: 1920px) {
              min-width: calc(77px + (128 - 77) * ((100vw - 1440px) / (1920 - 1440)));
              font-size: calc(16px + (24 - 16) * ((100vw - 1440px) / (1920 - 1440)));
            }

            @media (max-width: 1440px) {
              font-size: 16px;
              min-width: 77px;
            }

            @media (max-width: 1199px) {
              margin-bottom: 3px;
              margin-right: 0;
              margin-top: 0;
            }

            @media (min-width: 320px) and (max-width: 480px) {
              margin-bottom: calc(6px + (3 - 6) * ((100vw - 320px) / (480 - 320)));
              font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (480 - 320)));
            }
          }

          &-content {
            .title {
              color: var(--font-color-black);
              font-size: 32px;
              font-family: Knewave;
              font-weight: 400;
              text-transform: uppercase;
              line-height: 40px;
              margin-bottom: 16px;

              @media (min-width: 1440px) and (max-width: 1920px) {
                font-size: calc(24px + (32 - 24) * ((100vw - 1440px) / (1920 - 1440)));
                line-height: calc(32px + (40 - 32) * ((100vw - 1440px) / (1920 - 1440)));
              }

              @media (max-width: 1440px) {
                font-size: 24px;
              }

              @media (min-width: 320px) and (max-width: 480px) {
                font-size: calc(18px + (24 - 18) * ((100vw - 320px) / (480 - 320)));
              }

              @media (min-width: 320px) and (max-width: 480px) {
                margin-bottom: calc(12px + (16 - 12) * ((100vw - 320px) / (480 - 320)));
                font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (480 - 320)));
                line-height: calc(21px + (32 - 21) * ((100vw - 320px) / (480 - 320)));
              }
            }

            .description {
              color: var(--font-color-black50);
              font-size: 18px;
              font-family: Russo One;
              font-weight: 400;
              line-height: 26px !important;
              height: 118px;
              overflow: hidden;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 3;
              /* start showing ellipsis when 3rd line is reached */

              @media (max-width: 1080px) {
                overflow: hidden;
              }

              @media (min-width: 1440px) and (max-width: 1920px) {
                font-size: calc(16px + (18 - 16) * ((100vw - 1440px) / (1920 - 1440)));
                line-height: calc(24px + (26 - 24) * ((100vw - 1440px) / (1920 - 1440)));
                height: calc(72px + (118 - 72) * ((100vw - 1440px) / (1920 - 1440)));
              }

              @media (max-width: 1199px) {
                height: 120px;
              }

              @media (max-width: 1079px) {
                height: 72px;
              }

              @media (max-width: 1440px) {
                font-size: 16px;
              }

              @media (min-width: 480px) and (max-width: 767px) {
                font-size: calc(14px + (16 - 14) * ((100vw - 480px) / (767 - 480)));
                line-height: calc(21px + (24 - 21) * ((100vw - 480px) / (767 - 480)));
                height: calc(80px + (90 - 80) * ((100vw - 480px) / (767 - 480)));
              }

              @media (max-width: 440px) {
                font-size: 14px;
                line-height: 21px;
              }
              @media (min-width: 320px) and (max-width: 480px) {
                height: 80px !important;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3 !important;
              }

              @media (min-width: 394px) and (max-width: 479px) {
                height: calc(112px + (72 - 112) * ((100vw - 394px) / (479 - 394)));
              }

              @media (max-width: 393px) {
                height: 123px;
              }
            }

            .description+span {
              display: inline-block;
              vertical-align: top;
              margin-top: -1px;
              cursor: pointer;
            }
          }

        }
      }

      &-dopBG {
        width: 100%;
        margin: 0 auto;

        @media (min-width: 480px) and (max-width: 767px) {
          margin-bottom: 17px;
          max-width: calc(367px + (588 - 367) * ((100vw - 480px) / (767 - 480)));
          height: calc(77px + (88 - 77) * ((100vw - 480px) / (767 - 480)));
          ;
        }

        @media (min-width: 320px) and (max-width: 480px) {
          margin-bottom: calc(18px + (17 - 18) * ((100vw - 320px) / (480 - 320)));
          height: calc(64px + (77 - 64) * ((100vw - 320px) / (480 - 320)));
        }

        @media (min-width: 768px) {
          display: none;
        }

        img {
          width: 100%;
          height: 100%;
        }
      }

      &-content {
        display: flex;
        flex-direction: column;
        align-items: center;

        @media (max-width: 1079px) {
          margin-bottom: 140px;
        }

        @media (min-width: 480px) and (max-width: 767px) {
          margin-bottom: calc(16px + (34 - 16) * ((100vw - 480px) / (767 - 480)));
        }

        @media (min-width: 320px) and (max-width: 480px) {
          margin-bottom: calc(2px + (16 - 2) * ((100vw - 320px) / (480 - 320)));
        }

        .slick-slider {
          max-width: 540px;

          @media (min-width: 1440px) and (max-width: 1920px) {
            max-width: calc(400px + (540 - 400) * ((100vw - 1440px) / (1920 - 1440))) !important;
          }

          @media (min-width: 1200px) and (max-width: 1440px) {
            max-width: calc(340px + (400 - 340) * ((100vw - 1200px) / (1440 - 1200))) !important;
          }

          @media (min-width: 1080px) and (max-width: 1200px) {
            max-width: calc(312px + (340 - 312) * ((100vw - 1080px) / (1200 - 1080))) !important;
          }

          @media (max-width: 1079px) {
            max-width: 640px;
          }

          @media (min-width: 480px) and (max-width: 767px) {
            max-width: calc(408px + (640 - 408) * ((100vw - 480px) / (767 - 480)));
          }

          @media (min-width: 320px) and (max-width: 480px) {
            max-width: calc(248px + (408 - 248) * ((100vw - 320px) / (480 - 320)));
          }


          button {
            display: none !important;
          }
        }

        .slick-track {
          display: flex;

          @media (min-width: 1440px) and (max-width: 1920px) {
            max-width: calc(400px + (540 - 400) * ((100vw - 1440px) / (1920 - 1440))) !important;
          }

          @media (min-width: 320px) and (max-width: 480px) {
            max-width: calc(248px + (408 - 248) * ((100vw - 320px) / (480 - 320)));
          }
        }

        .slick-track {
          width: 100% !important;
        }

        .slick-slide {
          width: 100% !important;
          transition: transform 0.3s;

          @media (min-width: 1440px) and (max-width: 1920px) {
            max-width: calc(400px + (540 - 400) * ((100vw - 1440px) / (1920 - 1440))) !important;
          }

          @media (min-width: 320px) and (max-width: 480px) {
            max-width: calc(248px + (408 - 248) * ((100vw - 320px) / (480 - 320)));
          }
        }

        .slick-center {
          transform: scale(1)
        }

        .slick-slide:not(.slick-center) {
          transform: scale(0.8) !important;
        }

        &-image {
          width: 540px;

          @media (min-width: 1440px) and (max-width: 1920px) {
            width: calc(400px + (540 - 400) * ((100vw - 1440px) / (1920 - 1440)));
          }

          @media (min-width: 1200px) and (max-width: 1440px) {
            width: calc(340px + (400 - 340) * ((100vw - 1200px) / (1440 - 1200)));
          }

          @media (min-width: 1080px) and (max-width: 1200px) {
            width: calc(312px + (340 - 312) * ((100vw - 1080px) / (1200 - 1080)));
          }

          @media (max-width: 1079px) {
            width: 640px;
          }

          @media (min-width: 480px) and (max-width: 768px) {
            width: calc(408px + (640 - 408) * ((100vw - 480px) / (768 - 480)));
          }

          @media (min-width: 320px) and (max-width: 480px) {
            max-width: calc(248px + (408 - 248) * ((100vw - 320px) / (480 - 320)));
          }

          img {
            width: 100%;
          }
        }

        &-title {
          color: var(--font-color-black);
          font-size: 32px;
          font-family: Knewave;
          font-weight: 400;
          text-transform: uppercase;
          line-height: 32px;
          margin-top: 16px;
          margin-bottom: 26px;
          color: "red";

          @media (min-width: 1440px) and (max-width: 1920px) {
            margin-bottom: calc(16px + (26 - 16) * ((100vw - 1440px) / (1920 - 1440)));
            font-size: calc(24px + (32 - 24) * ((100vw - 1440px) / (1920 - 1440)));
            line-height: calc(32px + (40 - 32) * ((100vw - 1440px) / (1920 - 1440)));
          }

          @media (max-width: 1440px) {
            margin-bottom: 16px;
            font-size: 24px;
          }
        }

        &-description {
          color: var(--font-color-black50);
          font-size: 18px;
          font-family: Russo One;
          font-weight: 400;
          line-height: 26px;

          @media (min-width: 1440px) and (max-width: 1920px) {
            font-size: calc(16px + (18 - 16) * ((100vw - 1440px) / (1920 - 1440)));
            line-height: calc(24px + (26 - 24) * ((100vw - 1440px) / (1920 - 1440)));
          }

          @media (max-width: 1440px) {
            font-size: 16px;
            line-height: 24px;
          }

          @media (min-width: 480px) and (max-width: 768px) {
            font-size: calc(14px + (16 - 14) * ((100vw - 480px) / (768 - 480)));
            line-height: calc(21px + (24 - 21) * ((100vw - 480px) / (768 - 480)));
          }

          @media (max-width: 480px) {
            font-size: 14px;
            line-height: 21px;
          }
        }

        &-controls {
          display: flex;
          gap: 24px;
          margin-top: 70px;

          @media (min-width: 1440px) and (max-width: 1920px) {
            margin-top: calc(30px + (70 - 30) * ((100vw - 1440px) / (1920 - 1440)));
            gap: calc(24px + (40 - 24) * ((100vw - 1440px) / (1920 - 1440)));
          }

          @media (max-width: 1199px) {
            margin-top: 40px;
          }

          @media (max-width: 1079px) {
            margin-top: 48px;
          }

          @media (min-width: 480px) and (max-width: 768px) {
            margin-top: calc(60px + (48 - 60) * ((100vw - 480px) / (768 - 480)));
          }

          @media (min-width: 320px) and (max-width: 480px) {
            margin-top: calc(16px + (60 - 16) * ((100vw - 320px) / (480 - 320)));
          }

          &-btn {
            transform: rotate(90deg);
            cursor: pointer;
            @include yellowBtn;
            transition: filter 1s ease, opacity 0.3s ease;
            width: 80px;

            @media (min-width: 1440px) and (max-width: 1920px) {
              width: calc(63px + (80 - 63) * ((100vw - 1440px) / (1920 - 1440)));
            }

            @media (max-width: 1440px) {
              width: 63px;
            }

            @media (max-width: 1199px) {
              width: 60px;
            }

            img {
              width: 100%;
            }
          }

          .disabled-btn {
            opacity: 0.5;
            pointer-events: none;
          }

          @media (hover: hover) {
            &-btn:hover {
              @include yellowBtnAnim;
            }
          }

          .touch-device &-btn:hover {
            @include yellowBtn;
          }

          &-btn.animate {
            filter: drop-shadow(0 0 2px #FEFF00) drop-shadow(0 0 4px #FEFF00) drop-shadow(0 0 8px #FEFF00) !important;
          }
        }
      }
    }
  }
}

.readmore {
  color: var(--font-color-black50);
  font-size: 18px;
  font-family: Russo One;
  font-weight: 400;
  line-height: 26px;
}


@media (min-width: 320px) and (max-width: 480px) {
  .modal-main {
    width: 300px !important;
    padding: 10px !important;
  }
}


@media (min-width: 1440px) and (max-width: 1920px) {
  .modal-img {
    width: calc(400px + (540 - 400) * ((100vw - 1440px) / (1920 - 1440))) !important;
  }
}

@media (min-width: 1200px) and (max-width: 1440px) {
  .modal-img {
    width: calc(340px + (400 - 340) * ((100vw - 1200px) / (1440 - 1200))) !important;
  }
}

@media (min-width: 1080px) and (max-width: 1200px) {
  .modal-img {
    width: calc(312px + (340 - 312) * ((100vw - 1080px) / (1200 - 1080))) !important;
  }
}

@media (max-width: 1079px) {
  .modal-img {
    width: 600px !important;
  }
}

@media (min-width: 480px) and (max-width: 768px) {
  .modal-img {
    width: calc(308px + (640 - 408) * ((100vw - 480px) / (768 - 480))) !important;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .modal-img {
    max-width: calc(148px + (408 - 248) * ((100vw - 320px) / (480 - 320))) !important;
    margin-top: 30px;
  }
}


.modal {
  position: fixed;
  top: 0;
  left: 0;
  width:100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 999999999;
}

.modal-main {
  position:fixed;
  padding: 40px;
  font-family: "Russo One" !important;
  background-image: url("../../../../images/mainPage/scheduleBG.png");
  background-size: cover;
  background-repeat: round;
  width: 600px;
  height: auto;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
}


.modal-description {
  height: 150px;
  overflow-y: scroll;
}

.modal-description::-webkit-scrollbar {
  width: 7px; 
}

.modal-description::-webkit-scrollbar-track {
  background: #5c36d5;
}


.modal-description::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 20px;       
  border: 1px solid white; 
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}