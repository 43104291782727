@import "src/pages/_variables.scss";
.footerWrapper {
  width: 100%;
  padding-bottom: 24px;
  position: relative;

  .gradientSec {
    position: absolute;
    top: -1px;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, black 70%, rgba(0, 0, 0, 0) 100%);
    opacity: 0.8;
  }

  .footer {
    position: sticky;
    &__upper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 128px;

      @media (min-width: 1440px) and (max-width: 1920px){
        margin-bottom: calc(
                56px + (128 - 56) * ((100vw - 1440px) / (1920 - 1440))
        );
      }
      @media (max-width: 1440px) {
        margin-bottom: 56px;
      }
      @media (min-width: 768px) and (max-width: 1080px){
        margin-bottom: calc(
                40px + (56 - 40) * ((100vw - 768px) / (1080 - 768))
        );
      }
      @media (min-width: 480px) and (max-width: 768px){
        margin-bottom: calc(
                36px + (40 - 36) * ((100vw - 480px) / (768 - 480))
        );
      }
      @media (max-width: 480px) {
        margin-bottom: 36px;
      }

      &-title {
        color: white;
        font-size: 64px;
        font-family: Knewave;
        font-weight: 400;
        text-transform: uppercase;
        line-height: 74px;

        @media (min-width: 1080px) and (max-width: 1440px){
          font-size: calc(
                  48px + (64 - 64) * ((100vw - 1080px) / (1440 - 1080))
          );
          line-height: calc(
                  68px + (74 - 68) * ((100vw - 1080px) / (1440 - 1080))
          );
        }
        @media (max-width: 1080px) {
          font-size: 48px;
          line-height: 68px;
        }
        @media (min-width: 480px) and (max-width: 768px){
          font-size: calc(
                  32px + (48 - 32) * ((100vw - 480px) / (768 - 480))
          );
          line-height: calc(
                  40px + (68 - 40) * ((100vw - 480px) / (768 - 480))
          );
        }
        @media (min-width: 320px) and (max-width: 480px){
          font-size: calc(
                  24px + (32 - 24) * ((100vw - 320px) / (480 - 320))
          );
          line-height: calc(
                  32px + (40 - 32) * ((100vw - 320px) / (480 - 320))
          );
        }
      }
    }
    &__first {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: sticky;

      @media (max-width: 670px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 32px;
      }
      @media (min-width: 320px) and (max-width: 480px){
        gap: calc(24px + (32 - 24) * ((100vw - 320px) / (480 - 320)));
      }
      &-logo {
        cursor: pointer;
        &-image {
          width: 160px;
          height: 140px;

          @media (min-width: 1440px) and (max-width: 1920px){
            width: calc(
                    92px + (160 - 92) * ((100vw - 1440px) / (1920 - 1440))
            );
            height: calc(
                    80px + (140 - 80) * ((100vw - 1440px) / (1920 - 1440))
            );
          }
          @media (max-width: 1440px) {
            width: 92px;
            height: 80px;
          }
          @media (min-width: 768px) and (max-width: 1080px){
            width: calc(
                    80px + (92 - 80) * ((100vw - 768px) / (1080 - 768))
            );
            height: calc(
                    64px + (80 - 64) * ((100vw - 768px) / (1080 - 768))
            );
          }
          @media (max-width: 768px) {
            height: 64px;
          }
          @media (min-width: 480px) and (max-width: 768px){
            width: calc(
                    56px + (80 - 56) * ((100vw - 480px) / (768 - 480))
            );
            height: calc(
                    48px + (64 - 48) * ((100vw - 480px) / (768 - 480))
            );
          }
          @media (min-width: 320px) and (max-width: 480px){
            width: calc(
                    48px + (56 - 48) * ((100vw - 320px) / (480 - 320))
            );
            height: calc(
                    40px + (48 - 40) * ((100vw - 320px) / (480 - 320))
            );
          }
        }
      }
      &-text {
        color: var(--font-color-white);
        font-size: 18px;
        font-family: Knewave;
        font-weight: 400;
        text-transform: uppercase;
        line-height: 28px;
        cursor: pointer;
        @include whiteBtn;
        transition: filter 1s ease;

        @media (min-width: 1440px) and (max-width: 1920px){
          font-size: calc(
                  16px + (18 - 16) * ((100vw - 1440px) / (1920 - 1440))
          );
        }
        @media (max-width: 1440px) {
          font-size: 16px;
        }

        a {
          text-decoration: none;
          color: inherit;
        }
      }
      @media (hover: hover) {
        &-text:hover {
          @include whiteBtnAnim;
        }
      }

      .touch-device &-text:hover {
        @include whiteBtn;
      }
      &-text.animate {
        transition: filter 0.5s ease;
        filter: drop-shadow(0 0 10px rgba(255, 255,255, 0.7)) drop-shadow(0 0 15px rgba(255, 255,255, 0.7)) drop-shadow(0 0 20px rgba(255, 255,255, 0.7)) !important;
      }

      &-btnWrapper {
        display: flex;
        align-items: center;
        gap: 16px;

        &-item {
          background-image: url("../../images/socialbg.svg");
          width: 56px;
          height: 56px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-size: cover;
          background-repeat: no-repeat;
          cursor: pointer;
          @include yellowBtn;
          transition: filter 1s ease;

          @media (min-width: 1440px) and (max-width: 1920px){
            width: calc(
                    40px + (56 - 40) * ((100vw - 1440px) / (1920 - 1440))
            );
            height: calc(
                    40px + (56 - 40) * ((100vw - 1440px) / (1920 - 1440))
            );
          }
          @media (max-width: 1440px) {
            width: 40px;
            height: 40px;
          }
          img {
            width: 32px;

            @media (min-width: 1440px) and (max-width: 1920px){
              width: calc(
                      24px + (32 - 24) * ((100vw - 1440px) / (1920 - 1440))
              );
            }
            @media (max-width: 1440px) {
              width: 24px;
            }
          }
        }
        @media (hover: hover) {
          &-item:hover {
            @include yellowBtnAnim;
          }
        }

        .touch-device &-item:hover {
          @include yellowBtn;
        }
        &-item.animate {
          filter: drop-shadow(0 0 2px #FEFF00) drop-shadow(0 0 4px #FEFF00) drop-shadow(0 0 8px #FEFF00) !important;
        }
      }
    }
    &__last {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 24px;

      @media (max-width: 670px) {
        margin-top: 17px;
      }
      &-item {
        color: var(--font-color-gray);
        font-size: 18px;
        font-family: Knewave;
        font-weight: 400;
        text-transform: uppercase;
        line-height: 28px;
        cursor: pointer;
        @include grayBtn;
        transition: filter 1s ease;

        @media (min-width: 1440px) and (max-width: 1920px){
          font-size: calc(
                  16px + (18 - 16) * ((100vw - 1440px) / (1920 - 1440))
          );
        }
        @media (max-width: 1440px) {
          font-size: 16px;
        }
        @media (min-width: 320px) and (max-width: 480px){
          font-size: calc(
                  14px + (16 - 14) * ((100vw - 320px) / (480 - 320))
          );
          line-height: calc(
                  21px + (28 - 21) * ((100vw - 320px) / (480 - 320))
          );
        }
      }
      @media (hover: hover) {
        &-item:hover {
          @include grayBtnAnim;
        }
      }

      .touch-device &-item:hover {
        @include grayBtn;
      }
      &-item.animate {
        transition: filter 0.5s ease;
        filter: drop-shadow(0 0 10px #AAAAAA) drop-shadow(0 0 15px #AAAAAA) drop-shadow(0 0 20px #AAAAAA) !important;
      }
    }
  }
}
