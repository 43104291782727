.wrapper {
  border-radius: 10px;
  box-sizing: border-box;
  background-size: cover;
  background-repeat: round;
  background-image: url("../../images/Union.png");
  padding: 31px 38px 29px 38px;

  @media (max-width: 1920px){
    padding: 31px 38px 29px 38px;
  }
  @media (max-width: 1440px){
    padding: 28px 44px 28px 30px;
  }
  @media (max-width: 768px){
    padding: 20px 40px 20px 24px;
  }
  @media (max-width: 480px){
    padding: 14px 34px 14px 18px;
  }

  &__title {
    font-family: Knewave;
    margin-bottom: 24px;
    font-size: 24px;

    @media (max-width: 1440px){
      margin-bottom: 20px;
      font-size: 20px;
    }
    @media (max-width: 768px){
      margin-bottom: 16px;
      font-size: 18px;
    }
    @media (max-width: 480px){
      margin-bottom: 14px;
      font-size: 14px;
    }
  }
  &__infoValue {
    background: #5C36D5;
    padding: 8px 0px 8px 16px;
    border-radius: 16px;
    font-size: 18px;
    font-weight: 400;
    font-family: "Russo One";
    max-width: 283px;
    width: 100%;

    &-first {
      color: #87FCB6;
      font-size: 18px;
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      @media (max-width: 1440px){
        margin-bottom: 8px;
        font-size: 16px;
      }
      @media (max-width: 768px){
        margin-bottom: 2px;
        font-size: 14px;
      }
      @media (max-width: 480px){
        font-size: 12px;
      }
      span {
        font-size: 24px;

        @media (max-width: 1440px){
          font-size: 20px;
        }
        @media (max-width: 768px){
          font-size: 18px;
        }
        @media (max-width: 480px){
          font-size: 14px;
        }
      }
    }
    &-second {
      color: #fff;
      font-size: 18px;
      display: flex;
      align-items: center;

      @media (max-width: 1440px){
        font-size: 16px;
      }
      @media (max-width: 768px){
        font-size: 14px;
      }
      @media (max-width: 480px){
        font-size: 12px;
      }
      span {
        font-size: 24px;

        @media (max-width: 1440px){
          font-size: 20px;
        }
        @media (max-width: 768px){
          font-size: 18px;
        }
        @media (max-width: 480px){
          font-size: 14px;
        }
      }
    }
  }
}
