@import "src/pages/_variables.scss";
.rewardsSectionWrapper {
  width: 100%;
  margin-bottom: 240px;
  position: relative;

  @media (min-width: 1080px) and (max-width: 1440px){
    margin-bottom: calc(
            160px + (240 - 160) * ((100vw - 1080px) / (1440 - 1080))
    );
  }
  @media (min-width: 768px) and (max-width: 1080px){
    margin-bottom: calc(
            112px + (160 - 112) * ((100vw - 768px) / (1080 - 768))
    );
  }
  @media (max-width: 768px) {
    margin-bottom: 112px;
  }
  @media (min-width: 320px) and (max-width: 480px){
    margin-bottom: calc(
            80px + (112 - 80) * ((100vw - 320px) / (480 - 320))
    );
  }

  .notification {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    &__title {
      color: var(--font-color-white);
      font-size: 82px;
      font-family: Knewave;
      font-weight: 400;
      text-transform: uppercase;
      line-height: 96px;
      white-space: nowrap;

      @media (min-width: 1440px) and (max-width: 1920px){
        font-size: calc(
                64px + (82 - 64) * ((100vw - 1440px) / (1920 - 1440))
        );
        line-height: calc(
                74px + (96 - 74) * ((100vw - 1440px) / (1920 - 1440))
        );
      }
      @media (min-width: 1080px) and (max-width: 1440px){
        font-size: calc(
                48px + (64 - 48) * ((100vw - 1080px) / (1440 - 1080))
        );
        line-height: calc(
                68px + (74 - 68) * ((100vw - 1080px) / (1440 - 1080))
        );
      }
      @media (min-width: 768px) and (max-width: 1080px){
        font-size: calc(
                32px + (48 - 32) * ((100vw - 768px) / (1080 - 768))
        );
        line-height: calc(
                40px + (68 - 40) * ((100vw - 768px) / (1080 - 768))
        );
      }
      @media (max-width: 768px) {
        white-space: inherit;
        text-align: center;
      }
      @media (min-width: 480px) and (max-width: 768px){
        font-size: calc(
                24px + (32 - 24) * ((100vw - 480px) / (768 - 480))
        );
        line-height: calc(
                32px + (40 - 32) * ((100vw - 480px) / (768 - 480))
        );
        max-width:  calc(
                374px + (621 - 374) * ((100vw - 480px) / (768 - 480))
        );
      }
      @media (min-width: 320px) and (max-width: 480px){
        font-size: calc(
                18px + (24 - 18) * ((100vw - 320px) / (480 - 320))
        );
        line-height: calc(
                26px + (32 - 26) * ((100vw - 320px) / (480 - 320))
        );
        max-width: calc(
                280px + (374 - 280) * ((100vw - 320px) / (480 - 320))
        );
      }
    }
    &__description {
      color: var(--font-color-gray);
      font-size: 24px;
      font-family: Russo One;
      font-weight: 400;
      line-height: 30px;
      max-width: 791px;
      text-align: center;
      margin: 32px 0 45px 0;

      @media (min-width: 1440px) and (max-width: 1920px){
        font-size: calc(
                16px + (24 - 16) * ((100vw - 1440px) / (1920 - 1440))
        );
        line-height: calc(
                24px + (30 - 24) * ((100vw - 1440px) / (1920 - 1440))
        );
        margin: calc(48px + (32 - 48) * ((100vw - 1440px) / (1920 - 1440))) 0
        calc(48px + (45 - 48) * ((100vw - 1440px) / (1920 - 1440))) 0
      }
      @media (max-width: 1440px) {
        font-size: 16px;
        line-height: 24px;
        margin: 48px 0;
      }
      @media (min-width: 768px) and (max-width: 1080px){
        max-width: calc(
                687px + (791 - 687) * ((100vw - 768px) / (1080 - 768))
        );
      }
      @media (min-width: 480px) and (max-width: 768px){
        max-width: calc(
                440px + (687 - 440) * ((100vw - 480px) / (768 - 480))
        );
        margin: calc(
                32px + (48 - 32) * ((100vw - 480px) / (768 - 480))
        ) 0;
      }
      @media (max-width: 480px) {
        margin: 32px 0;
      }
      @media (min-width: 320px) and (max-width: 480px){
        font-size: calc(
                14px + (16 - 14) * ((100vw - 320px) / (480 - 320))
        );
        line-height: calc(
                21px + (24 - 21) * ((100vw - 320px) / (480 - 320))
        );
        max-width: calc(
                280px + (440 - 280) * ((100vw - 320px) / (480 - 320))
        );
      }
    }
  }
  .hidden {
    display: none;
  }
  .rewardsSection {

    &__header {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 48px;

      @media (min-width: 1080px) and (max-width: 1440px){
        margin-bottom: calc(
                32px + (48 - 32) * ((100vw - 1080px) / (1440 - 1080))
        );
      }
      @media (max-width: 1080px) {
        margin-bottom: 32px;
      }
      &-title {
        color: var(--font-color-white);
        font-size: 64px;
        font-family: Knewave;
        font-weight: 400;
        text-transform: uppercase;
        line-height: 74px;
        margin-bottom: 48px;
        position: relative;

        @media (min-width: 1440px) and (max-width: 1920px){
          font-size: calc(
                  56px + (64 - 56) * ((100vw - 1440px) / (1920 - 1440))
          );
          line-height: calc(
                  67px + (74 - 67) * ((100vw - 1440px) / (1920 - 1440))
          );
        }
        @media (min-width: 1080px) and (max-width: 1440px){
          font-size: calc(
                  48px + (56 - 48) * ((100vw - 1080px) / (1440 - 1080))
          );
          line-height: calc(
                  68px + (67 - 68) * ((100vw - 1080px) / (1440 - 1080))
          );
          margin-bottom: calc(
                  32px + (48 - 32) * ((100vw - 1080px) / (1440 - 1080))
          );
        }
        @media (max-width: 1080px) {
          font-size: 48px;
          line-height: 68px;
          margin-bottom: 32px;
        }
        @media (min-width: 480px) and (max-width: 768px){
          font-size: calc(
                  32px + (48 - 32) * ((100vw - 480px) / (768 - 480))
          );
          line-height: calc(
                  40px + (68 - 40) * ((100vw - 480px) / (768 - 480))
          );
        }
        @media (min-width: 320px) and (max-width: 480px){
          font-size: calc(
                  24px + (32 - 24) * ((100vw - 320px) / (480 - 320))
          );
          line-height: calc(
                  32px + (40 - 32) * ((100vw - 320px) / (480 - 320))
          );
          margin-bottom: calc(
                  24px + (32 - 24) * ((100vw - 320px) / (480 - 320))
          );
        }
        &-imageWrap {
          position: absolute;
          left: -120%;
          bottom: -50%;
          max-width: 320px;

          @media (min-width: 1440px) and (max-width: 1920px){
            max-width: calc(
                    240px + (320 - 240) * ((100vw - 1440px) / (1920 - 1440))
            );
            left: -100%;
          }
          @media (min-width: 1080px) and (max-width: 1440px){
            max-width: calc(
                    160px + (240 - 160) * ((100vw - 1080px) / (1440 - 1080))
            );
            left: -90%;
          }
          @media (min-width: 768px) and (max-width: 1080px){
            max-width: calc(
                    120px + (160 - 120) * ((100vw - 768px) / (1080 - 768))
            );
          }
          @media (min-width: 480px) and (max-width: 768px){
            max-width: calc(
                    80px + (120 - 80) * ((100vw - 480px) / (768 - 480))
            );
          }
          @media (min-width: 320px) and (max-width: 480px){
            max-width: calc(
                    64px + (80 - 64) * ((100vw - 320px) / (480 - 320))
            );
          }
          @media (max-width: 1079px) {
            left: -80%;
          }
          @media (max-width: 1000px) {
            left: -70%;
          }

          img {
            width: 100%;
          }
        }
      }
      &-description {
        color: var(--font-color-gray);
        font-size: 24px;
        font-family: Russo One;
        font-weight: 400;
        line-height: 30px;
        max-width: 791px;
        text-align: center;

        @media (min-width: 1440px) and (max-width: 1920px){
          font-size: calc(
                  16px + (24 - 16) * ((100vw - 1440px) / (1920 - 1440))
          );
          line-height: calc(
                  24px + (30 - 24) * ((100vw - 1440px) / (1920 - 1440))
          );
        }
        @media (max-width: 1440px) {
          font-size: 16px;
          line-height: 24px;
        }
        @media (min-width: 1080px) and (max-width: 1440px){
          max-width: calc(
                  680px + (791 - 680) * ((100vw - 1080px) / (1440 - 1080))
          );
        }
        @media (max-width: 1080px) {
         max-width: 680px;
        }
        @media (min-width: 480px) and (max-width: 768px){
          max-width: calc(
                  440px + (680 - 440) * ((100vw - 480px) / (768 - 480))
          );
        }
        @media (min-width: 320px) and (max-width: 480px){
          font-size: calc(
                  14px + (16 - 14) * ((100vw - 320px) / (480 - 320))
          );
          line-height: calc(
                  21px + (24 - 21) * ((100vw - 320px) / (480 - 320))
          );
          max-width: calc(
                  280px + (440 - 280) * ((100vw - 320px) / (480 - 320))
          );
        }
      }
    }
    &__timerWrapper {
      background-image: url("../../../../images/analyticsPage/rewardsBG.png");
      background-repeat: round;
      padding: 56px 0 164px 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      margin-bottom: 48px;

      @media (min-width: 1440px) and (max-width: 1920px){
        padding: 56px 0 calc(139px + (164 - 139) * ((100vw - 1440px) / (1920 - 1440))) 0
      }
      @media (min-width: 1080px) and (max-width: 1440px){
        padding: calc(40px + (56 - 40) * ((100vw - 1080px) / (1440 - 1080))) 0 calc(92px + (139 - 92) * ((100vw - 1080px) / (1440 - 1080))) 0
      }
      @media (min-width: 768px) and (max-width: 1080px){
        padding: 40px 0 calc(101px + (92 - 101) * ((100vw - 768px) / (1080 - 768))) 0
      }
      @media (min-width: 480px) and (max-width: 768px){
        background-image: url("../../../../images/analyticsPage/claimBG2.png");
        padding: calc(32px + (40 - 32) * ((100vw - 480px) / (768 - 480))) 0 calc(60px + (101 - 60) * ((100vw - 480px) / (768 - 480))) 0
      }
      @media (min-width: 320px) and (max-width: 480px){
        background-image: url("../../../../images/analyticsPage/claimBG3.png");
        padding: calc(19px + (32 - 19) * ((100vw - 320px) / (480 - 320))) 0 calc(36px + (60 - 36) * ((100vw - 320px) / (480 - 320))) 0
      }

      .dollarImage {
        position: absolute;
        left: 50px;
        top: 50px;
        max-width: 187px;
        animation: glowingCrownSec 2s infinite alternate 1s;

        @media (min-width: 1080px) and (max-width: 1440px){
          max-width: calc(
                  150px + (187 - 150) * ((100vw - 1080px) / (1440 - 1080))
          );
          top: calc(
                  30px + (50 - 30) * ((100vw - 1080px) / (1440 - 1080))
          );
          left: calc(
                  30px + (50 - 30) * ((100vw - 1080px) / (1440 - 1080))
          );
        }
        @media (max-width: 1079px) {
          display: none;
        }
      }
      .scullImage {
        position: absolute;
        bottom: 90px;
        right: 40px;
        max-width: 319px;
        animation: glowingCrownSec 2s infinite alternate;

        @media (min-width: 1440px) and (max-width: 1920px){
          max-width: calc(
                  260px + (319 - 260) * ((100vw - 1440px) / (1920 - 1440))
          );
        }
        @media (min-width: 1080px) and (max-width: 1440px){
          max-width: calc(
                  209px + (260 - 209) * ((100vw - 1080px) / (1440 - 1080))
          );
          right: calc(
                  2px + (40 - 2) * ((100vw - 1080px) / (1440 - 1080))
          );
          bottom: calc(
                  70px + (90 - 70) * ((100vw - 1080px) / (1440 - 1080))
          );
        }
        @media (max-width: 1079px) {
          display: none;
        }
      }

      &-claim {
        background-image: url("../../../../images/analyticsPage/claimBG.png");
        background-repeat: round;
        background-size: cover;
        color: var(--font-color-black);
        font-size: 64px;
        font-family: Knewave;
        font-weight: 400;
        text-transform: uppercase;
        line-height: 74px;
        padding: 22px 70px 24px 55px;
        margin-bottom: 32px;

        @media (min-width: 1440px) and (max-width: 1920px){
          font-size: calc(
                  56px + (64 - 56) * ((100vw - 1440px) / (1920 - 1440))
          );
          line-height: calc(
                  67px + (74 - 67) * ((100vw - 1440px) / (1920 - 1440))
          );
          padding: 22px
          calc(78px + (70 - 78) * ((100vw - 1440px) / (1920 - 1440)))
          24px
          calc(80px + (55 - 80) * ((100vw - 1440px) / (1920 - 1440)))
        }
        @media (min-width: 1080px) and (max-width: 1440px){
          font-size: calc(
                  48px + (64 - 48) * ((100vw - 1080px) / (1440 - 1080))
          );
          line-height: calc(
                  68px + (67 - 68) * ((100vw - 1080px) / (1440 - 1080))
          );
          padding: 22px
          calc(76px + (78 - 76) * ((100vw - 1080px) / (1440 - 1080)))
          24px
          calc(76px + (80 - 76) * ((100vw - 1080px) / (1440 - 1080)))
        }
        @media (max-width: 1080px) {
          font-size: 48px;
          line-height: 68px;
          padding: 22px 76px 24px 76px;
        }
        @media (min-width: 480px) and (max-width: 768px){
          font-size: calc(
                  32px + (48 - 32) * ((100vw - 480px) / (768 - 480))
          );
          line-height: calc(
                  40px + (68 - 40) * ((100vw - 480px) / (768 - 480))
          );
          margin-bottom: calc(
                  16px + (32 - 16) * ((100vw - 480px) / (768 - 480))
          );
          padding: calc(16px + (22 - 16) * ((100vw - 480px) / (768 - 480)))
          calc(50px + (76 - 50) * ((100vw - 480px) / (768 - 480)));
        }
        @media (max-width: 480px) {
          margin-bottom: 16px;
        }
        @media (min-width: 320px) and (max-width: 480px){
          font-size: calc(
                  24px + (32 - 24) * ((100vw - 320px) / (480 - 320))
          );
          line-height: calc(
                  32px + (40 - 32) * ((100vw - 320px) / (480 - 320))
          );
          padding: calc(8px + (16 - 8) * ((100vw - 320px) / (480 - 320)))
          calc(42px + (50 - 42) * ((100vw - 320px) / (480 - 320)))
        }
      }
      &-timer {
        gap: 80px;
        display: flex;
        align-items: center;

        @media (min-width: 1440px) and (max-width: 1920px){
          gap: calc(
                  64px + (80 - 64) * ((100vw - 1440px) / (1920 - 1440))
          );
        }
        @media (max-width: 1440px) {
          gap: 64px;
        }
        @media (min-width: 768px) and (max-width: 1080px){
          gap: calc(
                  32px + (64 - 32) * ((100vw - 768px) / (1080 - 768))
          );
        }
        @media (min-width: 480px) and (max-width: 768px){
          gap: calc(
                  8px + (32 - 8) * ((100vw - 480px) / (768 - 480))
          );
        }
        @media (max-width: 480px) {
          gap: 8px;
        }
        &-item {
          color: var(--font-color-black);
          font-size: 82px;
          font-family: Knewave;
          font-weight: 400;
          text-transform: uppercase;
          line-height: 96px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          gap: 16px;
          text-align: center;
          background: #3B95FF;
          border-radius: 16px;
          box-shadow: 0px 3px 0px rgba(0, 0, 0, 0.2);
          width: 264px;
          height: 248px;
          box-sizing: border-box;

          @media (min-width: 1440px) and (max-width: 1920px){
            font-size: calc(
                    64px + (82 - 64) * ((100vw - 1440px) / (1920 - 1440))
            );
            line-height: calc(
                    74px + (96 - 74) * ((100vw - 1440px) / (1920 - 1440))
            );
            width: calc(
                    200px + (264 - 200) * ((100vw - 1440px) / (1920 - 1440))
            );
            height: calc(
                    180px + (248 - 180) * ((100vw - 1440px) / (1920 - 1440))
            );
          }
          @media (min-width: 1080px) and (max-width: 1440px){
            font-size: calc(
                    56px + (64 - 56) * ((100vw - 1080px) / (1440 - 1080))
            );
            line-height: calc(
                    67px + (74 - 67) * ((100vw - 1080px) / (1440 - 1080))
            );
            width: calc(
                    160px + (200 - 160) * ((100vw - 1080px) / (1440 - 1080))
            );
            height: calc(
                    140px + (180 - 140) * ((100vw - 1080px) / (1440 - 1080))
            );
          }
          @media (max-width: 1080px) {
            font-size: 56px;
            line-height: 67px;
            width: 160px;
            height: 140px;
          }
          @media (min-width: 480px) and (max-width: 768px){
            font-size: calc(
                    48px + (56 - 48) * ((100vw - 480px) / (768 - 480))
            );
            line-height: calc(
                    68px + (67 - 68) * ((100vw - 480px) / (768 - 480))
            );
            width: calc(
                    120px + (160 - 120) * ((100vw - 480px) / (768 - 480))
            );
            height: calc(
                    120px + (140 - 120) * ((100vw - 480px) / (768 - 480))
            );
            gap: calc(
                    8px + (16 - 8) * ((100vw - 480px) / (768 - 480))
            );
          }
          @media (max-width: 480px) {
            gap: 8px;
          }
          @media (min-width: 320px) and (max-width: 480px){
            font-size: calc(
                    32px + (48 - 32) * ((100vw - 320px) / (480 - 320))
            );
            line-height: calc(
                    40px + (68 - 40) * ((100vw - 320px) / (480 - 320))
            );
            width: calc(
                    80px + (120 - 80) * ((100vw - 320px) / (480 - 320))
            );
            height: calc(
                    80px + (120 - 80) * ((100vw - 320px) / (480 - 320))
            );
          }

          &-name {
            color: var(--font-color-black);
            font-size: 24px;
            font-family: Russo One;
            font-weight: 400;
            line-height: 30px;
            text-transform: initial;

            @media (min-width: 1440px) and (max-width: 1920px){
              font-size: calc(
                      16px + (24 - 16) * ((100vw - 1440px) / (1920 - 1440))
              );
              line-height: calc(
                      24px + (30 - 24) * ((100vw - 1440px) / (1920 - 1440))
              );
            }
            @media (max-width: 1440px) {
              font-size: 16px;
              line-height: 24px;
            }
            @media (min-width: 320px) and (max-width: 480px){
              font-size: calc(
                      14px + (16 - 14) * ((100vw - 320px) / (480 - 320))
              );
              line-height: calc(
                      21px + (24 - 21) * ((100vw - 320px) / (480 - 320))
              );
            }
          }
        }
      }
    }
    &__btnWrap {
      display: flex;
      justify-content: center;
      &-btn {
        background-image: url("../../../../images/analyticsPage/claimBtnBG.png");
        background-repeat: round;
        padding: 24px 130px;
        color: var(--font-color-black);
        font-size: 22px;
        font-family: Knewave;
        font-weight: 400;
        text-transform: uppercase;
        line-height: 26px;
        @include lightBreenBtn;
        transition: filter 1s ease;
        cursor: pointer;

        @media (min-width: 1440px) and (max-width: 1920px){
          padding: calc(12px + (24 - 12) * ((100vw - 1440px) / (1920 - 1440))) calc(98px + (130 - 75) * ((100vw - 1440px) / (1920 - 1440)));
          font-size: calc(16px + (22 - 16) * ((100vw - 1440px) / (1920 - 1440)));
          max-width: calc(240px + (320 - 240) * ((100vw - 1440px) / (1920 - 1440)));
        }
        @media (max-width: 1440px) {
          padding: 12px 98px;
          font-size: 16px;
          max-width: 240px;
        }
      }
      @media (hover: hover) {
        &-btn:hover {
          @include lightBreenBtnAnim;
        }
      }

      .touch-device &-btn:hover {
        @include lightBreenBtn;
      }
      &-btn.animate {
        transition: filter 0.5s ease;
        filter: drop-shadow(0 0 2px #00FED1) drop-shadow(0 0 4px #00FED1) drop-shadow(0 0 8px #00FED1) !important;
      }
    }

  }
}
.blur {
  @include blur;
}
