@import "src/pages/_variables.scss";

.button {
  background-size: cover;
  background-repeat: round;
  color: var(--font-color-black);
  font-family: Knewave;
  font-size: 22px;
  text-transform: uppercase;
  cursor: pointer;
  box-sizing: border-box;
  @include yellowBtn;
  transition: filter 1s ease;
  white-space: nowrap;
  display: flex;
  justify-content: center;
}
//.button:hover {
//  @include yellowBtnAnim;
//}
//.touch-device .button:hover {
//  @include yellowBtn;
//}
@media (hover: hover) {
  .button:hover {
    @include yellowBtnAnim;
  }
}

.touch-device .button:hover {
  @include yellowBtn;
}
.button.animate {
  filter: drop-shadow(0 0 2px #FEFF00) drop-shadow(0 0 4px #FEFF00) drop-shadow(0 0 8px #FEFF00) !important;
}

.button--connectwallet {
  background-image: url("../../images/walletBtnBg.svg");
  padding: 24px 75px;
  margin-left: 32px;
  max-width: 320px;

  @media (min-width: 1440px) and (max-width: 1920px){
    padding: calc(12px + (24 - 12) * ((100vw - 1440px) / (1920 - 1440))) calc(58px + (75 - 58) * ((100vw - 1440px) / (1920 - 1440)));
    font-size: calc(16px + (22 - 16) * ((100vw - 1440px) / (1920 - 1440)));
    max-width: calc(240px + (320 - 240) * ((100vw - 1440px) / (1920 - 1440)));
    margin-left: calc(24px + (32 - 24) * ((100vw - 1440px) / (1920 - 1440)));
  }

  @media (min-width: 768px) and (max-width: 1080px){
    margin-left: calc(16px + (24 - 16) * ((100vw - 768px) / (1080 - 768)));
  }
  @media (max-width: 1440px){
    padding: 12px 58px;
    font-size: 16px;
    margin-left: 24px;
    max-width: 240px;
  }
  @media (min-width: 768px) and (max-width: 1080px){
    padding: 12px calc(48px + (58 - 48) * ((100vw - 768px) / (1080 - 768)));
    margin-left: calc(16px + (24 - 16) * ((100vw - 768px) / (1080 - 768)));
  }
}

.button--getsugarheadsnow {
  background-image: url("../../images/walletBtnBg.svg");
  padding: 24px 44px;
  max-width: 320px;
  font-size: 22px;

  @media (min-width: 1440px) and (max-width: 1920px){
    padding: calc(12px + (24 - 12) * ((100vw - 1440px) / (1920 - 1440))) calc(36px + (44 - 36) * ((100vw - 1440px) / (1920 - 1440)));
    font-size: calc(16px + (22 - 16) * ((100vw - 1440px) / (1920 - 1440)));
    max-width: calc(240px + (320 - 240) * ((100vw - 1440px) / (1920 - 1440)));
  }
  @media (min-width: 1080px) and (max-width: 1440px){
    padding: 12px calc(25px + (36 - 25) * ((100vw - 1080px) / (1440 - 1080)));
  }

  @media (max-width: 1440px){
    font-size: 16px;
    max-width: 240px;
  }
}
.button--getstarted {
  background-image: url("../../images/walletBtnBg.svg");
  margin-top: 48px;
  padding: 24px 95px;
  max-width: 320px;
  font-size: 22px;

  @media (min-width: 1440px) and (max-width: 1920px){
    padding: calc(12px + (24 - 12) * ((100vw - 1440px) / (1920 - 1440))) calc(73px + (95 - 73) * ((100vw - 1440px) / (1920 - 1440)));
    font-size: calc(16px + (22 - 16) * ((100vw - 1440px) / (1920 - 1440)));
    max-width: calc(240px + (320 - 240) * ((100vw - 1440px) / (1920 - 1440)));
    margin-top: calc(42px + (48 - 42) * ((100vw - 1440px) / (1920 - 1440)));
  }
  @media (max-width: 1440px) {
    padding: 12px 73px;
    font-size: 16px;
    max-width: 240px;
    margin-top: 42px;
  }
  @media (min-width: 1080px) and (max-width: 1440px){
    margin-top: calc(38px + (42 - 38) * ((100vw - 1080px) / (1440 - 1080)));
  }
  @media (min-width: 768px) and (max-width: 1080px){
    margin-top: calc(48px + (38 - 48) * ((100vw - 768px) / (1080 - 768)));
    max-width: calc(220px + (240 - 220) * ((100vw - 768px) / (1080 - 768)));
    padding: 12px calc(63px + (73 - 63) * ((100vw - 768px) / (1080 - 768)));
  }
  @media (max-width: 768px) {
    max-width: 220px;
    padding: 12px 63px;
  }
  @media (min-width: 480px) and (max-width: 768px){
    margin-top: calc(40px + (48 - 40) * ((100vw - 480px) / (768 - 480)));
  }
  @media (min-width: 320px) and (max-width: 480px){
    margin-top: calc(24px + (40 - 24) * ((100vw - 320px) / (480 - 320)));
  }
}
